import axios, { AxiosRequestConfig } from "axios";

export interface PISDataType {
  id: number;
  name: string;
  lat: string;
  long: string;
  dev_id_1: string;
  dev_id_2: string;
  remarks: string;
  createdAt: Date;
  updatedAt: Date;
  dis_lat: string;
  dis_long: string;
  dis_direction: number[];
}

export interface getPISDataType {
  status: string;
  total: number;
  data: PISDataType[];
}

export const getPIS = async (token: string) => {
  var config: AxiosRequestConfig = {
    method: "get",
    url: "https://pis.distronix.in:5443/api/pisMaster/getAll",
    headers: {
      "x-access-token": token,
    },
  };

  const result = await axios(config).catch(function (error) {
    console.log(error);
  });

  return result;
};
