import React, { useState, useEffect } from "react";
import GoogleMapReact from "google-map-react";
import styles from "./PISMaps.module.css";
import MonitorCollapse from "./MonitorCollapse";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { polyColors } from "../pages/mockdata";
// @ts-ignore
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import ReactTooltip from "react-tooltip";
import { faRssSquare } from "@fortawesome/free-solid-svg-icons";
import { getBusStop } from "../api/getBusStop";
import "react-toggle/style.css";
import MapsKPI from "./MapsKPI";
import MAPS_API_KEY from "../APIKEY";
import { useSubscription } from "mqtt-react-hooks";
import {
  IMessageBusReturnType,
  IMessageMarkerReturnType,
} from "../pages/types";
import { getRoutes2 } from "../api/getRoutes2";
import DisplayInfo from "./DisplayInfo";
import BusPin from "./BusPin";
import moment from "moment";
import { getRoute } from "../api/getRoute";
import { getKPI } from "../api/getKpi";
import { getStopsStatus } from "../api/getStopsStatus";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { GenerateAccessToken } from "../api/generateAccessToken";
import { logout, setAccessToken } from "../redux/slice/UserSlice";
import { Logout } from "../api/login/logout";
import { getUserResponse } from "../api/getUserResponse";

export const updateBusStopMarkerLiveInfo = (data: any[], newItem: any) => {
  const live: any[] = data;
  const index = live.findIndex((item) => item.cmd === newItem.cmd);
  if (index === -1 && newItem.effect !== "delete") {
    live.push(newItem);
  } else if (newItem.effect === "delete") {
    live.filter((item) => item.cmd !== newItem.cmd);
  } else {
    live[index] = newItem;
  }
  return live;
};

function MapView() {
  const customStyles = {
    container: (provided: any) => ({
      ...provided,
      color: "#000",
      width: 150,
      background: "#fff",
      marginLeft: -10,
      boxShadow: "rgb(0 0 0 / 30%) 0px 1px 4px -1px",
    }),
    option: () => ({
      cursor: "pointer",
      width: 130,
      padding: "5px",
    }),
    control: () => ({
      width: 130,
      padding: "5px",
    }),
    indicatorsContainer: () => ({
      display: "none",
    }),
    dropdownButton: (provided: any) => ({
      ...provided,
      background: "#125AA1",
      border: 0,
      color: "#fff",
      width: 130,
      padding: "4px 0px",
      boxShadow: 0,
      fontSize: "17px",
    }),
  };

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      container: {
        position: "absolute",
        zIndex: 1,
      },
      formControl: {
        margin: 0,
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
      select: {
        maxWidth: 300,
        margin: "0 10px",
        color: "#fff",
        "&&&:before": {
          border: 0,
        },
      },
      icon: {
        fill: "#fff",
      },
    })
  );

  const User = useAppSelector((state: any) => state.User);
  const classes = useStyles();
  const [activeItem, setActiveItem] = useState();
  const [routeID, setRouteID] = useState<any[]>([]);
  const [Markers, setMarkers] = useState<any[]>([]);
  let selectedRoutes: any[] = [];
  let selectedBusLabels: any[] = [];
  const [filterAnchor, setFilterAnchor] = useState(false);
  const [map, setMap] = useState();
  const [maps, setMaps] = useState();
  const [polyState, setPolyState] = useState<any>([]);
  const [MarkData, setMarkData] = useState<IMessageMarkerReturnType[]>([]);
  const [BusDataActive, setBusDataActive] = useState<IMessageBusReturnType[]>(
    []
  );
  const [RouteIdArr, setRouteIdArr] = useState<any[]>([]);
  const [markerInfo, setMarkerInfo] = useState(false);
  const [selectedMarkerId, setSelectedMarkerId] = useState(0);
  const [markerData, setMarkerData] = useState({ stoppage: "", mac: "" });
  const [busAnchor, setBusAnchor] = useState(false);
  const [selectedBusId, setSelectedBusId] = useState<string>();
  const [KPIData, setKPIData] = useState();
  const getRoutesList = async (routeList: any) => {
    const response = await getRoutes2(routeList, User.token).catch((err) => {
      if (err.response.status === 401) {
        regenerateToken();
        getRoutesList(routeList);
      }
    });
    response?.data?.data?.map((i: any) => {
      selectedRoutes.push(i.routes);
      selectedBusLabels.push(i.busLabel);
      return i;
    });
    directionServicePolylines(map, maps, selectedRoutes, selectedBusLabels);
  };
  const dispatch = useAppDispatch();
  const regenerateToken = async () => {
    const res = await GenerateAccessToken(User.refreshToken).catch((e) => {
      Logout(User.refreshToken);
      dispatch(logout());
    });
    if (!!res?.token) {
      dispatch(
        setAccessToken({
          token: res?.token,
        })
      );
    }
  };

  useEffect(() => {
    const getBusStopData = async () => {
      const result = await getBusStop(User.token).catch((err) => {
        if (err.response.status === 401) {
          regenerateToken();
          getBusStopData();
        }
      });
      result?.map((item: any, index: any) => {
        setMarkers((d) => [
          ...d,
          {
            id: index,
            stoppage: item.name,
            mac: item.dev_id_1 === null ? "" : item.dev_id_1,
            lat: item?.lat,
            long: item.long,
          },
        ]);
      });
    };
    getBusStopData();
    callgetRoute();
    callgetKPI();
    callStopStatus();

    const interval = setInterval(() => {
      callStopStatus();
    }, 30000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const [StoppageOptions, setStoppageOptions] = useState<any[]>([]);
  useEffect(() => {
    if (!!Markers) {
      Markers?.map((item: any) => {
        return setStoppageOptions((d) => [
          ...d.filter((i) => i !== item.stoppage),
          item.stoppage,
        ]);
      });
    }
  }, [Markers]);

  const callgetKPI = async () => {
    const result = await getKPI(User.token).catch((err) => {
      if (err.response.status === 401) {
        regenerateToken();
        callgetKPI();
      }
    });
    setKPIData(result?.data?.data);
  };

  const callgetRoute = async () => {
    const result = await getRoute(User.token).catch((err) => {
      if (err.response.status === 401) {
        regenerateToken();
        callgetRoute();
      }
    });
    result?.data?.data?.map((item: any) => {
      setRouteIdArr((d) => [
        ...d.filter((i) => i.value !== item),
        {
          label: item,
          value: item,
        },
      ]);
    });
  };

  const handleMultiSelect = (event: any) => {
    const routeIDCollection: string[] = [];
    event?.map((item: any) => {
      routeIDCollection.push(item.value);
      return item;
    });
    setRouteID(routeIDCollection);
    getRoutesList(routeIDCollection);
  };
  // End of Multiselect filter
  // Filter slide
  const handleFilter = () => {
    setFilterAnchor(!filterAnchor);
  };
  // End of filter slide

  const setValues = (map: any, maps: any) => {
    setMap(map);
    setMaps(maps);
  };

  const directionServicePolylines = (
    map: any,
    maps: any,
    values: any[],
    selectedBL: any[]
  ) => {
    const directionsService = new maps.DirectionsService();
    const directionsDisplay = new maps.DirectionsRenderer();
    let i = -1;
    values.forEach((item) => {
      directionsService.route(
        {
          origin: new maps.LatLng(item[0]?.lat, item[0]?.long),
          destination: new maps.LatLng(
            item[item.length - 1].lat,
            item[item.length - 1].long
          ),
          travelMode: maps.TravelMode.TRANSIT,
        },
        (response: any, status: any) => {
          if (status === "OK") {
            directionsDisplay.setDirections(response);
            response.routes?.map((item: any, index: any) => {
              const routePolyline = new maps.Polyline({
                path: item.overview_path,
                strokeColor: polyColors[++i],
                strokeOpacity: 0.85,
                strokeWeight: 10,
              });
              routePolyline.setMap(map);
              setPolyState((d: any) => [...d, routePolyline]);
              return item;
            });
          }
        }
      );
    });
  };

  const callGetUserResponse = async () => {
    await getUserResponse(User?.refreshToken);
  };

  const removeLines = (polyState: any) => {
    polyState?.map((item: any) => {
      item.setMap(null);
      return item;
    });
  };

  useEffect(() => {
    if (polyState !== undefined) {
      removeLines(polyState);
    }
  }, [routeID]);

  const [StoreStopsStatus, setStoreStopsStatus] = useState<any[]>([]);

  const callStopStatus = async () => {
    const result = await getStopsStatus(User.token).catch((err) => {
      if (err.response.status === 401) {
        regenerateToken();
        callStopStatus();
      }
    });
    console.log("status data==>", result?.data.data);
    setStoreStopsStatus(result?.data.data);
  };

  /* MQTT */
  const { message: BusStopMessage } = useSubscription(
    `/dist/pis/${selectedMarkerId}/data`
  );

  const [BusData, setBusData] = useState<any[]>([]);

  const { message: messageBusData } = useSubscription("busdata");
  useEffect(() => {
    if (
      typeof BusStopMessage?.message === "string" &&
      BusStopMessage?.topic === `/dist/pis/${selectedMarkerId}/data`
    ) {
      const markerDataInJSON: any =
        BusStopMessage?.message !== "[]"
          ? JSON.parse(BusStopMessage?.message)
          : [];
      console.log("socket Data ==>", markerDataInJSON);

      if (markerDataInJSON.length === 0) {
        setMarkData([
          {
            cmd: "No data",
            created_at: "",
            dest: "",
            effect: "",
            eta: "",
            mac: "",
            reg_no: "",
          },
        ]);
      } else {
        setMarkData([...markerDataInJSON]);
      }
    }
  }, [BusStopMessage]);

  useEffect(() => {
    if (
      typeof messageBusData?.message === "string" &&
      messageBusData?.topic === "busdata"
    ) {
      const busDataInJSON: IMessageBusReturnType = JSON.parse(
        messageBusData?.message
      );
      Object.entries(busDataInJSON)?.map((item) => {
        setBusData((d) => [
          ...d.filter((i) => i.vehicleRegNo !== item[1].vehicleRegNo),
          item[1],
        ]);
      });
    }
  }, [messageBusData]);

  useEffect(() => {
    if (!!BusData) {
      BusData?.map((item: any) => {
        setBusDataActive((d) => [
          ...d.filter(
            (i: any) =>
              !(i.vehicleRegNo === item.vehicleRegNo) &&
              moment.duration(moment().diff(i.timestamp)).asMinutes() < 30
          ),
          item,
        ]);
      });
    }
  }, [BusData]);

  // END OF MQTT
  const handleMarkerInfoOpen = (props: any) => {
    callGetUserResponse();
    setMarkDataSorted([]);
    setNumberMarkData([]);
    setStringMarkData([]);
    setMarkData([]);
    setSelectedMarkerId(props.mac);
    setMarkerInfo(true);
    setActiveItem(props.mac);
    setMarkerData({
      ...markerData,
      stoppage: props.stoppage,
      mac: props.mac,
    });
  };
  const handleMarkerInfoClose = () => {
    setMarkDataSorted([]);
    setNumberMarkData([]);
    setStringMarkData([]);
    setMarkData([]);
    setSelectedMarkerId(0);
    setMarkerInfo(false);
    setMarkerData({
      ...markerData,
      stoppage: "",
      mac: "",
    });
  };

  // BUSINFO POPOVER

  const toggleBusInfo = (props: any) => {
    setBusAnchor(!busAnchor);
    if (selectedBusId === props) setSelectedBusId(undefined);
    else setSelectedBusId(props);
  };

  // END OF BUSINFO POPOVER
  const center = {
    lat: 22.59627,
    lng: 88.48757,
  };

  const zoom = 14;
  const MarkerPin = (props: any) => (
    <div
      data-tip={props.stoppage}
      className={styles.MarkerPin}
      onClick={() => {
        setMarkDataSorted([]);
        setMarkData([]);
        setNumberMarkData([]);
        setStringMarkData([]);
        handleMarkerInfoOpen(props);
      }}
    >
      <FontAwesomeIcon
        icon={faRssSquare}
        size={selectedMarkerId === props.mac ? "5x" : "3x"}
        color={selectedMarkerId === props.mac ? "#E84335" : "#E84335"}
      />
    </div>
  );

  const compare = (a: any, b: any) => {
    return Number(a.eta) - Number(b.eta);
  };

  const [NumberMarkData, setNumberMarkData] = useState<any[]>([]);
  const [StringMarkData, setStringMarkData] = useState<any[]>([]);

  useEffect(() => {
    if (!!MarkData) {
      MarkData.map((item) => {
        if (!isNaN(Number(item.eta))) {
          setNumberMarkData((d) => [
            ...d.filter((i) => i.reg_no !== item.reg_no),
            { ...item, eta: Number(item.eta) },
          ]);
        }
      });
      MarkData.map((item) => {
        if (isNaN(Number(item.eta))) {
          setStringMarkData((d) => [
            ...d.filter((i) => i.reg_no !== item.reg_no),
            item,
          ]);
        }
      });
    }
  }, [MarkData]);

  const [MarkDataSorted, setMarkDataSorted] = useState<any>([]);
  const [NumberMarkDataSorted, setNumberMarkDataSorted] = useState<any>([]);

  useEffect(() => {
    setNumberMarkDataSorted(NumberMarkData.sort(compare));
  }, [MarkData]);

  useEffect(() => {
    setMarkDataSorted([...StringMarkData, ...NumberMarkDataSorted]);
  }, [NumberMarkDataSorted, StringMarkData]);

  return (
    <div className={styles.Maps}>
      <MonitorCollapse
        activeItem={activeItem}
        handleMarkerInfoOpen={handleMarkerInfoOpen}
        Markers={Markers}
        status={StoreStopsStatus}
        StoppageOptions={StoppageOptions}
      />
      <div>
        <div onClick={handleFilter} className={styles.Filter}>
          <FontAwesomeIcon icon={faFilter} />
        </div>
        <div className={classes.container}>
          <Slide direction="right" in={filterAnchor} mountOnEnter unmountOnExit>
            <div className={styles.FilterDrop}>
              <ReactMultiSelectCheckboxes
                styles={customStyles}
                onChange={handleMultiSelect}
                placeholderButtonLabel="Route ID"
                options={RouteIdArr}
              />
            </div>
          </Slide>
        </div>
      </div>
      <MapsKPI KPIData={KPIData} />
      {markerInfo ? (
        <DisplayInfo
          MarkData={MarkData}
          markerData={markerData}
          handleMarkerInfoClose={handleMarkerInfoClose}
          status={StoreStopsStatus}
        />
      ) : null}
      <GoogleMapReact
        bootstrapURLKeys={{ key: MAPS_API_KEY }}
        defaultCenter={center}
        defaultZoom={zoom}
        yesIWantToUseGoogleMapApiInternals={true}
        options={(maps) => ({
          backgroundColor: "#f7f7f7",
          fullscreenControl: false,
          mapTypeControl: false,
          zoomControl: true,
          zoomControlOptions: {
            position: maps.ControlPosition.RIGHT_BOTTOM,
          },
        })}
        onGoogleApiLoaded={({ map, maps }) => setValues(map, maps)}
      >
        {Markers?.map((item: any, index: number) => (
          <MarkerPin
            stoppage={item.stoppage}
            mac={item.mac}
            lat={item?.lat}
            lng={item.long}
            key={item?.mac + item?.lat}
          />
        ))}
        {routeID.length === 0 &&
          BusDataActive?.map(
            (item) =>
              !!item?.latitude &&
              !!item?.longitude && (
                <BusPin
                  lat={item?.latitude}
                  lng={item?.longitude}
                  routeCode={item?.routeCode}
                  vehicleRegNo={item?.vehicleRegNo}
                  key={item?.vehicleRegNo + item?.latitude + item?.longitude}
                  time={item?.timestamp}
                  toggleBusInfo={toggleBusInfo}
                  selectedBusId={selectedBusId}
                />
              )
          )}
        {routeID.length > 0 &&
          BusDataActive?.map(
            (item) =>
              routeID.includes(item?.routeCode) &&
              !!item?.latitude &&
              !!item?.longitude && (
                <BusPin
                  lat={item?.latitude}
                  lng={item?.longitude}
                  routeCode={item?.routeCode}
                  vehicleRegNo={item?.vehicleRegNo}
                  key={item?.vehicleRegNo + item?.latitude + item?.longitude}
                  time={item?.timestamp}
                  toggleBusInfo={toggleBusInfo}
                  selectedBusId={selectedBusId}
                />
              )
          )}
      </GoogleMapReact>
      <ReactTooltip />
    </div>
  );
}

export default MapView;
