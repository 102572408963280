import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { GrFormClose } from "react-icons/gr";
import { FaEdit } from "react-icons/fa";
import Select from "react-select";
import moment from "moment";
import { Button } from "antd";
import { editPIS } from "../api/CRUD/EditPis";
import { stringify } from "querystring";
import { useAppSelector, useAppDispatch } from "../redux/hooks";
import { GenerateAccessToken } from "../api/generateAccessToken";
import { Logout } from "../api/login/logout";
import { logout, setAccessToken } from "../redux/slice/UserSlice";

export const PISEdit: React.FC<{
  setToggleModal: React.Dispatch<React.SetStateAction<boolean>>;
  data: any;
  callgetPIS: any;
}> = ({ setToggleModal = () => {}, data, callgetPIS }) => {
  const User = useAppSelector((state: any) => state.User);
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [Id, setId] = useState(data.id);
  const [Name, setName] = useState(data.name);
  const [DestinationDirection, setDestinationDirection] = useState(
    "[" + data?.dis_direction[0] + "," + data?.dis_direction[1] + "]"
  );
  const [Lat, setLat] = useState(data.lat);
  const [Long, setLong] = useState(data.long);
  const [DisLat, setDisLat] = useState(data.dis_lat);
  const [DisLong, setDisLong] = useState(data.dis_long);
  const [DeviceId1, setDeviceId1] = useState(data.dev_id_1);
  const [DeviceId2, setDeviceId2] = useState(data.dev_id_2);

  const [Remarks, setRemarks] = useState(data.remarks);

  const regenerateToken = async () => {
    const res = await GenerateAccessToken(User.refreshToken).catch((e) => {
      Logout(User.refreshToken);;
      dispatch(logout());
    });
    if (!!res?.token) {
      dispatch(
        setAccessToken({
          token: res?.token,
        })
      );
    }
  };

  const callEditPIS = async () => {
    if (
      !!Id &&
      !!Name &&
      !!DestinationDirection &&
      !!Lat &&
      !!Long &&
      !!DisLat &&
      !!DisLong &&
      !!DeviceId1 &&
      !!DeviceId2 &&
      !!Remarks
    ) {
      const result = await editPIS(
        Id,
        Name,
        DestinationDirection,
        Lat,
        Long,
        DisLat,
        DisLong,
        DeviceId1,
        DeviceId2,
        Remarks,
        User?.token
      ).catch((err) => {
        if (err.response.status === 401) {
          regenerateToken();
          callEditPIS();
        }
      });
      if (result.status === 200) {
        enqueueSnackbar("Row Edited Successfully", {
          variant: "success",
        });
        callgetPIS();
        setToggleModal(false);
      } else {
        enqueueSnackbar("Failed to edit", {
          variant: "error",
        });
      }
    } else {
      window.alert("Please fill all the fields.");
    }
  };

  return (
    <div className="bg-white w-4/6 absolute">
      <div className="flex flex-row justify-between px-2 pt-2 pb-1 bg-slate-300 border-b-2">
        <p className="font-semibold flex flex-row m-0">
          <span className="pt-1 pr-2">
            <FaEdit />
          </span>{" "}
          Edit
        </p>
        <GrFormClose
          size={25}
          className="cursor-pointer"
          onClick={() => setToggleModal(false)}
        />
      </div>
      <div className="mx-5 mt-5 mb-10">
        <div className="flex flex-col justify-center ">
          <div className="flex flex-row justify-between gap-x-10 pb-4">
            <div className="flex flex-col flex-1">
              <label className="text-sm pb-2 font-semibold text-gray-500">
                <span className=" text-blue-600">*</span>Bus Stop Name
              </label>
              <input
                type="text"
                className="border-2 border-gray-700 px-3 py-2"
                value={Name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="flex flex-col flex-1">
              <label className="text-sm pb-2 font-semibold text-gray-500">
                <span className=" text-blue-600">*</span>Destination Direction
              </label>
              <input
                type="text"
                className="border-2 border-gray-700 px-3 py-2"
                value={DestinationDirection}
                onChange={(e) => setDestinationDirection(e.target.value)}
              />
            </div>
          </div>
          <div className="flex flex-row justify-between gap-x-10 pb-4">
            <div className="flex flex-col flex-1">
              <label className="text-sm pb-2 font-semibold text-gray-500">
                <span className=" text-blue-600">*</span>Latitude
              </label>
              <input
                type="text"
                className="border-2 border-gray-700 px-3 py-2"
                value={Lat}
                onChange={(e) => setLat(e.target.value)}
              />
            </div>
            <div className="flex flex-col flex-1">
              <label className="text-sm pb-2 font-semibold text-gray-500">
                <span className=" text-blue-600">*</span>Longitude
              </label>
              <input
                type="text"
                className="border-2 border-gray-700 px-3 py-2"
                value={Long}
                onChange={(e) => setLong(e.target.value)}
              />
            </div>
          </div>
          <div className="flex flex-row justify-between gap-x-10 pb-4">
            <div className="flex flex-col flex-1">
              <label className="text-sm pb-2 font-semibold text-gray-500">
                <span className=" text-blue-600">*</span>Destination Latitude
              </label>
              <input
                type="text"
                className="border-2 border-gray-700 px-3 py-2"
                value={DisLat}
                onChange={(e) => setDisLat(e.target.value)}
              />
            </div>
            <div className="flex flex-col flex-1">
              <label className="text-sm pb-2 font-semibold text-gray-500">
                <span className=" text-blue-600">*</span>Destination Longitude
              </label>
              <input
                type="text"
                className="border-2 border-gray-700 px-3 py-2"
                value={DisLong}
                onChange={(e) => setDisLong(e.target.value)}
              />
            </div>
          </div>
          <div className="flex flex-row justify-between gap-x-10 pb-4">
            <div className="flex flex-col flex-1">
              <label className="text-sm pb-2 font-semibold text-gray-500">
                <span className=" text-blue-600">*</span>Device Id 1
              </label>
              <input
                type="text"
                className="border-2 border-gray-700 px-3 py-2"
                value={DeviceId1}
                onChange={(e) => setDeviceId1(e.target.value)}
              />
            </div>
            <div className="flex flex-col flex-1">
              <label className="text-sm pb-2 font-semibold text-gray-500">
                <span className=" text-blue-600">*</span>Device Id 2
              </label>
              <input
                type="text"
                className="border-2 border-gray-700 px-3 py-2"
                value={DeviceId2}
                onChange={(e) => setDeviceId2(e.target.value)}
              />
            </div>
          </div>
          <div className="flex flex-row justify-between gap-x-10 pb-4">
            <div className="flex flex-col flex-1">
              <label className="text-sm pb-2 font-semibold text-gray-500">
                <span className=" text-blue-600">*</span>Remarks
              </label>
              <input
                type="text"
                className="border-2 border-gray-700 px-3 py-2"
                value={Remarks}
                onChange={(e) => setRemarks(e.target.value)}
              />
            </div>
          </div>
          <div className="flex flex-row justify-between">
            <p className="text-xs text-blue-600 pt-2">
              *Fields cannot be made empty
            </p>
            <Button onClick={callEditPIS} type="primary">
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
