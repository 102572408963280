import axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from "axios";

export interface getBusStopType {
  id: number;
  name: string;
  lat: string;
  long: string;
  dev_id_1: string;
  dev_id_2: string;
  remarks: string;
  createdAt: string;
  updatedAt: string;
  dis_lat: string;
  dis_long: string;
  dis_direction: number[];
}

export interface getBusStopData {
  status: string;
  data: getBusStopType[];
}

export const getBusStop = async (token: string) => {
  var config: AxiosRequestConfig = {
    method: "get",
    url: "https://pis.distronix.in:5443/api/v1.1/busStop/getBusStop",
    headers: {
      "x-access-token": token,
    },
  };

  const result = await axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      console.log(error);
    });
  return result;
};
