import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { GrFormClose } from "react-icons/gr";
import { FaEdit } from "react-icons/fa";
import { Button } from "antd";
import { createRFID } from "../api/CRUD/CreateRFID";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { GenerateAccessToken } from "../api/generateAccessToken";
import { Logout } from "../api/login/logout";
import { logout, setAccessToken } from "../redux/slice/UserSlice";

export const RFIDCreate: React.FC<{
  setToggleModal: React.Dispatch<React.SetStateAction<boolean>>;
  callgetRFID: any;
}> = ({ setToggleModal = () => {}, callgetRFID: callgetPIS }) => {
  const User = useAppSelector((state: any) => state.User);
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [RfTag, setRfTag] = useState<string | undefined>();
  const [License, setLicense] = useState<string>();
  const [RtName, setRtName] = useState<string>();

  const regenerateToken = async () => {
    const res = await GenerateAccessToken(User.refreshToken).catch((e) => {
      Logout(User.refreshToken);;
      dispatch(logout());
    });
    if (!!res?.token) {
      dispatch(
        setAccessToken({
          token: res?.token,
        })
      );
    }
  };

  const callCreateRFID = async () => {
    if (!!RfTag && !!License && !!RtName) {
      const result = await createRFID(
        RfTag,
        License,
        RtName,
        User?.token
      ).catch((err) => {
        if (err.response.status === 401) {
          regenerateToken();
          callCreateRFID();
        }
      });
      if (result.status === 200) {
        enqueueSnackbar("Data Inserted Successfully", {
          variant: "success",
        });
        callgetPIS();
        setToggleModal(false);
      } else {
        enqueueSnackbar("Data Insertion Failed", {
          variant: "error",
        });
      }
    } else {
      window.alert("Please fill all the fields.");
    }
  };
  return (
    <div className="bg-white w-4/6 absolute">
      <div className="flex flex-row justify-between px-2 pt-2 pb-1 bg-slate-300 border-b-2">
        <p className="font-semibold flex flex-row m-0">
          <span className="pt-1 pr-2">
            <FaEdit />
          </span>{" "}
          Create
        </p>
        <GrFormClose
          size={25}
          className="cursor-pointer"
          onClick={() => setToggleModal(false)}
        />
      </div>
      <div className="mx-5 mt-5 mb-10">
        <div className="flex flex-col justify-center ">
          <div className="flex flex-row justify-between gap-x-10 pb-4">
            <div className="flex flex-col flex-1">
              <label className="text-sm pb-2 font-semibold text-gray-500">
                <span className=" text-blue-600">*</span>RFID Tag
              </label>
              <input
                type="text"
                className="border-2 border-gray-700 px-3 py-2"
                value={RfTag}
                onChange={(e) => setRfTag(e.target.value)}
              />
            </div>
            <div className="flex flex-col flex-1">
              <label className="text-sm pb-2 font-semibold text-gray-500">
                <span className=" text-blue-600">*</span>License Plate
              </label>
              <input
                type="text"
                className="border-2 border-gray-700 px-3 py-2"
                value={License}
                onChange={(e) => setLicense(e.target.value)}
              />
            </div>
          </div>
          <div className="flex flex-row justify-between gap-x-10 pb-4">
            <div className="flex flex-col flex-1">
              <label className="text-sm pb-2 font-semibold text-gray-500">
                <span className=" text-blue-600">*</span>RFID Tag Name
              </label>
              <input
                type="text"
                className="border-2 border-gray-700 px-3 py-2"
                value={RtName}
                onChange={(e) => setRtName(e.target.value)}
              />
            </div>
          </div>
          <div className="flex flex-row justify-between">
            <p className="text-xs text-blue-600 pt-2">
              *Fields cannot be made empty
            </p>
            <Button type="primary" onClick={() => callCreateRFID()}>
              Create
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
