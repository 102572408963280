import { Markers as MarkersData } from './types';
import { Master as MasterData } from './types';

export const Markers: MarkersData = {
    tag: [
        {
            id: "1",
            stoppage: "COAL INDIA",
            mac: "coalIndia",
            lat: "22.58131939888511",
            lng: "88.44733977453745"
        },
        {
            id: "2",
            stoppage: "DLF-I (near subway)",
            mac: "dlf-1",
            lat: "22.581514821781187",
            lng: "88.4501206485423"
        },
        {
            id: "3",
            stoppage: "DLF-I (near subway)-Opp. Side",
            mac: "dlf-1_opp",
            lat: "22.581431722529423",
            lng: "88.45045910837757"
        },
        {
            id: "4",
            stoppage: "Nazrul Tirtha-I",
            mac: "E4:5F:01:20:EC:90",
            lat: "22.582002671494756",
            lng: "88.45276941735895"
        },
        {
            id: "5",
            stoppage: "Novotel",
            mac: "novotel",
            lat: "22.580567144205112",
            lng: "88.46252253406863"
        },
        {
            id: "6",
            stoppage: "Swapnabhor",
            mac: "swapnabhar",
            lat: "22.579662233265882",
            lng: "88.46716275840852"
        },
        {
            id: "7",
            stoppage: "Rabindra Tirtha-I",
            mac: "rabindratirtha",
            lat: "22.579189455377275",
            lng: " 88.47143798775977"
        },
        {
            id: "8",
            stoppage: "Prakriti Tirtha-I",
            mac: "prakritiTirtha-1",
            lat: "22.600199125409045",
            lng: "88.47086861328967"
        },
        {
            id: "9",
            stoppage: "Misti Hub",
            mac: "E4:5F:01:20:ED:1D",
            lat: "22.607913",
            lng: "88.468305"
        },
        {
            id: "10",
            stoppage: "Akankha",
            mac: "a",
            lat: "22.618420449331314",
            lng: "88.46440017855724"
        },
        {
            id: "11",
            stoppage: "City Centre-II",
            mac: "b",
            lat: "22.621476228514904",
            lng: "88.45075501614858"
        },
        {
            id: "12",
            stoppage: "7th Rotary",
            mac: "c",
            lat: "22.62074475905036",
            lng: "88.4542519652644"
        },
        {
            id: "13",
            stoppage: "Akankha",
            mac: "d",
            lat: "22.61829108290943",
            lng: "88.46497484183641"
        },
        {
            id: "14",
            stoppage: "Misti Hub",
            mac: "e",
            lat: "22.607132",
            lng: "88.468788"
        },
        {
            id: "15",
            stoppage: "Owl more",
            mac: "owlMore",
            lat: "22.604525835901345",
            lng: "88.46970495011577"
        },
        {
            id: "16",
            stoppage: "Prakriti Tirtha-2",
            mac: "prakritiTirtha-2",
            lat: "22.600712",
            lng: "88.470973"
        },
        {
            id: "17",
            stoppage: "westin",
            mac: "westin",
            lat: "22.592894",
            lng: "88.473469"
        },
        {
            id: "18",
            stoppage: "Rabindra Tirtha",
            mac: "f",
            lat: "22.578670683485697",
            lng: "88.47261092386648"
        },
        {
            id: "19",
            stoppage: "Tata Cancer",
            mac: "tataCancer",
            lat: "22.57702",
            lng: "88.478696"
        },
        {
            id: "20",
            stoppage: "TCS",
            mac: "g",
            lat: "22.580851",
            lng: "88.487201"
        },
        {
            id: "21",
            stoppage: "ALIA",
            mac: "h",
            lat: "22.589012396244858",
            lng: "88.48451253563424"
        },
        {
            id: "22",
            stoppage: "ALIA",
            mac: "i",
            lat: "22.588482411081948",
            lng: "88.48521961808348"
        },
        {
            id: "23",
            stoppage: "TCS",
            mac: "j",
            lat: "22.580597",
            lng: "88.487269"
        },
        {
            id: "24",
            stoppage: "Unitech Gate-1",
            mac: "k",
            lat: "22.573189",
            lng: "88.480168"
        },
        {
            id: "25",
            stoppage: "Eye Hospital",
            mac: "l",
            lat: "22.574276",
            lng: "88.480192"
        },
        {
            id: "26",
            stoppage: "Newtown P.S.",
            mac: "m",
            lat: "22.576236",
            lng: "88.479271"
        },
        {
            id: "27",
            stoppage: "Techno India College",
            mac: "n",
            lat: "22.577836",
            lng: "88.475431"
        },
        {
            id: "28",
            stoppage: "Rabindra Tirtha-II",
            mac: "o",
            lat: "22.578748",
            lng: "88.471403"
        },
        {
            id: "29",
            stoppage: "1st Rotary",
            mac: "1stRotary",
            lat: "22.581135",
            lng: "88.452895"
        }
    ]
}

export const Master: MasterData ={
    Data: [
        {
            "serial": "1",
            "A": "B",
            "B": "C",
            "C": "D",
            "D": "E"
        },
        {
            "serial": "2",
            "A": "C",
            "B": "D",
            "C": "E",
            "D": "F"
        },
        {
            "serial": "3",
            "A": "D",
            "B": "E",
            "C": "F",
            "D": "G"
        },
        {
            "serial": "4",
            "A": "E",
            "B": "F",
            "C": "G",
            "D": "H"
        },
        {
            "serial": "5",
            "A": "F",
            "B": "G",
            "C": "H",
            "D": "I"
        },
        {
            "serial": "6",
            "A": "G",
            "B": "H",
            "C": "I",
            "D": "J"
        },
        {
            "serial": "7",
            "A": "H",
            "B": "I",
            "C": "J",
            "D": "K"
        },
        {
            "serial": "8",
            "A": "I",
            "B": "J",
            "C": "K",
            "D": "L"
        },
        {
            "serial": "9",
            "A": "J",
            "B": "K",
            "C": "L",
            "D": "M"
        },
        {
            "serial": "10",
            "A": "K",
            "B": "L",
            "C": "M",
            "D": "N"
        },
        {
            "serial": "11",
            "A": "L",
            "B": "M",
            "C": "N",
            "D": "O"
        },
        {
            "serial": "12",
            "A": "M",
            "B": "N",
            "C": "O",
            "D": "P"
        },
        {
            "serial": "13",
            "A": "N",
            "B": "O",
            "C": "P",
            "D": "Q"
        },
        {
            "serial": "14",
            "A": "O",
            "B": "P",
            "C": "Q",
            "D": "R"
        },
        {
            "serial": "15",
            "A": "P",
            "B": "Q",
            "C": "R",
            "D": "S"
        },
        {
            "serial": "16",
            "A": "Q",
            "B": "R",
            "C": "S",
            "D": "T"
        },
        {
            "serial": "17",
            "A": "R",
            "B": "S",
            "C": "T",
            "D": "U"
        },
        {
            "serial": "18",
            "A": "S",
            "B": "T",
            "C": "U",
            "D": "V"
        },
        {
            "serial": "19",
            "A": "T",
            "B": "U",
            "C": "V",
            "D": "W"
        },
        {
            "serial": "20",
            "A": "U",
            "B": "V",
            "C": "W",
            "D": "X"
        },
        {
            "serial": "21",
            "A": "V",
            "B": "W",
            "C": "X",
            "D": "Y"
        },
        {
            "serial": "22",
            "A": "W",
            "B": "X",
            "C": "Y",
            "D": "Z"
        },
        {
            "serial": "23",
            "A": "X",
            "B": "Y",
            "C": "Z",
            "D": "A"
        },
        {
            "serial": "24",
            "A": "Y",
            "B": "Z",
            "C": "A",
            "D": "B"
        },
        {
            "serial": "25",
            "A": "Z",
            "B": "A",
            "C": "B",
            "D": "C"
        }
    ]
}

export const routeIDarr = [
    {
        "id": "1",
        "value": "215A:DN",
        "label": "215A:DN"
    },
    {
        "id": "2",
        "value": "215A:UP",
        "label": "215A:UP"
    },
    {
        "id": "3",
        "value": "260:DN",
        "label": "260:DN"
    },
    {
        "id": "4",
        "value": "260:UP",
        "label": "260:UP"
    },
    {
        "id": "5",
        "value": "46B:DN",
        "label": "46B:DN"
    },
    {
        "id": "6",
        "value": "46B:UP",
        "label": "46B:UP"
    },
    {
        "id": "7",
        "value": "AC12:UP",
        "label": "AC12:UP"
    },
    {
        "id": "8",
        "value": "AC23A:DN",
        "label": "AC23A:DN"
    },
    {
        "id": "9",
        "value": "AC25A:DN",
        "label": "AC25A:DN"
    },
    {
        "id": "10",
        "value": "AC30S:DN",
        "label": "AC30S:DN"
    },
    {
        "id": "11",
        "value": "AC35:DN",
        "label": "AC35:DN"
    },
    {
        "id": "12",
        "value": "AC35:UP",
        "label": "AC35:UP"
    },
    {
        "id": "13",
        "value": "AC36:UP",
        "label": "AC36:UP"
    },
    {
        "id": "14",
        "value": "AC38:UP",
        "label": "AC38:UP"
    },
    {
        "id": "15",
        "value": "AC47:DN",
        "label": "AC47:DN"
    },
    {
        "id": "16",
        "value": "AC47:UP",
        "label": "AC47:UP"
    },
    {
        "id": "17",
        "value": "AC4B:UP",
        "label": "AC4B:UP"
    },
    {
        "id": "18",
        "value": "ACT2:DN",
        "label": "ACT2:DN"
    },
    {
        "id": "19",
        "value": "ACT3A:DN",
        "label": "ACT3A:DN"
    },
    {
        "id": "20",
        "value": "ACT4/S:DN",
        "label": "ACT4/S:DN"
    },
    {
        "id": "21",
        "value": "ACT4:DN",
        "label": "ACT4:DN"
    },
    {
        "id": "22",
        "value": "BGN:DN",
        "label": "BGN:DN"
    },
    {
        "id": "23",
        "value": "BGN:UP",
        "label": "BGN:UP"
    },
    {
        "id": "24",
        "value": "BSP6:UP",
        "label": "BSP6:UP"
    },
    {
        "id": "25",
        "value": "C11/2:DN",
        "label": "C11/2:DN"
    },
    {
        "id": "26",
        "value": "C11/2:UP",
        "label": "C11/2:UP"
    },
    {
        "id": "27",
        "value": "C23:UP",
        "label": "C23:UP"
    },
    {
        "id": "28",
        "value": "C31:DN",
        "label": "C31:DN"
    },
    {
        "id": "29",
        "value": "C33:UP",
        "label": "C33:UP"
    },
    {
        "id": "30",
        "value": "C43:DN",
        "label": "C43:DN"
    },
    {
        "id": "31",
        "value": "C43:UP",
        "label": "C43:UP"
    },
    {
        "id": "32",
        "value": "C48:UP",
        "label": "C48:UP"
    },
    {
        "id": "33",
        "value": "C8:DN",
        "label": "C8:DN"
    },
    {
        "id": "34",
        "value": "D12:UP",
        "label": "D12:UP"
    },
    {
        "id": "35",
        "value": "D16:UP",
        "label": "D16:UP"
    },
    {
        "id": "36",
        "value": "D19:UP",
        "label": "D19:UP"
    },
    {
        "id": "37",
        "value": "D3:UP",
        "label": "D3:UP"
    },
    {
        "id": "38",
        "value": "D4:DN",
        "label": "D4:DN"
    },
    {
        "id": "39",
        "value": "D5:DN",
        "label": "D5:DN"
    },
    {
        "id": "40",
        "value": "E36A:DN",
        "label": "E36A:DN"
    },
    {
        "id": "41",
        "value": "E39:UP",
        "label": "E39:UP"
    },
    {
        "id": "42",
        "value": "E48:DN",
        "label": "E48:DN"
    },
    {
        "id": "43",
        "value": "EB1:DN",
        "label": "EB1:DN"
    },
    {
        "id": "44",
        "value": "EB1:UP",
        "label": "EB1:UP"
    },
    {
        "id": "45",
        "value": "EB3:UP",
        "label": "EB3:UP"
    },
    {
        "id": "46",
        "value": "EXE1:UP",
        "label": "EXE1:UP"
    },
    {
        "id": "47",
        "value": "KPS:UP",
        "label": "KPS:UP"
    },
    {
        "id": "48",
        "value": "M14:DN",
        "label": "M14:DN"
    },
    {
        "id": "49",
        "value": "M14:UP",
        "label": "M14:UP"
    },
    {
        "id": "50",
        "value": "MX1:DN",
        "label": "MX1:DN"
    },
    {
        "id": "51",
        "value": "MX1:UP",
        "label": "MX1:UP"
    },
    {
        "id": "52",
        "value": "NS14:DN",
        "label": "NS14:DN"
    },
    {
        "id": "53",
        "value": "NS14:UP",
        "label": "NS14:UP"
    },
    {
        "id": "54",
        "value": "S12:DN",
        "label": "S12:DN"
    },
    {
        "id": "55",
        "value": "S12:UP",
        "label": "S12:UP"
    },
    {
        "id": "56",
        "value": "S12E:DN",
        "label": "S12E:DN"
    },
    {
        "id": "57",
        "value": "S12E:UP",
        "label": "S12E:UP"
    },
    {
        "id": "58",
        "value": "S14C:DN",
        "label": "S14C:DN"
    },
    {
        "id": "59",
        "value": "S23A:UP",
        "label": "S23A:UP"
    },
    {
        "id": "60",
        "value": "S44:DN",
        "label": "S44:DN"
    },
    {
        "id": "61",
        "value": "S44:UP",
        "label": "S44:UP"
    },
    {
        "id": "62",
        "value": "S4B:DN",
        "label": "S4B:DN"
    },
    {
        "id": "63",
        "value": "S4B:UP",
        "label": "S4B:UP"
    },
    {
        "id": "64",
        "value": "S4D:UP",
        "label": "S4D:UP"
    },
    {
        "id": "65",
        "value": "V9:UP",
        "label": "V9:UP"
    },
    {
        "id": "66",
        "value": "S30:DN",
        "label": "S30:DN"
    },
    {
        "id": "67",
        "value": "S30:UP",
        "label": "S30:UP"
    }
]

  export const polyColors = ["#63b598", "#ce7d78", "#ea9e70", "#a48a9e", "#c6e1e8", "#648177" ,"#0d5ac1" ,
  "#f205e6" ,"#1c0365" ,"#14a9ad" ,"#4ca2f9" ,"#a4e43f" ,"#d298e2" ,"#6119d0",
  "#d2737d" ,"#c0a43c" ,"#f2510e" ,"#651be6" ,"#79806e" ,"#61da5e" ,"#cd2f00" ,
  "#9348af" ,"#01ac53" ,"#c5a4fb" ,"#996635","#b11573" ,"#4bb473" ,"#75d89e" ,
  "#2f3f94" ,"#2f7b99" ,"#da967d" ,"#34891f" ,"#b0d87b" ,"#ca4751" ,"#7e50a8" ,
  "#c4d647" ,"#e0eeb8" ,"#11dec1" ,"#289812" ,"#566ca0" ,"#ffdbe1" ,"#2f1179" ,
  "#935b6d" ,"#916988" ,"#513d98" ,"#aead3a", "#9e6d71", "#4b5bdc", "#0cd36d",
  "#250662", "#cb5bea", "#228916", "#ac3e1b", "#df514a", "#539397", "#880977",
  "#f697c1", "#ba96ce", "#679c9d", "#c6c42c", "#5d2c52", "#48b41b", "#e1cf3b",
  "#5be4f0", "#57c4d8", "#a4d17a", "#225b8", "#be608b", "#96b00c", "#088baf",];
