import Header from "../../components/Header";
import { Redirect, Route, Switch } from "react-router-dom";

import { GuestRoutes } from "./route";

export const GuestLayout = () => {
  const LayoutPath = "/guest";
  const getRoutes = () => {
    return GuestRoutes.map((item, key) => (
      <Route path={LayoutPath + item.path} key={key}>
        <item.Component />
      </Route>
    ));
  };
  return (
    <div>
      <Header/>
      <Switch>
        {getRoutes()}
        <Route path="/">
          <Redirect to="/guest/signin" />
        </Route>
      </Switch>
    </div>
  );
};
