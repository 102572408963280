import React, { useCallback, useEffect, useState } from "react";
import Header from "../../components/Header";
import { Redirect, Route, Switch } from "react-router-dom";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";

import { UserRoutes } from "./route";

import axios, { AxiosRequestConfig } from "axios";
import { Tab, Tabs } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { GenerateAccessToken } from "../../api/generateAccessToken";
import { logout, setAccessToken } from "../../redux/slice/UserSlice";
import { Logout } from "../../api/login/logout";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  })
);

export const UserLayout = () => {
  const User = useAppSelector((state: any) => state.User);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const interval = setInterval(async () => {
      const res = await GenerateAccessToken(User.refreshToken).catch((e) => {
        console.log(e);
        Logout(User.refreshToken);;
        dispatch(logout());
      });
      if (!!res?.token) {
        dispatch(setAccessToken({ token: res.token }));
      }
    }, 1000 * 60);
    return () => clearInterval(interval);
  }, []);

  const LayoutPath = "/user";

  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getRoutes = () => {
    return UserRoutes.map((item, key) => (
      <Route path={LayoutPath + item.path} key={key}>
        <item.Component />
      </Route>
    ));
  };

  if (!User.loggedIn) return <Redirect to="/guest/signin" />;
  return (
    <>
      <div className="App">
        <Header />
        <Switch>
          {getRoutes()}
          <Route path="/">
            <Redirect to="/user/map" />
          </Route>
        </Switch>
      </div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          style={{ marginLeft: "30%", marginTop: "10%" }}
          className={classes.paper}
        >
          <Tabs
            defaultActiveKey="Add New User"
            id="uncontrolled-tab-example"
            style={{ display: "flex", justifyContent: "center", fontSize: 16 }}
          ></Tabs>
        </div>
      </Modal>
    </>
  );
};
