import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSquare,
  faSignal,
  faWifi,
  faMicrochip,
  faSlash,
  faCloud,
  faCloudUploadAlt,
  faBus,
  faRoute,
} from "@fortawesome/free-solid-svg-icons";
import { GrDevice } from "react-icons/gr";
import Toggle from "react-toggle";
import styles from "./PISMaps.module.css";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

const MapsKPI = (props: any) => {
  const [KPIState, setKPIState] = useState(false);
  const handleKPIState = () => {
    setKPIState(!KPIState);
  };
  return (
    <div>
      <div onClick={handleKPIState} className={styles.KPIToggleContainer}>
        <div className={styles.KPIToggle}>
          <Toggle
            id="KPIStatus"
            defaultChecked={KPIState}
            onChange={handleKPIState}
          />
        </div>
        <label htmlFor="KPIStatus">KPI</label>
      </div>
      {KPIState ? (
        <div className={styles.KPIContainer}>
          {/* Total Devices  */}
          <Card
            className={styles.Card}
            style={{ backgroundColor: "#D7393B", color: "#fff" }}
          >
            <CardContent>
              <div className={styles.CardHeader}>
                <p className={styles.Digit}>
                  {props?.KPIData?.total_pis_device_installed}
                </p>
                <FontAwesomeIcon
                  style={{ paddingTop: "5px" }}
                  icon={faMicrochip}
                />
              </div>
              <div className={styles.Info}>
                <p>NUMBER OF DEVICES INSTALLED</p>
              </div>
            </CardContent>
          </Card>
          {/* Total Buses Crossed */}
          <Card
            className={styles.Card}
            style={{ backgroundColor: "#26A9BB", color: "#fff" }}
          >
            <CardContent>
              <div className={styles.CardHeader}>
                <p className={styles.Digit}>{props?.KPIData?.online_system}</p>
                <FontAwesomeIcon style={{ paddingTop: "5px" }} icon={faWifi} />
              </div>
              <div className={styles.Info}>
                <p>NUMBER OF ONLINE DEVICES</p>
              </div>
            </CardContent>
          </Card>
          {/* Total Buses Missed */}
          <Card
            className={styles.Card}
            style={{ backgroundColor: "#4B9C5C", color: "#fff" }}
          >
            <CardContent>
              <div className={styles.CardHeader}>
                <p className={styles.Digit}>
                  {props?.KPIData?.total_pis_device_installed -
                    props?.KPIData?.online_system}
                </p>
                <div>
                  <FontAwesomeIcon
                    style={{ paddingTop: "5px" }}
                    icon={faWifi}
                  />
                  <FontAwesomeIcon
                    style={{
                      marginLeft: "-20px",
                      paddingTop: "6px",
                      color: "#000",
                    }}
                    icon={faSlash}
                  />
                </div>
              </div>
              <div className={styles.Info}>
                <p>NUMBER OF OFFLINE DEVICES</p>
              </div>
            </CardContent>
          </Card>
          {/* Total Devices Online  */}
          <Card
            className={styles.Card}
            style={{ backgroundColor: "#F78224", color: "#fff" }}
          >
            <CardContent>
              <div className={styles.CardHeader}>
                <p className={styles.Digit}>
                  {props?.KPIData?.total_no_of_buses}
                </p>
                <FontAwesomeIcon style={{ paddingTop: "5px" }} icon={faBus} />
              </div>
              <div className={styles.Info}>
                <p>TOTAL NUMBER OF BUSES</p>
              </div>
            </CardContent>
          </Card>
          <Card
            className={styles.Card}
            style={{ backgroundColor: "#009587", color: "#fff" }}
          >
            <CardContent>
              <div className={styles.CardHeader}>
                <p className={styles.Digit}>{props?.KPIData?.bus_on_route}</p>
                <div>
                  <FontAwesomeIcon
                    style={{ paddingTop: "5px" }}
                    icon={faRoute}
                  />
                  <FontAwesomeIcon
                    style={{
                      marginLeft: "-20px",
                      paddingTop: "6px",
                      color: "#000",
                    }}
                    icon={faSlash}
                  />
                </div>
              </div>
              <div className={styles.Info}>
                <p>BUSES ON ROUTE</p>
              </div>
            </CardContent>
          </Card>
          <Card
            className={styles.Card}
            style={{ backgroundColor: "#5F7C8A", color: "#fff" }}
          >
            <CardContent>
              <div className={styles.CardHeader}>
                <p className={styles.Digit}>
                  {props?.KPIData?.total_no_of_buses -
                    props?.KPIData?.bus_on_route}
                </p>
                <FontAwesomeIcon style={{ paddingTop: "5px" }} icon={faRoute} />
              </div>
              <div className={styles.Info}>
                <p>BUSES NOT ON ROUTE</p>
              </div>
            </CardContent>
          </Card>
        </div>
      ) : null}
    </div>
  );
};

export default MapsKPI;
