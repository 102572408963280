import React, { useEffect, useRef, useState } from "react";
import SideBar from "../components/SideBar";
import styles from "./MasterData.module.css";
import { ClickEventArgs } from "@syncfusion/ej2-navigations";
import { Button } from "antd";
import "antd/dist/antd.css";
import { Modal } from "@material-ui/core";
import { FiEdit, FiDelete } from "react-icons/fi";
import {
  ColumnDirective,
  ColumnsDirective,
  FilterSettingsModel,
  GridComponent,
  Toolbar,
  Inject,
  PdfExport,
  Grid,
  ToolbarItems,
  ExcelExport,
  Filter,
  Sort,
  Page,
} from "@syncfusion/ej2-react-grids";
import { RFIDEdit } from "../components/RFIDEdit";
import { RFIDCreate } from "../components/RFIDCreate";
import { getRFID } from "../api/CRUD/getRFID";
import { GenerateAccessToken } from "../api/generateAccessToken";
import { logout, setAccessToken } from "../redux/slice/UserSlice";
import { Logout } from "../api/login/logout";
import { useAppDispatch, useAppSelector } from "../redux/hooks";

function RFIDMaster() {
  const filterOptions: FilterSettingsModel = {
    type: "Excel",
  };
  // @ts-ignore
  const gridRef = useRef<Grid | null>(null);
  const toolbar: ToolbarItems[] = ["ExcelExport", "Search"];
  const toolbarClick = (args: ClickEventArgs | undefined) => {
    if (gridRef.current === null || !args) return;
    switch (args.item.text) {
      case "Excel Export":
        gridRef.current.excelExport();
        break;
      case "PDF Export":
        gridRef.current.pdfExport();
        break;
      case "CSV Export":
        gridRef.current.csvExport();
        break;
    }
  };

  const User = useAppSelector((state: any) => state.User);
  const dispatch = useAppDispatch();

  const [RFIDData, setRFIDData] = useState<any[]>([]);

  const regenerateToken = async () => {
    const res = await GenerateAccessToken(User.refreshToken).catch((e) => {
      Logout(User.refreshToken);;
      dispatch(logout());
    });
    if (!!res?.token) {
      dispatch(
        setAccessToken({
          token: res?.token,
        })
      );
    }
  };

  const callgetRFID = async () => {
    const result = await getRFID(User?.token).catch((err) => {
      if (err.response.status === 401) {
        regenerateToken();
        callgetRFID();
      }
    });

    const snoData = result?.data?.data.map((item: any, index: any) => ({
      ...item,
      sno: index + 1,
    }));
    setRFIDData(snoData);
  };

  useEffect(() => {
    callgetRFID();
  }, []);

  const [ToggleEdit, setToggleEdit] = useState(false);
  const [ToggleCreate, setToggleCreate] = useState(false);

  const [Ob, setOb] = useState<any>();

  const editTemplate = (args: any) => {
    return (
      <FiEdit style={{ cursor: "pointer" }} onClick={() => editRFID(args)} />
    );
  };

  const editRFID = (args: any) => {
    setOb(args);
    setToggleEdit(true);
  };

  return (
    <div className={styles.Container}>
      <div className={styles.SideBar}>
        <SideBar />
      </div>
      <div className={styles.Body}>
        <h2>RFID Master Data</h2>
        <Button
          onClick={() => setToggleCreate(true)}
          style={{ marginLeft: "10px" }}
          type="dashed"
        >
          Add New +
        </Button>
        <Modal
          open={ToggleEdit}
          // onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className="flex flex-row justify-around items-center"
        >
          <RFIDEdit
            setToggleModal={setToggleEdit}
            data={Ob}
            callgetRFID={callgetRFID}
          />
        </Modal>
        <Modal
          open={ToggleCreate}
          // onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className="flex flex-row justify-around items-center"
        >
          <RFIDCreate
            setToggleModal={setToggleCreate}
            callgetRFID={callgetRFID}
          />
        </Modal>
        <div className={styles.Table}>
          <GridComponent
            toolbarClick={toolbarClick}
            ref={(g) => (gridRef.current = g)}
            filterSettings={filterOptions}
            dataSource={RFIDData}
            toolbar={toolbar}
            pageSettings={{ pageSize: 50 }}
            allowFiltering
            allowPaging
            allowSorting
            allowPdfExport
            allowExcelExport
            style={{
              margin: 10,
            }}
            height={410}
          >
            <ColumnsDirective>
              <ColumnDirective width="120" field="sno" headerText="S. no." />
              <ColumnDirective
                width="200"
                field="rf_tag"
                headerText="RFID Tag"
              />
              <ColumnDirective
                width="200"
                field="license_plate"
                headerText="License Plate"
              />
              <ColumnDirective
                width="200"
                field="rt_name"
                headerText="RFID Tag Name"
              />
              <ColumnDirective
                headerText="Edit"
                textAlign="Left"
                width="120"
                template={editTemplate}
              />
            </ColumnsDirective>
            <Inject
              services={[Toolbar, ExcelExport, PdfExport, Filter, Sort, Page]}
            />
          </GridComponent>
        </div>
      </div>
    </div>
  );
}

export default RFIDMaster;
