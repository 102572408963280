import React, { useEffect, useRef, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Collapse from "@material-ui/core/Collapse";
import styles from "./PISMaps.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBatteryHalf,
  faBolt,
  faCircle,
  faChartLine,
} from "@fortawesome/free-solid-svg-icons";
import { makeStyles } from "@material-ui/core/styles";
import { Select } from "antd";
import { useAppDispatch, useAppSelector } from "../redux/hooks";

const MonitorCollapse = (props: any) => {
  const User = useAppSelector((state: any) => state.User);
  const dispatch = useAppDispatch();
  const { activeItem, handleMarkerInfoOpen, Markers, StoppageOptions } = props;
  const [checked, setChecked] = React.useState(false);
  const { status } = props;
  const togglesCollapse = () => {
    setChecked((prev) => !prev);
  };
  const collapseStyles = makeStyles((theme) => ({
    root: {
      height: 180,
    },
    container: {
      zIndex: 1,
      position: "absolute",
      background: "#28313B",
      boxShadow: "rgb(0 0 0 / 30%) 0px 1px 4px -1px",
      margin: "42px 0 0 10px",
      width: "300px",
    },
    paperWhite: {
      margin: theme.spacing(1),
      cursor: "pointer",
      background: "#fff",
    },
    paperSelected: {
      margin: theme.spacing(1),
      cursor: "pointer",
      background: "#dedede",
    },
  }));
  const classesCollapse = collapseStyles();

  useEffect(() => {
    if (!!activeItem) {
      setChecked(true);
      document.getElementById(activeItem)?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [activeItem]);

  const { Option } = Select;
  const [Search, setSearch] = useState<string>("");
  return (
    <div>
      <div className={styles.BusStopContainer} onClick={togglesCollapse}>
        <FontAwesomeIcon
          icon={faChartLine}
          color="#fff"
          style={{ margin: "10px 10px" }}
        />
        <p> Monitor Bus Stops</p>
      </div>
      <div className={classesCollapse.container}>
        <Collapse in={checked}>
          <Select
            showSearch
            allowClear
            style={{
              width: "265px",
              marginLeft: 18,
              marginTop: 20,
              borderRadius: 10,
              marginBottom: 5,
            }}
            placeholder="Search Device Location"
            onChange={(e) => {
              setSearch(e);
            }}
          >
            {StoppageOptions.map((item: any) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
          <div className={styles.MonitorCollapse}>
            {Markers.map((item: any, index: any) =>
              Search?.length > 0 ? (
                Search.includes(item.stoppage) && (
                  <Paper
                    id={item.mac}
                    key={item.mac + item.lat}
                    className={
                      item.mac === activeItem
                        ? classesCollapse.paperSelected
                        : classesCollapse.paperWhite
                    }
                    onClick={() => {
                      handleMarkerInfoOpen(item);
                    }}
                  >
                    <div
                      className={styles.MonitorSideBarHeader}
                      style={{ paddingTop: 10 }}
                    >
                      <p>DEVICE LOCATION</p>
                      <h2>{item.stoppage}</h2>
                    </div>
                    <div className={styles.Diagnostic}>
                      <div className={styles.DiagnosticStatus}>
                        <p>DEVICE STATUS</p>
                        <FontAwesomeIcon
                          className={styles.Active}
                          icon={faCircle}
                          color={
                            status.find(
                              (data: any) => data.name === item.stoppage
                            )?.pis_online_status === "Online"
                              ? "#0f0"
                              : "#FF0000"
                          }
                          size="xs"
                          title="Active"
                        />
                      </div>
                      <div className={styles.DiagnosticStatus}>
                        <p>CPU</p>
                        <p className={styles.RSSI}>
                          {!!status &&
                          status.find(
                            (data: any) => data.name === item.stoppage
                          )?.cpu_percentage
                            ? status.find(
                                (data: any) => data.name === item.stoppage
                              )?.cpu_percentage + "%"
                            : "-"}
                        </p>
                      </div>
                    </div>
                    <div className={styles.Diagnostic}>
                      <div className={styles.DiagnosticStatus}>
                        <p>MEMORY</p>
                        <p className={styles.RSSI}>
                          {!!status &&
                          status.find(
                            (data: any) => data.name === item.stoppage
                          )?.memory_percent
                            ? status.find(
                                (data: any) => data.name === item.stoppage
                              )?.memory_percent + "%"
                            : "-"}
                        </p>
                      </div>
                      <div className={styles.DiagnosticStatus}>
                        <p>DISK</p>
                        <p className={styles.RSSI}>
                          {!!status &&
                          status.find(
                            (data: any) => data.name === item.stoppage
                          )?.disk_percent
                            ? status.find(
                                (data: any) => data.name === item.stoppage
                              )?.disk_percent + "%"
                            : "-"}
                        </p>
                      </div>
                    </div>
                    <div className={styles.Diagnostic}>
                      <div className={styles.DiagnosticStatus}>
                        <p>CPU TEMP</p>
                        <p className={styles.RSSI}>
                          {!!status &&
                          status.find(
                            (data: any) => data.name === item.stoppage
                          )?.cpu_temperature
                            ? status.find(
                                (data: any) => data.name === item.stoppage
                              )?.cpu_temperature + "°C"
                            : "-"}
                        </p>
                      </div>
                    </div>
                    {/* <div className={styles.Diagnostic}>
                  <div>
                    <p style={{ color: "#000" }}>
                      {!!status && status.find((data: any) => data.name === item.stoppage)
                        ?.last_response
                        ? "Last seen " +
                          status.find(
                            (data: any) => data.name === item.stoppage
                          )?.last_response
                        : "No data"}
                    </p>
                  </div>
                </div> */}
                  </Paper>
                )
              ) : (
                <Paper
                  id={item.mac}
                  key={item.mac + item.lat}
                  className={
                    item.mac === activeItem
                      ? classesCollapse.paperSelected
                      : classesCollapse.paperWhite
                  }
                  onClick={() => handleMarkerInfoOpen(item)}
                >
                  <div
                    className={styles.MonitorSideBarHeader}
                    style={{ paddingTop: 10 }}
                  >
                    <p>DEVICE LOCATION</p>
                    <h2>{item.stoppage}</h2>
                  </div>
                  <div className={styles.Diagnostic}>
                    <div className={styles.DiagnosticStatus}>
                      <p>DEVICE STATUS</p>
                      <FontAwesomeIcon
                        className={styles.Active}
                        icon={faCircle}
                        color={
                          status?.find(
                            (data: any) => data.name === item.stoppage
                          )?.pis_online_status === "Online"
                            ? "#0f0"
                            : "#FF0000"
                        }
                        size="xs"
                        title="Active"
                      />
                    </div>
                    <div className={styles.DiagnosticStatus}>
                      <p>CPU</p>
                      <p className={styles.RSSI}>
                        {!!status &&
                        status.find((data: any) => data.name === item.stoppage)
                          ?.cpu_percentage
                          ? status
                              .find((data: any) => data.name === item.stoppage)
                              ?.cpu_percentage?.toFixed(2) + "%"
                          : "-"}
                      </p>
                    </div>
                  </div>
                  <div className={styles.Diagnostic}>
                    <div className={styles.DiagnosticStatus}>
                      <p>MEMORY</p>
                      <p className={styles.RSSI}>
                        {!!status &&
                        status?.find((data: any) => data.name === item.stoppage)
                          ?.memory_percent
                          ? status
                              ?.find((data: any) => data.name === item.stoppage)
                              ?.memory_percent?.toFixed(2) + "%"
                          : "-"}
                      </p>
                    </div>
                    <div className={styles.DiagnosticStatus}>
                      <p>DISK</p>
                      <p className={styles.RSSI}>
                        {!!status &&
                        status?.find((data: any) => data.name === item.stoppage)
                          ?.disk_percent
                          ? status
                              ?.find((data: any) => data.name === item.stoppage)
                              ?.disk_percent?.toFixed(2) + "%"
                          : "-"}
                      </p>
                    </div>
                  </div>
                  <div className={styles.Diagnostic}>
                    <div className={styles.DiagnosticStatus}>
                      <p>CPU TEMP</p>
                      <p className={styles.RSSI}>
                        {!!status &&
                        status &&
                        status.find((data: any) => data.name === item.stoppage)
                          ?.cpu_temperature
                          ? status
                              .find((data: any) => data.name === item.stoppage)
                              ?.cpu_temperature?.toFixed(2) + "°C"
                          : "-"}
                      </p>
                    </div>
                  </div>
                  {/* <div className={styles.Diagnostic}>
                  <div>
                    <p style={{ color: "#000" }}>
                      {!!status && status.find((data: any) => data.name === item.stoppage)
                        ?.last_response
                        ? "Last seen " +
                          status.find(
                            (data: any) => data.name === item.stoppage
                          )?.last_response
                        : "No data"}
                    </p>
                  </div>
                </div> */}
                </Paper>
              )
            )}
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default MonitorCollapse;
