import React, { useEffect, useRef, useState } from "react";
import SideBar from "../components/SideBar";
import styles from "./MasterData.module.css";
import { ClickEventArgs } from "@syncfusion/ej2-navigations";
import { Button } from "antd";
import "antd/dist/antd.css";
import { Modal } from "@material-ui/core";
import { getPIS, PISDataType } from "../api/CRUD/getPIS";
import { FiEdit, FiDelete } from "react-icons/fi";
import { PISEdit } from "../components/PISEdit";
import {
  ColumnDirective,
  ColumnsDirective,
  FilterSettingsModel,
  GridComponent,
  Toolbar,
  Inject,
  PdfExport,
  Grid,
  ToolbarItems,
  ExcelExport,
  Filter,
  Sort,
} from "@syncfusion/ej2-react-grids";
import moment from "moment";
import { PISCreate } from "../components/PISCreate";
import {useAppSelector, useAppDispatch} from "../redux/hooks";
import { GenerateAccessToken } from "../api/generateAccessToken";
import { logout, setAccessToken } from "../redux/slice/UserSlice";
import { Logout } from "../api/login/logout";

function MasterData() {
  const User = useAppSelector((state: any) => state.User);
  const dispatch = useAppDispatch();
  const filterOptions: FilterSettingsModel = {
    type: "Excel",
  };
  // @ts-ignore
  const gridRef = useRef<Grid | null>(null);
  const toolbar: ToolbarItems[] = [
    "ExcelExport",
    "Search",
  ];
  const toolbarClick = (args: ClickEventArgs | undefined) => {
    if (gridRef.current === null || !args) return;
    switch (args.item.text) {
      case "Excel Export":
        gridRef.current.excelExport();
        break;
      case "PDF Export":
        gridRef.current.pdfExport();
        break;
      case "CSV Export":
        gridRef.current.csvExport();
        break;
    }
  };


  const regenerateToken = async () => {
    const res = await GenerateAccessToken(User.refreshToken).catch((e) => {
      Logout(User.refreshToken);;
      dispatch(logout());
    });
    if (!!res?.token) {
      dispatch(
        setAccessToken({
          token: res?.token,
        })
      );
    }
  };

  const [PisData, setPisData] = useState<PISDataType[]>([]);

  const callgetPIS = async () => {
    const result = await getPIS(User?.token).catch((err) => {
      if (err.response.status === 401) {
        regenerateToken();
        callgetPIS();
      }
    });;
    result?.data?.data.map((item: any, index: any) => {
      setPisData((prevState: any) => [
        ...prevState.filter((i: any) => i.id !== item.id),
        {
          ...item,
          sno: index + 1,
          createdAt: moment(item.createdAt).format("DD-MM-YYYY HH:mm:ss a"),
          updatedAt: moment(item.updatedAt).format("DD-MM-YYYY HH:mm:ss a"),
        },
      ]);
    });
  };

  useEffect(() => {
    callgetPIS();
  }, []);

  const [ToggleEdit, setToggleEdit] = useState(false);
  const [ToggleCreate, setToggleCreate] = useState(false);

  const [Ob, setOb] = useState<any>();

  const editTemplate = (args: any) => {
    return (
      <FiEdit style={{ cursor: "pointer" }} onClick={() => editPIS(args)} />
    );
  };

  const editPIS = (args: any) => {
    setOb(args);
    setToggleEdit(true);
  };

  return (
    <div className={styles.Container}>
      <div className={styles.SideBar}>
        <SideBar />
      </div>
      <div className={styles.Body}>
        <h2>Passenger Information System Master Data</h2>
        <Button
          onClick={() => setToggleCreate(true)}
          style={{ marginLeft: "10px" }}
          type="dashed"
        >
          Add New +
        </Button>
        <Modal
          open={ToggleEdit}
          // onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className="flex flex-row justify-around items-center"
        >
          <PISEdit
            setToggleModal={setToggleEdit}
            data={Ob}
            callgetPIS={callgetPIS}
          />
        </Modal>
        <Modal
          open={ToggleCreate}
          // onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className="flex flex-row justify-around items-center"
        >
          <PISCreate setToggleModal={setToggleCreate} callgetPIS={callgetPIS} />
        </Modal>
        <div className={styles.Table}>
          <GridComponent
            toolbarClick={toolbarClick}
            ref={(g) => (gridRef.current = g)}
            filterSettings={filterOptions}
            dataSource={PisData}
            toolbar={toolbar}
            allowFiltering
            allowSorting
            allowPdfExport
            allowExcelExport
            style={{
              margin: 10,
            }}
            height={450}
          >
            <ColumnsDirective>
              <ColumnDirective width="120" field="sno" headerText="S. no." />
              <ColumnDirective width="200" field="name" headerText="Bus Stop" />
              <ColumnDirective width="200" field="lat" headerText="Latitude" />
              <ColumnDirective
                width="200"
                field="long"
                headerText="Longitude"
              />
              <ColumnDirective
                width="200"
                field="dis_lat"
                headerText="Dis Latitude"
              />
              <ColumnDirective
                width="200"
                field="dis_long"
                headerText="Dis Longitude"
              />
              <ColumnDirective
                width="200"
                field="dev_id_1"
                headerText="Device Id 1"
              />
              <ColumnDirective
                width="200"
                field="dev_id_2"
                headerText="Device Id 2"
              />
              <ColumnDirective
                width="200"
                field="dis_direction"
                headerText="Dis Direction"
              />
              <ColumnDirective
                width="200"
                field="createdAt"
                headerText="Created At"
              />
              <ColumnDirective
                width="200"
                field="updatedAt"
                headerText="UpdatedAt"
              />
              <ColumnDirective
                width="200"
                field="remarks"
                headerText="Remarks"
              />
              <ColumnDirective
                headerText="Edit"
                textAlign="Left"
                width="120"
                template={editTemplate}
              />
            </ColumnsDirective>
            <Inject
              services={[Toolbar, ExcelExport, PdfExport, Filter, Sort]}
            />
          </GridComponent>
        </div>
      </div>
    </div>
  );
}

export default MasterData;
