import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Connector } from "mqtt-react-hooks";
import { store, persistor } from "./redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

ReactDOM.render(
  <Connector
    // brokerUrl="wss://pis.distronix.in:8083"
    brokerUrl="wss://pis.distronix.in:5083"
    options={{
      username: "distronix",
      password: "D@1357902468",
    }}
  >
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </Connector>,
  document.getElementById("root")
);
