import React, { useState, useRef } from 'react'
import { Mock } from "./tram_frequency";
import masterStyles from "../MasterData.module.css";
import { ClickEventArgs } from "@syncfusion/ej2-navigations";
import {
  ColumnDirective,
  ColumnsDirective,
  FilterSettingsModel,
  GridComponent,
  Toolbar,
  Inject,
  PdfExport,
  Grid,
  ToolbarItems,
  ExcelExport,
  Filter,
  Sort,
} from "@syncfusion/ej2-react-grids";
import Select from "react-select";
import SideBar from '../../components/SideBar';

const BusOfflineReport = () => {
  const [selectedInitial, setSelectedInitial] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const [selectedFinal, setSelectedFinal] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const filterOptions: FilterSettingsModel = {
    type: "Excel",
  };
  // @ts-ignore
  const gridRef = useRef<Grid | null>(null);
  const toolbar: ToolbarItems[] = [
    "ExcelExport",
    "PdfExport",
    "CsvExport",
    "Search",
    "Print",
  ];
  const toolbarClick = (args: ClickEventArgs | undefined) => {
    if (gridRef.current === null || !args) return;
    switch (args.item.text) {
      case "Excel Export":
        gridRef.current.excelExport();
        break;
      case "PDF Export":
        gridRef.current.pdfExport();
        break;
      case "CSV Export":
        gridRef.current.csvExport();
        break;
    }
  };
  const initialRoutes = Mock.map((item) => item.route)
    .filter((item, i, ar) => ar.indexOf(item) === i)
    .map((i) => ({ label: i, value: i.toLowerCase() }));
  return (
    <div className={masterStyles.Container}>
      <div className={masterStyles.SideBar}>
        <SideBar />
      </div>
      <div className={masterStyles.Body}>
        <h2 className="text-2xl mb-4">Tram Frequency Report</h2>
        <div className="flex flex-row justify-start items-center gap-4 px-4">
          <div className="flex flex-row justify-start items-center gap-2">
            <div className="">Initial Route</div>
            <div>
              <Select
                options={initialRoutes}
                value={selectedInitial}
                onChange={setSelectedInitial}
                // labelledBy="Select Initial Route"
                className="w-60"
              />
            </div>
          </div>
          <div className="flex flex-row justify-start items-center gap-2">
            <div>Final Route</div>
            <div>
              <Select
                options={initialRoutes}
                value={selectedFinal}
                onChange={setSelectedFinal}
                // labelledBy="Select Final Route"
                className="w-60"
              />
            </div>
          </div>
          <div className="p-2 w-24 text-center rounded-md shadow-lg cursor-pointer bg-blue-400 text-white">
            Go
          </div>
        </div>
        {selectedFinal !== null && selectedInitial !== null ? (
          <div className={masterStyles.Table}>
            <GridComponent
              toolbarClick={toolbarClick}
              ref={(g) => (gridRef.current = g)}
              filterSettings={filterOptions}
              dataSource={Mock}
              toolbar={toolbar}
              allowFiltering
              allowSorting
              allowPdfExport
              allowExcelExport
              style={{
                margin: 10,
              }}
              height={"70vh"}
            >
              <ColumnsDirective>
                <ColumnDirective width="200" field="id" headerText="Sl no." />
                <ColumnDirective width="200" field="route" headerText="Route" />
                <ColumnDirective
                  width="200"
                  field="frequency_initial"
                  headerText={`Frequency ${selectedInitial?.label}`}
                />
                <ColumnDirective
                  width="200"
                  field="frequency_final"
                  headerText={`Frequency ${selectedFinal?.label}`}
                />
              </ColumnsDirective>
              <Inject
                services={[Toolbar, ExcelExport, PdfExport, Filter, Sort]}
              />
            </GridComponent>
          </div>
        ) : (
          <div className="text-center mt-10">
            Please select the options to show the appropriate data.
          </div>
        )}
      </div>
    </div>
  );
}

export default BusOfflineReport