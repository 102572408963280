import React, { useState } from "react";
import styles from "./Login.module.css";
import GoogleMapReact from "google-map-react";
import { Link, Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRssSquare } from "@fortawesome/free-solid-svg-icons";
import { Markers } from "../pages/mockdata";
import MAPS_API_KEY from "../APIKEY";

function ForgotPassword() {
  const [userId, setUserId] = useState("");
  const [toReset, setToReset] = useState(false);
  const sendOTP = () => {
    setToReset(true);
    var axios = require("axios");
    var data = JSON.stringify({
      userName: userId,
    });

    var config = {
      method: "post",
      url: "https://pis.distronix.in:5443/api/users/getOtp",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response: any) {
        console.log("OTP SENT SUCCESSFULLY!");
      })
      .catch(function (error: any) {
        console.log(error);
      });
  };

  const center = {
    lat: 22.59627,
    lng: 88.48757,
  };
  const zoom = 14;
  const MarkerPin = ({ stoppage }: any): any => (
    <div className={styles.MarkerPin}>
      <FontAwesomeIcon icon={faRssSquare} size="2x" color="#E84335" />
    </div>
  );
  if (toReset)
    return <Redirect to={{ pathname: "/guest/reset", state: { userId } }} />;
  return (
    <div className="styles.Container">
      <div className={styles.FormContainer}>
        <div className={styles.Form}>
          <p className="text-white">
            Check your email for a code to <br />
            reset your password
          </p>
          <input
            type="text"
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
            placeholder="ENTER USER ID"
          />
          <input type="submit" value="GET CODE" onClick={sendOTP} />
          <Link to="/" className={styles.ForgotPass}>
            Go back
          </Link>
        </div>
      </div>
      <div className={styles.Maps}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: MAPS_API_KEY }}
          defaultCenter={center}
          defaultZoom={zoom}
          options={(maps) => ({
            backgroundColor: "##f7f7f7",
            fullscreenControl: false,
            mapTypeControl: false,
            zoomControl: false,
          })}
        >
          {Markers.tag.map((item: any, index: number) => (
            <MarkerPin stoppage={item.stoppage} lat={item.lat} lng={item.lng} />
          ))}
        </GoogleMapReact>
      </div>
    </div>
  );
}

export default ForgotPassword;
