import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "./App.css";
import { GuestLayout, UserLayout } from "./layout";
import { useAppSelector } from "./redux/hooks";
import { SnackbarProvider } from "notistack";

function App() {
  const flag = useAppSelector((state: any) => state.User?.loggedIn);
  return (
    <Router>
      <SnackbarProvider maxSnack={3}>
        <Switch>
          <Route exact path="/">
            {flag ? (
              <Redirect to="/user/map" />
            ) : (
              <Redirect to="/guest/signin" />
            )}
          </Route>
          <Route path="/guest" render={() => <GuestLayout />} />
          <Route path="/user" render={() => <UserLayout />} />
        </Switch>
      </SnackbarProvider>
    </Router>
  );
}

export default App;
