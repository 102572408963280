import axios, { AxiosRequestConfig } from "axios";

export const Login = async (userName: string, password: string) => {
  let data = { userName: userName, password: password };

  let config: AxiosRequestConfig = {
    method: "post",
    url: "https://pis.distronix.in:5443/api/users/login",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  const result = await axios(config)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      return e;
    });
  return result;
};
