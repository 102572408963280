import { FunctionComponent } from "react";

import Login from "../../pages/Login";
import ForgotPassword from "../../pages/ForgotPassword";
import Reset from "../../pages/Reset";

interface GuestRoute {
  Component: FunctionComponent;
  path: string;
  title: string;
}

export const GuestRoutes: GuestRoute[] = [
  {
    Component: Login,
    path: "/signin",
    title: "Sign In",
  },
  {
    Component: ForgotPassword,
    path: "/forgot-password",
    title: "Forgot Password",
  },
  {
    Component: Reset,
    path: "/reset",
    title: "Reset Password",
  },
];
