interface TramFrequencyItem {
  id: number;
  route: string;
  frequency_initial: string;
  frequency_final: string;
}

export const Mock = [
  {
    id: 1,
    route: "ASND",
    frequency_initial: "6:04 PM",
    frequency_final: "12:53 PM",
  },
  {
    id: 2,
    route: "GHM",
    frequency_initial: "6:32 AM",
    frequency_final: "11:34 AM",
  },
  {
    id: 3,
    route: "SNH",
    frequency_initial: "9:02 AM",
    frequency_final: "10:24 PM",
  },
  {
    id: 4,
    route: "HAIN",
    frequency_initial: "1:55 AM",
    frequency_final: "11:48 AM",
  },
  {
    id: 5,
    route: "EVG",
    frequency_initial: "8:29 PM",
    frequency_final: "9:41 AM",
  },
  {
    id: 6,
    route: "JSYN",
    frequency_initial: "4:07 PM",
    frequency_final: "8:59 PM",
  },
  {
    id: 7,
    route: "VRX",
    frequency_initial: "2:10 AM",
    frequency_final: "2:45 PM",
  },
  {
    id: 8,
    route: "HTF",
    frequency_initial: "2:22 PM",
    frequency_final: "7:15 AM",
  },
  {
    id: 9,
    route: "TWNK",
    frequency_initial: "5:01 PM",
    frequency_final: "10:19 AM",
  },
  {
    id: 10,
    route: "CCK",
    frequency_initial: "8:50 PM",
    frequency_final: "5:39 AM",
  },
  {
    id: 11,
    route: "BDJ",
    frequency_initial: "1:03 PM",
    frequency_final: "10:23 PM",
  },
  {
    id: 12,
    route: "SCON",
    frequency_initial: "10:17 PM",
    frequency_final: "4:08 AM",
  },
  {
    id: 13,
    route: "KFRC",
    frequency_initial: "7:58 PM",
    frequency_final: "6:31 PM",
  },
  {
    id: 14,
    route: "MPACU",
    frequency_initial: "10:43 PM",
    frequency_final: "10:14 PM",
  },
  {
    id: 15,
    route: "MMLP",
    frequency_initial: "12:33 AM",
    frequency_final: "5:15 AM",
  },
  {
    id: 16,
    route: "OMED",
    frequency_initial: "5:10 AM",
    frequency_final: "10:11 PM",
  },
  {
    id: 17,
    route: "CDEV",
    frequency_initial: "10:56 AM",
    frequency_final: "11:42 AM",
  },
  {
    id: 18,
    route: "BX",
    frequency_initial: "5:15 PM",
    frequency_final: "3:56 PM",
  },
  {
    id: 19,
    route: "CUB",
    frequency_initial: "7:34 PM",
    frequency_final: "4:05 AM",
  },
  {
    id: 20,
    route: "LPCN",
    frequency_initial: "1:27 AM",
    frequency_final: "8:39 PM",
  },
  {
    id: 21,
    route: "ESGG",
    frequency_initial: "11:40 PM",
    frequency_final: "11:54 AM",
  },
  {
    id: 22,
    route: "PLYA",
    frequency_initial: "9:42 PM",
    frequency_final: "1:35 PM",
  },
  {
    id: 23,
    route: "FINL",
    frequency_initial: "12:12 AM",
    frequency_final: "2:52 AM",
  },
  {
    id: 24,
    route: "VER",
    frequency_initial: "10:25 AM",
    frequency_final: "9:03 PM",
  },
  {
    id: 25,
    route: "SNSS",
    frequency_initial: "1:51 PM",
    frequency_final: "4:37 AM",
  },
  {
    id: 26,
    route: "NXN",
    frequency_initial: "6:52 PM",
    frequency_final: "7:26 AM",
  },
  {
    id: 27,
    route: "MBRX",
    frequency_initial: "5:27 PM",
    frequency_final: "5:31 AM",
  },
  {
    id: 28,
    route: "CCRC",
    frequency_initial: "11:02 AM",
    frequency_final: "2:33 PM",
  },
  {
    id: 29,
    route: "SKM",
    frequency_initial: "10:38 PM",
    frequency_final: "4:52 PM",
  },
  {
    id: 30,
    route: "AMT^B",
    frequency_initial: "11:43 AM",
    frequency_final: "3:49 AM",
  },
  {
    id: 31,
    route: "VALE",
    frequency_initial: "9:43 AM",
    frequency_final: "3:35 AM",
  },
  {
    id: 32,
    route: "JPM^H",
    frequency_initial: "4:44 PM",
    frequency_final: "7:46 AM",
  },
  {
    id: 33,
    route: "MDLQ",
    frequency_initial: "8:29 AM",
    frequency_final: "7:57 PM",
  },
  {
    id: 34,
    route: "FMSA",
    frequency_initial: "5:16 PM",
    frequency_final: "11:30 AM",
  },
  {
    id: 35,
    route: "KTH",
    frequency_initial: "10:52 PM",
    frequency_final: "5:02 PM",
  },
  {
    id: 36,
    route: "NRIM",
    frequency_initial: "5:21 AM",
    frequency_final: "5:43 AM",
  },
  {
    id: 37,
    route: "CLW",
    frequency_initial: "12:30 AM",
    frequency_final: "2:42 AM",
  },
  {
    id: 38,
    route: "LSXMA",
    frequency_initial: "2:57 AM",
    frequency_final: "1:25 PM",
  },
  {
    id: 39,
    route: "ISM",
    frequency_initial: "1:43 AM",
    frequency_final: "7:11 AM",
  },
  {
    id: 40,
    route: "PSA^E",
    frequency_initial: "1:36 AM",
    frequency_final: "6:39 PM",
  },
  {
    id: 41,
    route: "RSO^B",
    frequency_initial: "8:47 PM",
    frequency_final: "3:10 AM",
  },
  {
    id: 42,
    route: "AGO^B",
    frequency_initial: "4:39 AM",
    frequency_final: "11:25 PM",
  },
  {
    id: 43,
    route: "ABEOW",
    frequency_initial: "12:46 PM",
    frequency_final: "7:21 AM",
  },
  {
    id: 44,
    route: "ODP",
    frequency_initial: "8:26 PM",
    frequency_final: "4:29 AM",
  },
  {
    id: 45,
    route: "MOS",
    frequency_initial: "11:53 PM",
    frequency_final: "5:32 PM",
  },
  {
    id: 46,
    route: "PZN",
    frequency_initial: "6:41 PM",
    frequency_final: "2:02 AM",
  },
  {
    id: 47,
    route: "HSII",
    frequency_initial: "1:18 PM",
    frequency_final: "5:59 AM",
  },
  {
    id: 48,
    route: "NGVC",
    frequency_initial: "6:24 AM",
    frequency_final: "5:00 PM",
  },
  {
    id: 49,
    route: "CEO",
    frequency_initial: "4:56 AM",
    frequency_final: "10:29 PM",
  },
  {
    id: 50,
    route: "TWTR",
    frequency_initial: "9:09 PM",
    frequency_final: "12:44 PM",
  },
  {
    id: 51,
    route: "WEC",
    frequency_initial: "7:26 PM",
    frequency_final: "6:24 PM",
  },
  {
    id: 52,
    route: "NMRX",
    frequency_initial: "6:04 AM",
    frequency_final: "2:01 PM",
  },
  {
    id: 53,
    route: "ACM",
    frequency_initial: "1:10 AM",
    frequency_final: "5:59 AM",
  },
  {
    id: 54,
    route: "ENTL",
    frequency_initial: "1:18 PM",
    frequency_final: "10:30 AM",
  },
  {
    id: 55,
    route: "ABR^C",
    frequency_initial: "5:00 PM",
    frequency_final: "1:10 AM",
  },
  {
    id: 56,
    route: "SNDR",
    frequency_initial: "10:29 AM",
    frequency_final: "7:23 PM",
  },
  {
    id: 57,
    route: "ETE",
    frequency_initial: "1:41 PM",
    frequency_final: "6:44 PM",
  },
  {
    id: 58,
    route: "CIM^A",
    frequency_initial: "8:26 AM",
    frequency_final: "2:44 AM",
  },
  {
    id: 59,
    route: "TRUE",
    frequency_initial: "12:48 AM",
    frequency_final: "4:49 AM",
  },
  {
    id: 60,
    route: "ROCK",
    frequency_initial: "12:43 PM",
    frequency_final: "6:53 AM",
  },
  {
    id: 61,
    route: "YIN",
    frequency_initial: "8:21 AM",
    frequency_final: "4:49 PM",
  },
  {
    id: 62,
    route: "JYNT",
    frequency_initial: "11:18 AM",
    frequency_final: "1:46 AM",
  },
  {
    id: 63,
    route: "ALL^B",
    frequency_initial: "11:36 PM",
    frequency_final: "10:54 PM",
  },
  {
    id: 64,
    route: "TGLS",
    frequency_initial: "4:34 AM",
    frequency_final: "5:48 AM",
  },
  {
    id: 65,
    route: "AVXL",
    frequency_initial: "5:05 AM",
    frequency_final: "6:30 PM",
  },
  {
    id: 66,
    route: "HOVNP",
    frequency_initial: "11:46 PM",
    frequency_final: "10:25 PM",
  },
  {
    id: 67,
    route: "MACK",
    frequency_initial: "7:49 AM",
    frequency_final: "5:04 PM",
  },
  {
    id: 68,
    route: "OOMA",
    frequency_initial: "4:15 PM",
    frequency_final: "2:23 PM",
  },
  {
    id: 69,
    route: "PEBK",
    frequency_initial: "4:32 AM",
    frequency_final: "11:02 AM",
  },
  {
    id: 70,
    route: "PDEX",
    frequency_initial: "12:17 PM",
    frequency_final: "6:39 PM",
  },
  {
    id: 71,
    route: "IFV",
    frequency_initial: "2:50 PM",
    frequency_final: "10:17 PM",
  },
  {
    id: 72,
    route: "KELYA",
    frequency_initial: "8:12 PM",
    frequency_final: "12:48 AM",
  },
  {
    id: 73,
    route: "TTF",
    frequency_initial: "10:12 AM",
    frequency_final: "7:07 PM",
  },
  {
    id: 74,
    route: "S",
    frequency_initial: "7:56 PM",
    frequency_final: "9:28 PM",
  },
  {
    id: 75,
    route: "BOOT",
    frequency_initial: "12:18 AM",
    frequency_final: "10:57 AM",
  },
  {
    id: 76,
    route: "WSBF",
    frequency_initial: "3:11 PM",
    frequency_final: "11:51 PM",
  },
  {
    id: 77,
    route: "WFC^L",
    frequency_initial: "3:55 AM",
    frequency_final: "8:43 AM",
  },
  {
    id: 78,
    route: "NNI",
    frequency_initial: "9:39 AM",
    frequency_final: "12:58 AM",
  },
  {
    id: 79,
    route: "MEDP",
    frequency_initial: "8:05 PM",
    frequency_final: "8:46 PM",
  },
  {
    id: 80,
    route: "GRID",
    frequency_initial: "6:13 AM",
    frequency_final: "7:09 AM",
  },
  {
    id: 81,
    route: "IIF",
    frequency_initial: "4:17 AM",
    frequency_final: "3:27 AM",
  },
  {
    id: 82,
    route: "FPF",
    frequency_initial: "2:01 AM",
    frequency_final: "8:26 PM",
  },
  {
    id: 83,
    route: "BLDR",
    frequency_initial: "8:07 AM",
    frequency_final: "9:45 PM",
  },
  {
    id: 84,
    route: "AERI",
    frequency_initial: "3:58 PM",
    frequency_final: "3:03 PM",
  },
  {
    id: 85,
    route: "PGJ",
    frequency_initial: "11:31 PM",
    frequency_final: "8:52 AM",
  },
  {
    id: 86,
    route: "JPM.WS",
    frequency_initial: "7:24 AM",
    frequency_final: "1:36 PM",
  },
  {
    id: 87,
    route: "ENB",
    frequency_initial: "2:32 PM",
    frequency_final: "7:36 PM",
  },
  {
    id: 88,
    route: "GKOS",
    frequency_initial: "2:11 AM",
    frequency_final: "1:59 AM",
  },
  {
    id: 89,
    route: "LKOR",
    frequency_initial: "10:53 AM",
    frequency_final: "1:36 PM",
  },
  {
    id: 90,
    route: "FKLYU",
    frequency_initial: "1:16 AM",
    frequency_final: "11:21 AM",
  },
  {
    id: 91,
    route: "LONE",
    frequency_initial: "3:34 PM",
    frequency_final: "11:58 AM",
  },
  {
    id: 92,
    route: "LVS",
    frequency_initial: "5:22 PM",
    frequency_final: "8:07 AM",
  },
  {
    id: 93,
    route: "HEP",
    frequency_initial: "10:42 AM",
    frequency_final: "10:46 PM",
  },
  {
    id: 94,
    route: "EVEP",
    frequency_initial: "4:19 AM",
    frequency_final: "9:54 PM",
  },
  {
    id: 95,
    route: "CXO",
    frequency_initial: "4:00 PM",
    frequency_final: "1:02 AM",
  },
  {
    id: 96,
    route: "JKI",
    frequency_initial: "4:46 PM",
    frequency_final: "12:12 AM",
  },
  {
    id: 97,
    route: "CDOR",
    frequency_initial: "8:27 PM",
    frequency_final: "5:48 AM",
  },
  {
    id: 98,
    route: "BSX",
    frequency_initial: "6:06 AM",
    frequency_final: "11:25 AM",
  },
  {
    id: 99,
    route: "NOAH",
    frequency_initial: "10:19 PM",
    frequency_final: "2:04 AM",
  },
  {
    id: 100,
    route: "DDT",
    frequency_initial: "2:25 AM",
    frequency_final: "5:57 PM",
  },
  {
    id: 101,
    route: "GMLP",
    frequency_initial: "10:00 PM",
    frequency_final: "10:23 AM",
  },
  {
    id: 102,
    route: "CIVI",
    frequency_initial: "10:01 PM",
    frequency_final: "6:49 PM",
  },
  {
    id: 103,
    route: "WRE",
    frequency_initial: "6:34 AM",
    frequency_final: "12:51 PM",
  },
  {
    id: 104,
    route: "SIVBO",
    frequency_initial: "10:06 PM",
    frequency_final: "12:01 PM",
  },
  {
    id: 105,
    route: "BRX",
    frequency_initial: "7:01 PM",
    frequency_final: "1:41 PM",
  },
  {
    id: 106,
    route: "AMRI",
    frequency_initial: "12:39 AM",
    frequency_final: "6:53 PM",
  },
  {
    id: 107,
    route: "CTIB",
    frequency_initial: "2:50 PM",
    frequency_final: "12:31 AM",
  },
  {
    id: 108,
    route: "SIEB",
    frequency_initial: "10:01 PM",
    frequency_final: "4:36 PM",
  },
  {
    id: 109,
    route: "WASH",
    frequency_initial: "2:19 AM",
    frequency_final: "5:08 PM",
  },
  {
    id: 110,
    route: "FEX",
    frequency_initial: "9:37 PM",
    frequency_final: "3:36 AM",
  },
  {
    id: 111,
    route: "AMOV",
    frequency_initial: "1:01 AM",
    frequency_final: "4:43 AM",
  },
  {
    id: 112,
    route: "DBVT",
    frequency_initial: "7:36 AM",
    frequency_final: "6:13 AM",
  },
  {
    id: 113,
    route: "PHM",
    frequency_initial: "1:17 PM",
    frequency_final: "3:23 AM",
  },
  {
    id: 114,
    route: "VTL",
    frequency_initial: "9:07 PM",
    frequency_final: "7:22 AM",
  },
  {
    id: 115,
    route: "ESGG",
    frequency_initial: "1:47 AM",
    frequency_final: "12:16 AM",
  },
  {
    id: 116,
    route: "CGEN",
    frequency_initial: "11:51 AM",
    frequency_final: "9:16 AM",
  },
  {
    id: 117,
    route: "EMIF",
    frequency_initial: "6:45 AM",
    frequency_final: "10:12 PM",
  },
  {
    id: 118,
    route: "NGHCN",
    frequency_initial: "10:44 PM",
    frequency_final: "3:47 AM",
  },
  {
    id: 119,
    route: "PKE",
    frequency_initial: "5:23 PM",
    frequency_final: "10:47 AM",
  },
  {
    id: 120,
    route: "EDIT",
    frequency_initial: "2:37 PM",
    frequency_final: "8:28 AM",
  },
  {
    id: 121,
    route: "GUT",
    frequency_initial: "1:15 PM",
    frequency_final: "12:40 AM",
  },
  {
    id: 122,
    route: "CRME",
    frequency_initial: "10:49 AM",
    frequency_final: "4:25 AM",
  },
  {
    id: 123,
    route: "LAKE",
    frequency_initial: "1:49 AM",
    frequency_final: "2:42 AM",
  },
  {
    id: 124,
    route: "KEM",
    frequency_initial: "2:20 AM",
    frequency_final: "12:17 AM",
  },
  {
    id: 125,
    route: "WTR",
    frequency_initial: "5:05 AM",
    frequency_final: "2:59 AM",
  },
  {
    id: 126,
    route: "CLNS^D",
    frequency_initial: "10:15 AM",
    frequency_final: "4:13 PM",
  },
  {
    id: 127,
    route: "COF",
    frequency_initial: "6:02 AM",
    frequency_final: "3:09 AM",
  },
  {
    id: 128,
    route: "HMHC",
    frequency_initial: "2:24 PM",
    frequency_final: "6:19 AM",
  },
  {
    id: 129,
    route: "TICC",
    frequency_initial: "5:33 AM",
    frequency_final: "7:27 AM",
  },
  {
    id: 130,
    route: "TDC",
    frequency_initial: "12:07 AM",
    frequency_final: "11:58 AM",
  },
  {
    id: 131,
    route: "ETX           ",
    frequency_initial: "12:24 PM",
    frequency_final: "5:11 PM",
  },
  {
    id: 132,
    route: "GIL",
    frequency_initial: "8:37 AM",
    frequency_final: "2:15 AM",
  },
  {
    id: 133,
    route: "DNP",
    frequency_initial: "1:06 AM",
    frequency_final: "12:48 AM",
  },
  {
    id: 134,
    route: "ULTA",
    frequency_initial: "5:50 AM",
    frequency_final: "6:54 AM",
  },
  {
    id: 135,
    route: "BANC^C",
    frequency_initial: "8:14 PM",
    frequency_final: "9:43 PM",
  },
  {
    id: 136,
    route: "TREC",
    frequency_initial: "2:02 AM",
    frequency_final: "10:59 AM",
  },
  {
    id: 137,
    route: "KVHI",
    frequency_initial: "12:26 PM",
    frequency_final: "2:32 AM",
  },
  {
    id: 138,
    route: "ETFC",
    frequency_initial: "11:10 PM",
    frequency_final: "1:40 AM",
  },
  {
    id: 139,
    route: "AKS",
    frequency_initial: "8:17 AM",
    frequency_final: "3:55 AM",
  },
  {
    id: 140,
    route: "YTEN",
    frequency_initial: "2:43 PM",
    frequency_final: "6:27 AM",
  },
  {
    id: 141,
    route: "SHG",
    frequency_initial: "12:20 AM",
    frequency_final: "4:50 AM",
  },
  {
    id: 142,
    route: "SVRA",
    frequency_initial: "11:00 PM",
    frequency_final: "2:13 AM",
  },
  {
    id: 143,
    route: "SPOK",
    frequency_initial: "7:48 PM",
    frequency_final: "7:40 PM",
  },
  {
    id: 144,
    route: "SSNI",
    frequency_initial: "1:01 PM",
    frequency_final: "2:34 AM",
  },
  {
    id: 145,
    route: "AON",
    frequency_initial: "3:27 PM",
    frequency_final: "1:28 AM",
  },
  {
    id: 146,
    route: "QUAD",
    frequency_initial: "6:06 AM",
    frequency_final: "4:49 AM",
  },
  {
    id: 147,
    route: "WINA",
    frequency_initial: "5:21 AM",
    frequency_final: "9:58 AM",
  },
  {
    id: 148,
    route: "BLCM",
    frequency_initial: "8:41 PM",
    frequency_final: "9:53 PM",
  },
  {
    id: 149,
    route: "RZB",
    frequency_initial: "6:29 PM",
    frequency_final: "1:20 PM",
  },
  {
    id: 150,
    route: "CHT",
    frequency_initial: "5:09 AM",
    frequency_final: "9:51 AM",
  },
  {
    id: 151,
    route: "OVBC",
    frequency_initial: "10:45 PM",
    frequency_final: "5:11 PM",
  },
  {
    id: 152,
    route: "FLS",
    frequency_initial: "8:20 PM",
    frequency_final: "9:48 PM",
  },
  {
    id: 153,
    route: "IRR",
    frequency_initial: "1:30 AM",
    frequency_final: "3:38 PM",
  },
  {
    id: 154,
    route: "AVX",
    frequency_initial: "8:47 PM",
    frequency_final: "7:05 AM",
  },
  {
    id: 155,
    route: "OXLCN",
    frequency_initial: "10:34 PM",
    frequency_final: "1:04 PM",
  },
  {
    id: 156,
    route: "AAME",
    frequency_initial: "10:11 PM",
    frequency_final: "12:12 AM",
  },
  {
    id: 157,
    route: "ECC           ",
    frequency_initial: "7:05 AM",
    frequency_final: "9:38 AM",
  },
  {
    id: 158,
    route: "HOME",
    frequency_initial: "6:46 PM",
    frequency_final: "5:44 AM",
  },
  {
    id: 159,
    route: "PYS",
    frequency_initial: "7:24 PM",
    frequency_final: "11:29 PM",
  },
  {
    id: 160,
    route: "PSCD",
    frequency_initial: "4:18 PM",
    frequency_final: "10:18 AM",
  },
  {
    id: 161,
    route: "HT^C",
    frequency_initial: "3:45 PM",
    frequency_final: "4:30 AM",
  },
  {
    id: 162,
    route: "NGVC",
    frequency_initial: "7:31 AM",
    frequency_final: "12:09 PM",
  },
  {
    id: 163,
    route: "SEIC",
    frequency_initial: "8:58 PM",
    frequency_final: "3:13 AM",
  },
  {
    id: 164,
    route: "SCZ",
    frequency_initial: "4:51 AM",
    frequency_final: "12:28 PM",
  },
  {
    id: 165,
    route: "SCAC",
    frequency_initial: "11:42 PM",
    frequency_final: "12:55 AM",
  },
  {
    id: 166,
    route: "QPACU",
    frequency_initial: "10:51 PM",
    frequency_final: "3:25 PM",
  },
  {
    id: 167,
    route: "VCRA",
    frequency_initial: "6:44 PM",
    frequency_final: "3:09 PM",
  },
  {
    id: 168,
    route: "GS^B",
    frequency_initial: "7:58 AM",
    frequency_final: "12:57 PM",
  },
  {
    id: 169,
    route: "VICL",
    frequency_initial: "2:38 PM",
    frequency_final: "4:14 PM",
  },
  {
    id: 170,
    route: "XELB",
    frequency_initial: "1:38 PM",
    frequency_final: "5:33 PM",
  },
  {
    id: 171,
    route: "WPT",
    frequency_initial: "2:40 PM",
    frequency_final: "4:17 AM",
  },
  {
    id: 172,
    route: "PCI",
    frequency_initial: "9:59 PM",
    frequency_final: "1:32 PM",
  },
  {
    id: 173,
    route: "HRI",
    frequency_initial: "9:35 AM",
    frequency_final: "12:48 AM",
  },
  {
    id: 174,
    route: "STAR          ",
    frequency_initial: "7:05 PM",
    frequency_final: "2:59 AM",
  },
  {
    id: 175,
    route: "PCO",
    frequency_initial: "8:43 PM",
    frequency_final: "8:47 PM",
  },
  {
    id: 176,
    route: "REXR",
    frequency_initial: "2:55 AM",
    frequency_final: "6:48 AM",
  },
  {
    id: 177,
    route: "FOR",
    frequency_initial: "3:11 AM",
    frequency_final: "5:44 AM",
  },
  {
    id: 178,
    route: "CSIQ",
    frequency_initial: "10:03 AM",
    frequency_final: "10:23 AM",
  },
  {
    id: 179,
    route: "MTEX",
    frequency_initial: "3:18 AM",
    frequency_final: "11:31 AM",
  },
  {
    id: 180,
    route: "HBAN",
    frequency_initial: "9:13 PM",
    frequency_final: "12:36 AM",
  },
  {
    id: 181,
    route: "FLOW",
    frequency_initial: "1:17 AM",
    frequency_final: "7:54 AM",
  },
  {
    id: 182,
    route: "HAFC",
    frequency_initial: "10:19 PM",
    frequency_final: "6:45 AM",
  },
  {
    id: 183,
    route: "DWAC",
    frequency_initial: "9:18 PM",
    frequency_final: "8:42 AM",
  },
  {
    id: 184,
    route: "IBCP",
    frequency_initial: "8:42 AM",
    frequency_final: "10:04 AM",
  },
  {
    id: 185,
    route: "IVR^A",
    frequency_initial: "7:55 AM",
    frequency_final: "10:48 PM",
  },
  {
    id: 186,
    route: "EFOI",
    frequency_initial: "2:23 AM",
    frequency_final: "12:19 AM",
  },
  {
    id: 187,
    route: "IEX",
    frequency_initial: "12:28 PM",
    frequency_final: "7:44 PM",
  },
  {
    id: 188,
    route: "EDR",
    frequency_initial: "6:04 PM",
    frequency_final: "6:57 PM",
  },
  {
    id: 189,
    route: "MGF",
    frequency_initial: "5:13 AM",
    frequency_final: "12:52 PM",
  },
  {
    id: 190,
    route: "GTT",
    frequency_initial: "7:18 PM",
    frequency_final: "5:32 AM",
  },
  {
    id: 191,
    route: "XRF",
    frequency_initial: "4:35 PM",
    frequency_final: "1:12 PM",
  },
  {
    id: 192,
    route: "RGSE",
    frequency_initial: "2:57 PM",
    frequency_final: "5:56 AM",
  },
  {
    id: 193,
    route: "IFGL",
    frequency_initial: "11:11 PM",
    frequency_final: "6:14 PM",
  },
  {
    id: 194,
    route: "CBB",
    frequency_initial: "6:30 PM",
    frequency_final: "10:13 PM",
  },
  {
    id: 195,
    route: "VBTX",
    frequency_initial: "2:51 PM",
    frequency_final: "2:24 PM",
  },
  {
    id: 196,
    route: "PRIM",
    frequency_initial: "4:30 PM",
    frequency_final: "6:09 PM",
  },
  {
    id: 197,
    route: "PLPM",
    frequency_initial: "3:34 PM",
    frequency_final: "1:19 AM",
  },
  {
    id: 198,
    route: "VTWO",
    frequency_initial: "8:53 PM",
    frequency_final: "5:24 PM",
  },
  {
    id: 199,
    route: "ICON",
    frequency_initial: "5:04 AM",
    frequency_final: "7:41 AM",
  },
  {
    id: 200,
    route: "ACV",
    frequency_initial: "6:42 PM",
    frequency_final: "3:14 PM",
  },
  {
    id: 201,
    route: "PBR",
    frequency_initial: "10:23 AM",
    frequency_final: "7:33 AM",
  },
  {
    id: 202,
    route: "FBNC",
    frequency_initial: "2:28 AM",
    frequency_final: "4:36 PM",
  },
  {
    id: 203,
    route: "CENTA",
    frequency_initial: "10:23 AM",
    frequency_final: "3:20 PM",
  },
  {
    id: 204,
    route: "LPCN",
    frequency_initial: "9:10 PM",
    frequency_final: "4:43 AM",
  },
  {
    id: 205,
    route: "EGIF",
    frequency_initial: "9:14 PM",
    frequency_final: "11:46 PM",
  },
  {
    id: 206,
    route: "TIME",
    frequency_initial: "1:33 PM",
    frequency_final: "9:09 AM",
  },
  {
    id: 207,
    route: "INCY",
    frequency_initial: "10:23 PM",
    frequency_final: "11:42 PM",
  },
  {
    id: 208,
    route: "LDL",
    frequency_initial: "9:17 AM",
    frequency_final: "10:10 AM",
  },
  {
    id: 209,
    route: "AVT",
    frequency_initial: "4:38 AM",
    frequency_final: "2:35 PM",
  },
  {
    id: 210,
    route: "PSET",
    frequency_initial: "10:51 PM",
    frequency_final: "5:33 AM",
  },
  {
    id: 211,
    route: "CMA",
    frequency_initial: "10:13 PM",
    frequency_final: "6:05 PM",
  },
  {
    id: 212,
    route: "MQY",
    frequency_initial: "5:05 AM",
    frequency_final: "12:58 PM",
  },
  {
    id: 213,
    route: "HESM",
    frequency_initial: "10:25 AM",
    frequency_final: "9:01 PM",
  },
  {
    id: 214,
    route: "MTH",
    frequency_initial: "2:58 AM",
    frequency_final: "7:06 AM",
  },
  {
    id: 215,
    route: "MER^K",
    frequency_initial: "1:11 PM",
    frequency_final: "3:42 AM",
  },
  {
    id: 216,
    route: "GPACU",
    frequency_initial: "12:48 AM",
    frequency_final: "4:12 AM",
  },
  {
    id: 217,
    route: "EBSB",
    frequency_initial: "5:43 PM",
    frequency_final: "1:13 PM",
  },
  {
    id: 218,
    route: "GBDC",
    frequency_initial: "6:13 PM",
    frequency_final: "3:32 PM",
  },
  {
    id: 219,
    route: "TUTT",
    frequency_initial: "10:43 AM",
    frequency_final: "11:47 PM",
  },
  {
    id: 220,
    route: "AGX",
    frequency_initial: "3:44 PM",
    frequency_final: "11:44 AM",
  },
  {
    id: 221,
    route: "NMFC",
    frequency_initial: "5:33 PM",
    frequency_final: "3:20 AM",
  },
  {
    id: 222,
    route: "CDZI",
    frequency_initial: "2:57 PM",
    frequency_final: "8:18 PM",
  },
  {
    id: 223,
    route: "TUES",
    frequency_initial: "9:46 AM",
    frequency_final: "1:01 AM",
  },
  {
    id: 224,
    route: "LQ",
    frequency_initial: "6:21 AM",
    frequency_final: "4:05 PM",
  },
  {
    id: 225,
    route: "LADR",
    frequency_initial: "5:16 AM",
    frequency_final: "9:35 AM",
  },
  {
    id: 226,
    route: "BID",
    frequency_initial: "11:48 PM",
    frequency_final: "5:45 PM",
  },
  {
    id: 227,
    route: "PODD",
    frequency_initial: "9:38 AM",
    frequency_final: "5:31 PM",
  },
  {
    id: 228,
    route: "ARGS",
    frequency_initial: "8:06 AM",
    frequency_final: "12:12 AM",
  },
  {
    id: 229,
    route: "TS",
    frequency_initial: "6:24 AM",
    frequency_final: "12:42 PM",
  },
  {
    id: 230,
    route: "CEA",
    frequency_initial: "3:37 PM",
    frequency_final: "4:44 PM",
  },
  {
    id: 231,
    route: "LPTH",
    frequency_initial: "9:24 AM",
    frequency_final: "9:10 AM",
  },
  {
    id: 232,
    route: "CHE",
    frequency_initial: "12:33 PM",
    frequency_final: "6:37 PM",
  },
  {
    id: 233,
    route: "DPZ",
    frequency_initial: "5:23 PM",
    frequency_final: "11:39 PM",
  },
  {
    id: 234,
    route: "OPHC",
    frequency_initial: "11:48 AM",
    frequency_final: "9:35 PM",
  },
  {
    id: 235,
    route: "VIDI",
    frequency_initial: "3:07 AM",
    frequency_final: "11:58 AM",
  },
  {
    id: 236,
    route: "FSFG",
    frequency_initial: "10:05 PM",
    frequency_final: "6:33 AM",
  },
  {
    id: 237,
    route: "SCE^G",
    frequency_initial: "9:12 PM",
    frequency_final: "3:46 AM",
  },
  {
    id: 238,
    route: "HXL",
    frequency_initial: "10:22 AM",
    frequency_final: "11:27 AM",
  },
  {
    id: 239,
    route: "VIA",
    frequency_initial: "10:41 AM",
    frequency_final: "9:48 PM",
  },
  {
    id: 240,
    route: "SIR",
    frequency_initial: "12:52 AM",
    frequency_final: "11:38 AM",
  },
  {
    id: 241,
    route: "SIEB",
    frequency_initial: "11:21 PM",
    frequency_final: "4:46 AM",
  },
  {
    id: 242,
    route: "LVS",
    frequency_initial: "2:29 AM",
    frequency_final: "1:23 AM",
  },
  {
    id: 243,
    route: "LLY",
    frequency_initial: "4:22 AM",
    frequency_final: "3:59 PM",
  },
  {
    id: 244,
    route: "FC",
    frequency_initial: "3:10 AM",
    frequency_final: "5:24 PM",
  },
  {
    id: 245,
    route: "OFG^D",
    frequency_initial: "11:13 PM",
    frequency_final: "4:18 PM",
  },
  {
    id: 246,
    route: "HFC",
    frequency_initial: "5:05 PM",
    frequency_final: "3:11 AM",
  },
  {
    id: 247,
    route: "PTGX",
    frequency_initial: "6:16 PM",
    frequency_final: "7:05 PM",
  },
  {
    id: 248,
    route: "DGX",
    frequency_initial: "4:45 AM",
    frequency_final: "2:24 AM",
  },
  {
    id: 249,
    route: "VBLT",
    frequency_initial: "4:20 PM",
    frequency_final: "10:45 PM",
  },
  {
    id: 250,
    route: "TAP",
    frequency_initial: "3:19 AM",
    frequency_final: "2:28 AM",
  },
  {
    id: 251,
    route: "MYC",
    frequency_initial: "11:03 PM",
    frequency_final: "5:13 PM",
  },
  {
    id: 252,
    route: "HBANO",
    frequency_initial: "4:28 PM",
    frequency_final: "4:37 PM",
  },
  {
    id: 253,
    route: "TGTX",
    frequency_initial: "7:26 AM",
    frequency_final: "5:19 AM",
  },
  {
    id: 254,
    route: "VRX",
    frequency_initial: "9:26 PM",
    frequency_final: "6:12 AM",
  },
  {
    id: 255,
    route: "CTZ",
    frequency_initial: "9:55 PM",
    frequency_final: "12:28 PM",
  },
  {
    id: 256,
    route: "FCA",
    frequency_initial: "1:12 PM",
    frequency_final: "7:57 PM",
  },
  {
    id: 257,
    route: "GAB^D",
    frequency_initial: "6:16 AM",
    frequency_final: "4:42 AM",
  },
  {
    id: 258,
    route: "SGBK",
    frequency_initial: "9:20 AM",
    frequency_final: "7:49 AM",
  },
  {
    id: 259,
    route: "LBIO",
    frequency_initial: "2:34 AM",
    frequency_final: "9:06 AM",
  },
  {
    id: 260,
    route: "VSTO",
    frequency_initial: "2:38 AM",
    frequency_final: "7:34 AM",
  },
  {
    id: 261,
    route: "DSE",
    frequency_initial: "3:19 AM",
    frequency_final: "1:47 PM",
  },
  {
    id: 262,
    route: "NNN^F",
    frequency_initial: "1:06 PM",
    frequency_final: "5:13 AM",
  },
  {
    id: 263,
    route: "ECOL",
    frequency_initial: "6:29 PM",
    frequency_final: "2:26 PM",
  },
  {
    id: 264,
    route: "ESND",
    frequency_initial: "1:38 PM",
    frequency_final: "3:15 AM",
  },
  {
    id: 265,
    route: "AGO",
    frequency_initial: "4:17 PM",
    frequency_final: "3:17 PM",
  },
  {
    id: 266,
    route: "JSYNR",
    frequency_initial: "3:38 PM",
    frequency_final: "10:30 PM",
  },
  {
    id: 267,
    route: "RAND",
    frequency_initial: "5:14 AM",
    frequency_final: "9:07 AM",
  },
  {
    id: 268,
    route: "CLBS",
    frequency_initial: "4:23 AM",
    frequency_final: "10:26 PM",
  },
  {
    id: 269,
    route: "GPC",
    frequency_initial: "1:53 PM",
    frequency_final: "4:19 AM",
  },
  {
    id: 270,
    route: "SOJB",
    frequency_initial: "5:44 PM",
    frequency_final: "4:45 PM",
  },
  {
    id: 271,
    route: "EZT",
    frequency_initial: "8:42 PM",
    frequency_final: "11:49 PM",
  },
  {
    id: 272,
    route: "BFO",
    frequency_initial: "2:06 PM",
    frequency_final: "4:33 AM",
  },
  {
    id: 273,
    route: "MDC",
    frequency_initial: "4:12 AM",
    frequency_final: "9:58 PM",
  },
  {
    id: 274,
    route: "FBHS",
    frequency_initial: "9:31 PM",
    frequency_final: "9:57 PM",
  },
  {
    id: 275,
    route: "CHSCL",
    frequency_initial: "1:45 PM",
    frequency_final: "11:22 AM",
  },
  {
    id: 276,
    route: "HYB",
    frequency_initial: "12:08 PM",
    frequency_final: "10:53 AM",
  },
  {
    id: 277,
    route: "RDN",
    frequency_initial: "5:51 AM",
    frequency_final: "6:51 PM",
  },
  {
    id: 278,
    route: "NVG",
    frequency_initial: "10:20 AM",
    frequency_final: "8:48 PM",
  },
  {
    id: 279,
    route: "COH",
    frequency_initial: "4:32 AM",
    frequency_final: "3:28 PM",
  },
  {
    id: 280,
    route: "ICD",
    frequency_initial: "7:40 PM",
    frequency_final: "7:25 PM",
  },
  {
    id: 281,
    route: "SHEN",
    frequency_initial: "2:36 PM",
    frequency_final: "11:16 AM",
  },
  {
    id: 282,
    route: "POT",
    frequency_initial: "11:55 PM",
    frequency_final: "5:52 AM",
  },
  {
    id: 283,
    route: "BMY",
    frequency_initial: "5:31 PM",
    frequency_final: "5:39 PM",
  },
  {
    id: 284,
    route: "PNM",
    frequency_initial: "6:06 PM",
    frequency_final: "8:30 PM",
  },
  {
    id: 285,
    route: "OVLY",
    frequency_initial: "2:40 AM",
    frequency_final: "11:05 AM",
  },
  {
    id: 286,
    route: "SCKT",
    frequency_initial: "1:49 PM",
    frequency_final: "4:52 AM",
  },
  {
    id: 287,
    route: "CBK",
    frequency_initial: "11:12 AM",
    frequency_final: "2:22 PM",
  },
  {
    id: 288,
    route: "HONE",
    frequency_initial: "3:06 AM",
    frequency_final: "11:09 PM",
  },
  {
    id: 289,
    route: "MED",
    frequency_initial: "7:00 PM",
    frequency_final: "3:58 PM",
  },
  {
    id: 290,
    route: "NYT",
    frequency_initial: "8:00 AM",
    frequency_final: "11:04 AM",
  },
  {
    id: 291,
    route: "PETX",
    frequency_initial: "11:42 PM",
    frequency_final: "6:55 PM",
  },
  {
    id: 292,
    route: "BRK.B",
    frequency_initial: "9:21 AM",
    frequency_final: "3:31 PM",
  },
  {
    id: 293,
    route: "MTR",
    frequency_initial: "3:39 AM",
    frequency_final: "6:30 PM",
  },
  {
    id: 294,
    route: "CARV",
    frequency_initial: "12:43 AM",
    frequency_final: "10:46 PM",
  },
  {
    id: 295,
    route: "FULT",
    frequency_initial: "10:46 AM",
    frequency_final: "2:50 AM",
  },
  {
    id: 296,
    route: "ULBI",
    frequency_initial: "9:46 AM",
    frequency_final: "11:40 AM",
  },
  {
    id: 297,
    route: "YUM",
    frequency_initial: "11:17 PM",
    frequency_final: "10:27 PM",
  },
  {
    id: 298,
    route: "FNBG",
    frequency_initial: "9:30 AM",
    frequency_final: "3:29 AM",
  },
  {
    id: 299,
    route: "KFFB",
    frequency_initial: "6:04 AM",
    frequency_final: "2:28 AM",
  },
  {
    id: 300,
    route: "AFL",
    frequency_initial: "11:10 AM",
    frequency_final: "3:45 PM",
  },
  {
    id: 301,
    route: "SMTX",
    frequency_initial: "3:05 PM",
    frequency_final: "10:27 PM",
  },
  {
    id: 302,
    route: "AMZN",
    frequency_initial: "11:05 PM",
    frequency_final: "2:48 AM",
  },
  {
    id: 303,
    route: "MTL",
    frequency_initial: "6:58 AM",
    frequency_final: "8:14 PM",
  },
  {
    id: 304,
    route: "WEB",
    frequency_initial: "8:43 AM",
    frequency_final: "4:19 PM",
  },
  {
    id: 305,
    route: "AFC",
    frequency_initial: "6:07 AM",
    frequency_final: "12:11 AM",
  },
  {
    id: 306,
    route: "R",
    frequency_initial: "7:46 PM",
    frequency_final: "8:52 AM",
  },
  {
    id: 307,
    route: "KMM",
    frequency_initial: "12:23 PM",
    frequency_final: "9:10 PM",
  },
  {
    id: 308,
    route: "SSNT",
    frequency_initial: "1:39 AM",
    frequency_final: "3:42 AM",
  },
  {
    id: 309,
    route: "SSFN",
    frequency_initial: "4:44 AM",
    frequency_final: "11:32 AM",
  },
  {
    id: 310,
    route: "BWG",
    frequency_initial: "5:29 AM",
    frequency_final: "12:08 PM",
  },
  {
    id: 311,
    route: "RDI",
    frequency_initial: "11:56 PM",
    frequency_final: "5:40 PM",
  },
  {
    id: 312,
    route: "BJZ",
    frequency_initial: "11:25 AM",
    frequency_final: "8:21 PM",
  },
  {
    id: 313,
    route: "SRCE",
    frequency_initial: "12:00 AM",
    frequency_final: "7:21 PM",
  },
  {
    id: 314,
    route: "NORD",
    frequency_initial: "2:38 PM",
    frequency_final: "9:59 PM",
  },
  {
    id: 315,
    route: "DNR",
    frequency_initial: "8:31 PM",
    frequency_final: "3:26 AM",
  },
  {
    id: 316,
    route: "PAGP",
    frequency_initial: "5:59 PM",
    frequency_final: "12:38 PM",
  },
  {
    id: 317,
    route: "TMK",
    frequency_initial: "6:13 PM",
    frequency_final: "6:09 AM",
  },
  {
    id: 318,
    route: "GCH",
    frequency_initial: "5:59 PM",
    frequency_final: "5:21 AM",
  },
  {
    id: 319,
    route: "ALEX",
    frequency_initial: "1:52 PM",
    frequency_final: "1:42 AM",
  },
  {
    id: 320,
    route: "ETN",
    frequency_initial: "4:50 AM",
    frequency_final: "6:45 AM",
  },
  {
    id: 321,
    route: "SYMC",
    frequency_initial: "1:37 AM",
    frequency_final: "1:08 AM",
  },
  {
    id: 322,
    route: "MCRI",
    frequency_initial: "5:30 PM",
    frequency_final: "1:15 AM",
  },
  {
    id: 323,
    route: "HCI",
    frequency_initial: "9:07 AM",
    frequency_final: "8:59 AM",
  },
  {
    id: 324,
    route: "PHII",
    frequency_initial: "4:08 PM",
    frequency_final: "1:57 AM",
  },
  {
    id: 325,
    route: "NUO",
    frequency_initial: "8:55 PM",
    frequency_final: "5:55 PM",
  },
  {
    id: 326,
    route: "COGT",
    frequency_initial: "7:23 PM",
    frequency_final: "3:46 AM",
  },
  {
    id: 327,
    route: "TMUS",
    frequency_initial: "7:45 AM",
    frequency_final: "11:00 PM",
  },
  {
    id: 328,
    route: "PRE^G",
    frequency_initial: "4:30 AM",
    frequency_final: "7:24 PM",
  },
  {
    id: 329,
    route: "MSLI",
    frequency_initial: "8:40 AM",
    frequency_final: "6:23 PM",
  },
  {
    id: 330,
    route: "GRMN",
    frequency_initial: "8:33 AM",
    frequency_final: "4:26 PM",
  },
  {
    id: 331,
    route: "LL",
    frequency_initial: "4:53 AM",
    frequency_final: "1:04 PM",
  },
  {
    id: 332,
    route: "TREX",
    frequency_initial: "7:56 AM",
    frequency_final: "9:02 AM",
  },
  {
    id: 333,
    route: "CIVI",
    frequency_initial: "6:15 PM",
    frequency_final: "11:51 PM",
  },
  {
    id: 334,
    route: "KKR^A",
    frequency_initial: "6:07 PM",
    frequency_final: "2:33 PM",
  },
  {
    id: 335,
    route: "AUPH",
    frequency_initial: "4:22 AM",
    frequency_final: "9:05 AM",
  },
  {
    id: 336,
    route: "CLW",
    frequency_initial: "3:39 AM",
    frequency_final: "12:50 PM",
  },
  {
    id: 337,
    route: "SFS",
    frequency_initial: "9:45 PM",
    frequency_final: "3:50 PM",
  },
  {
    id: 338,
    route: "WIRE",
    frequency_initial: "7:31 PM",
    frequency_final: "10:57 AM",
  },
  {
    id: 339,
    route: "RRC",
    frequency_initial: "1:17 PM",
    frequency_final: "11:40 AM",
  },
  {
    id: 340,
    route: "NS^B",
    frequency_initial: "7:31 PM",
    frequency_final: "12:03 PM",
  },
  {
    id: 341,
    route: "INFY",
    frequency_initial: "2:47 AM",
    frequency_final: "11:31 AM",
  },
  {
    id: 342,
    route: "JSML",
    frequency_initial: "8:43 PM",
    frequency_final: "8:44 AM",
  },
  {
    id: 343,
    route: "BDGE",
    frequency_initial: "8:08 AM",
    frequency_final: "5:01 AM",
  },
  {
    id: 344,
    route: "FTAG",
    frequency_initial: "9:27 AM",
    frequency_final: "4:21 AM",
  },
  {
    id: 345,
    route: "ESCA",
    frequency_initial: "10:11 PM",
    frequency_final: "11:51 AM",
  },
  {
    id: 346,
    route: "FTS",
    frequency_initial: "5:54 AM",
    frequency_final: "9:16 AM",
  },
  {
    id: 347,
    route: "MEOH",
    frequency_initial: "7:42 PM",
    frequency_final: "11:29 AM",
  },
  {
    id: 348,
    route: "BBT^D",
    frequency_initial: "2:23 PM",
    frequency_final: "2:45 PM",
  },
  {
    id: 349,
    route: "HBANO",
    frequency_initial: "10:12 AM",
    frequency_final: "6:54 AM",
  },
  {
    id: 350,
    route: "LOW",
    frequency_initial: "6:59 PM",
    frequency_final: "12:27 PM",
  },
  {
    id: 351,
    route: "ADUS",
    frequency_initial: "11:51 AM",
    frequency_final: "7:59 PM",
  },
  {
    id: 352,
    route: "ACHC",
    frequency_initial: "10:10 AM",
    frequency_final: "9:52 AM",
  },
  {
    id: 353,
    route: "SSNT",
    frequency_initial: "1:41 PM",
    frequency_final: "3:11 AM",
  },
  {
    id: 354,
    route: "FDIV",
    frequency_initial: "10:32 PM",
    frequency_final: "5:15 PM",
  },
  {
    id: 355,
    route: "VMC",
    frequency_initial: "2:13 AM",
    frequency_final: "10:34 PM",
  },
  {
    id: 356,
    route: "TRK",
    frequency_initial: "7:50 AM",
    frequency_final: "3:07 PM",
  },
  {
    id: 357,
    route: "SA",
    frequency_initial: "1:23 PM",
    frequency_final: "4:33 AM",
  },
  {
    id: 358,
    route: "AWRE",
    frequency_initial: "7:15 AM",
    frequency_final: "3:28 AM",
  },
  {
    id: 359,
    route: "WVVIP",
    frequency_initial: "10:24 PM",
    frequency_final: "1:03 AM",
  },
  {
    id: 360,
    route: "LMNX",
    frequency_initial: "3:30 AM",
    frequency_final: "5:21 AM",
  },
  {
    id: 361,
    route: "CCXI",
    frequency_initial: "7:54 PM",
    frequency_final: "11:11 AM",
  },
  {
    id: 362,
    route: "PAAS",
    frequency_initial: "8:03 PM",
    frequency_final: "9:35 PM",
  },
  {
    id: 363,
    route: "EGP",
    frequency_initial: "10:02 PM",
    frequency_final: "11:18 AM",
  },
  {
    id: 364,
    route: "EDN",
    frequency_initial: "10:21 PM",
    frequency_final: "9:40 AM",
  },
  {
    id: 365,
    route: "SSBI",
    frequency_initial: "12:46 AM",
    frequency_final: "12:49 PM",
  },
  {
    id: 366,
    route: "GGT",
    frequency_initial: "7:30 AM",
    frequency_final: "9:07 PM",
  },
  {
    id: 367,
    route: "ENOC",
    frequency_initial: "12:39 AM",
    frequency_final: "5:03 AM",
  },
  {
    id: 368,
    route: "MCHI",
    frequency_initial: "9:35 PM",
    frequency_final: "1:33 PM",
  },
  {
    id: 369,
    route: "BXP",
    frequency_initial: "12:38 PM",
    frequency_final: "3:51 AM",
  },
  {
    id: 370,
    route: "CNSL",
    frequency_initial: "9:05 AM",
    frequency_final: "2:55 AM",
  },
  {
    id: 371,
    route: "HBM.WS",
    frequency_initial: "3:41 AM",
    frequency_final: "4:40 PM",
  },
  {
    id: 372,
    route: "TRNO^A.CL",
    frequency_initial: "12:51 PM",
    frequency_final: "1:41 AM",
  },
  {
    id: 373,
    route: "AEY",
    frequency_initial: "2:43 AM",
    frequency_final: "1:35 AM",
  },
  {
    id: 374,
    route: "BPT",
    frequency_initial: "9:28 PM",
    frequency_final: "10:55 AM",
  },
  {
    id: 375,
    route: "RWLK",
    frequency_initial: "10:08 AM",
    frequency_final: "1:07 PM",
  },
  {
    id: 376,
    route: "NXJ",
    frequency_initial: "6:42 AM",
    frequency_final: "3:15 PM",
  },
  {
    id: 377,
    route: "POWI",
    frequency_initial: "9:54 AM",
    frequency_final: "7:56 PM",
  },
  {
    id: 378,
    route: "SENEA",
    frequency_initial: "1:41 AM",
    frequency_final: "12:03 PM",
  },
  {
    id: 379,
    route: "LMRK",
    frequency_initial: "6:23 AM",
    frequency_final: "11:45 PM",
  },
  {
    id: 380,
    route: "BBY",
    frequency_initial: "9:43 AM",
    frequency_final: "12:42 PM",
  },
  {
    id: 381,
    route: "NCB",
    frequency_initial: "1:33 AM",
    frequency_final: "11:29 PM",
  },
  {
    id: 382,
    route: "GS^K",
    frequency_initial: "3:42 PM",
    frequency_final: "9:45 PM",
  },
  {
    id: 383,
    route: "MGU",
    frequency_initial: "4:06 AM",
    frequency_final: "6:58 AM",
  },
  {
    id: 384,
    route: "ADC",
    frequency_initial: "3:58 AM",
    frequency_final: "10:24 AM",
  },
  {
    id: 385,
    route: "ACAD",
    frequency_initial: "6:53 PM",
    frequency_final: "10:17 AM",
  },
  {
    id: 386,
    route: "RFAP",
    frequency_initial: "1:07 AM",
    frequency_final: "3:39 PM",
  },
  {
    id: 387,
    route: "BL",
    frequency_initial: "3:26 PM",
    frequency_final: "7:46 AM",
  },
  {
    id: 388,
    route: "TTD",
    frequency_initial: "10:54 PM",
    frequency_final: "4:24 AM",
  },
  {
    id: 389,
    route: "KRNY",
    frequency_initial: "1:19 AM",
    frequency_final: "12:43 AM",
  },
  {
    id: 390,
    route: "EGF",
    frequency_initial: "10:48 PM",
    frequency_final: "8:04 PM",
  },
  {
    id: 391,
    route: "CNX",
    frequency_initial: "10:25 AM",
    frequency_final: "7:37 AM",
  },
  {
    id: 392,
    route: "CLIRW",
    frequency_initial: "12:50 PM",
    frequency_final: "1:27 PM",
  },
  {
    id: 393,
    route: "MTW",
    frequency_initial: "4:36 PM",
    frequency_final: "1:27 PM",
  },
  {
    id: 394,
    route: "FGEN",
    frequency_initial: "4:21 AM",
    frequency_final: "4:12 PM",
  },
  {
    id: 395,
    route: "ROK",
    frequency_initial: "10:56 PM",
    frequency_final: "12:07 AM",
  },
  {
    id: 396,
    route: "RAS^A",
    frequency_initial: "10:16 AM",
    frequency_final: "5:03 AM",
  },
  {
    id: 397,
    route: "HONE",
    frequency_initial: "9:41 AM",
    frequency_final: "10:18 PM",
  },
  {
    id: 398,
    route: "SNOAW",
    frequency_initial: "9:03 PM",
    frequency_final: "7:30 AM",
  },
  {
    id: 399,
    route: "CHDN",
    frequency_initial: "3:38 AM",
    frequency_final: "1:24 PM",
  },
  {
    id: 400,
    route: "BSF",
    frequency_initial: "11:49 AM",
    frequency_final: "8:19 PM",
  },
  {
    id: 401,
    route: "SGC",
    frequency_initial: "12:38 PM",
    frequency_final: "8:16 AM",
  },
  {
    id: 402,
    route: "LKSD",
    frequency_initial: "2:09 AM",
    frequency_final: "8:53 AM",
  },
  {
    id: 403,
    route: "ZG",
    frequency_initial: "10:41 AM",
    frequency_final: "11:52 PM",
  },
  {
    id: 404,
    route: "COTY",
    frequency_initial: "5:37 PM",
    frequency_final: "4:27 PM",
  },
  {
    id: 405,
    route: "SNH",
    frequency_initial: "8:55 AM",
    frequency_final: "12:17 PM",
  },
  {
    id: 406,
    route: "MDR",
    frequency_initial: "9:16 AM",
    frequency_final: "11:55 AM",
  },
  {
    id: 407,
    route: "CTL",
    frequency_initial: "7:13 AM",
    frequency_final: "1:09 PM",
  },
  {
    id: 408,
    route: "RDNT",
    frequency_initial: "10:30 AM",
    frequency_final: "12:40 AM",
  },
  {
    id: 409,
    route: "FSD",
    frequency_initial: "5:57 AM",
    frequency_final: "8:04 PM",
  },
  {
    id: 410,
    route: "PSA^C",
    frequency_initial: "4:22 PM",
    frequency_final: "10:50 PM",
  },
  {
    id: 411,
    route: "RGCO",
    frequency_initial: "8:03 PM",
    frequency_final: "12:44 AM",
  },
  {
    id: 412,
    route: "TY^",
    frequency_initial: "3:11 AM",
    frequency_final: "7:48 AM",
  },
  {
    id: 413,
    route: "FTW",
    frequency_initial: "9:30 AM",
    frequency_final: "7:39 AM",
  },
  {
    id: 414,
    route: "LMIA",
    frequency_initial: "12:50 PM",
    frequency_final: "12:28 AM",
  },
  {
    id: 415,
    route: "CID",
    frequency_initial: "12:39 AM",
    frequency_final: "11:19 AM",
  },
  {
    id: 416,
    route: "KIM^I",
    frequency_initial: "5:33 AM",
    frequency_final: "3:26 PM",
  },
  {
    id: 417,
    route: "PEIX",
    frequency_initial: "5:01 AM",
    frequency_final: "1:47 PM",
  },
  {
    id: 418,
    route: "BWFG",
    frequency_initial: "10:25 AM",
    frequency_final: "7:55 AM",
  },
  {
    id: 419,
    route: "REXR",
    frequency_initial: "4:18 PM",
    frequency_final: "10:00 AM",
  },
  {
    id: 420,
    route: "IRM",
    frequency_initial: "5:57 AM",
    frequency_final: "9:05 AM",
  },
  {
    id: 421,
    route: "BOLD",
    frequency_initial: "4:55 PM",
    frequency_final: "2:17 AM",
  },
  {
    id: 422,
    route: "NESRW",
    frequency_initial: "1:42 AM",
    frequency_final: "2:33 PM",
  },
  {
    id: 423,
    route: "ATH",
    frequency_initial: "3:39 AM",
    frequency_final: "11:02 AM",
  },
  {
    id: 424,
    route: "NBL",
    frequency_initial: "10:48 AM",
    frequency_final: "4:42 PM",
  },
  {
    id: 425,
    route: "VRTV",
    frequency_initial: "11:39 AM",
    frequency_final: "5:49 AM",
  },
  {
    id: 426,
    route: "CVLT",
    frequency_initial: "2:36 PM",
    frequency_final: "10:15 AM",
  },
  {
    id: 427,
    route: "EVHC^",
    frequency_initial: "10:33 AM",
    frequency_final: "3:51 AM",
  },
  {
    id: 428,
    route: "BABY",
    frequency_initial: "12:32 AM",
    frequency_final: "1:58 AM",
  },
  {
    id: 429,
    route: "UBND",
    frequency_initial: "10:55 AM",
    frequency_final: "5:05 PM",
  },
  {
    id: 430,
    route: "GSUM",
    frequency_initial: "10:55 AM",
    frequency_final: "2:44 PM",
  },
  {
    id: 431,
    route: "AEK",
    frequency_initial: "5:25 AM",
    frequency_final: "10:16 PM",
  },
  {
    id: 432,
    route: "CELH",
    frequency_initial: "4:29 AM",
    frequency_final: "9:09 AM",
  },
  {
    id: 433,
    route: "GLOG^A",
    frequency_initial: "4:40 AM",
    frequency_final: "9:46 PM",
  },
  {
    id: 434,
    route: "SBFGP",
    frequency_initial: "5:52 AM",
    frequency_final: "3:29 PM",
  },
  {
    id: 435,
    route: "VWR",
    frequency_initial: "3:47 PM",
    frequency_final: "8:00 PM",
  },
  {
    id: 436,
    route: "NVO",
    frequency_initial: "9:20 PM",
    frequency_final: "12:01 PM",
  },
  {
    id: 437,
    route: "ALDX",
    frequency_initial: "1:05 PM",
    frequency_final: "2:51 PM",
  },
  {
    id: 438,
    route: "ARI^A",
    frequency_initial: "1:53 AM",
    frequency_final: "11:09 PM",
  },
  {
    id: 439,
    route: "GDDY",
    frequency_initial: "6:18 AM",
    frequency_final: "5:26 AM",
  },
  {
    id: 440,
    route: "WCFB",
    frequency_initial: "11:27 PM",
    frequency_final: "1:20 PM",
  },
  {
    id: 441,
    route: "FOR",
    frequency_initial: "4:30 PM",
    frequency_final: "8:37 PM",
  },
  {
    id: 442,
    route: "MTH",
    frequency_initial: "6:55 PM",
    frequency_final: "11:04 PM",
  },
  {
    id: 443,
    route: "ADOM",
    frequency_initial: "12:59 AM",
    frequency_final: "4:15 AM",
  },
  {
    id: 444,
    route: "BIT",
    frequency_initial: "3:10 AM",
    frequency_final: "6:21 PM",
  },
  {
    id: 445,
    route: "CUB",
    frequency_initial: "8:09 PM",
    frequency_final: "6:01 PM",
  },
  {
    id: 446,
    route: "BBOX",
    frequency_initial: "3:43 AM",
    frequency_final: "8:40 AM",
  },
  {
    id: 447,
    route: "VRSN",
    frequency_initial: "5:46 PM",
    frequency_final: "7:04 AM",
  },
  {
    id: 448,
    route: "CXP",
    frequency_initial: "8:10 AM",
    frequency_final: "12:25 AM",
  },
  {
    id: 449,
    route: "FMK",
    frequency_initial: "3:19 AM",
    frequency_final: "11:21 AM",
  },
  {
    id: 450,
    route: "BRX",
    frequency_initial: "8:27 AM",
    frequency_final: "9:30 PM",
  },
  {
    id: 451,
    route: "MTEX",
    frequency_initial: "12:17 AM",
    frequency_final: "4:50 PM",
  },
  {
    id: 452,
    route: "VIDI",
    frequency_initial: "1:39 PM",
    frequency_final: "6:57 PM",
  },
  {
    id: 453,
    route: "WSTC",
    frequency_initial: "5:38 AM",
    frequency_final: "11:04 PM",
  },
  {
    id: 454,
    route: "IPXL",
    frequency_initial: "11:05 AM",
    frequency_final: "4:26 AM",
  },
  {
    id: 455,
    route: "JCE",
    frequency_initial: "3:49 PM",
    frequency_final: "10:57 AM",
  },
  {
    id: 456,
    route: "SRDX",
    frequency_initial: "6:59 AM",
    frequency_final: "1:58 PM",
  },
  {
    id: 457,
    route: "GOF",
    frequency_initial: "8:39 PM",
    frequency_final: "8:11 AM",
  },
  {
    id: 458,
    route: "ASTE",
    frequency_initial: "1:21 AM",
    frequency_final: "7:19 AM",
  },
  {
    id: 459,
    route: "BXMT",
    frequency_initial: "3:08 AM",
    frequency_final: "6:48 AM",
  },
  {
    id: 460,
    route: "CFRX",
    frequency_initial: "6:28 AM",
    frequency_final: "4:19 AM",
  },
  {
    id: 461,
    route: "PES",
    frequency_initial: "7:42 PM",
    frequency_final: "1:40 AM",
  },
  {
    id: 462,
    route: "STAR^E",
    frequency_initial: "2:47 PM",
    frequency_final: "7:55 AM",
  },
  {
    id: 463,
    route: "SPXC",
    frequency_initial: "4:39 AM",
    frequency_final: "3:50 PM",
  },
  {
    id: 464,
    route: "AMGP",
    frequency_initial: "6:48 AM",
    frequency_final: "2:50 AM",
  },
  {
    id: 465,
    route: "ENSG",
    frequency_initial: "2:42 PM",
    frequency_final: "9:50 PM",
  },
  {
    id: 466,
    route: "ANSS",
    frequency_initial: "7:50 AM",
    frequency_final: "7:17 AM",
  },
  {
    id: 467,
    route: "OPHC",
    frequency_initial: "8:19 PM",
    frequency_final: "8:21 AM",
  },
  {
    id: 468,
    route: "TRHC",
    frequency_initial: "11:04 AM",
    frequency_final: "12:59 AM",
  },
  {
    id: 469,
    route: "ICON",
    frequency_initial: "6:59 AM",
    frequency_final: "4:46 PM",
  },
  {
    id: 470,
    route: "EIX",
    frequency_initial: "10:06 PM",
    frequency_final: "6:58 AM",
  },
  {
    id: 471,
    route: "KMG",
    frequency_initial: "3:07 PM",
    frequency_final: "4:24 PM",
  },
  {
    id: 472,
    route: "KTOV",
    frequency_initial: "6:38 PM",
    frequency_final: "7:59 PM",
  },
  {
    id: 473,
    route: "TAST",
    frequency_initial: "12:44 AM",
    frequency_final: "6:35 PM",
  },
  {
    id: 474,
    route: "QSR",
    frequency_initial: "2:22 PM",
    frequency_final: "3:45 AM",
  },
  {
    id: 475,
    route: "SGLB",
    frequency_initial: "11:32 PM",
    frequency_final: "2:29 PM",
  },
  {
    id: 476,
    route: "CENTA",
    frequency_initial: "9:50 PM",
    frequency_final: "2:05 PM",
  },
  {
    id: 477,
    route: "INSM",
    frequency_initial: "11:53 PM",
    frequency_final: "11:50 PM",
  },
  {
    id: 478,
    route: "STAR^F",
    frequency_initial: "6:39 AM",
    frequency_final: "1:00 PM",
  },
  {
    id: 479,
    route: "RAVN",
    frequency_initial: "4:59 PM",
    frequency_final: "10:45 AM",
  },
  {
    id: 480,
    route: "KO",
    frequency_initial: "3:58 AM",
    frequency_final: "1:06 PM",
  },
  {
    id: 481,
    route: "FNCX",
    frequency_initial: "1:03 AM",
    frequency_final: "1:26 PM",
  },
  {
    id: 482,
    route: "TSU",
    frequency_initial: "11:13 AM",
    frequency_final: "11:05 AM",
  },
  {
    id: 483,
    route: "FRME",
    frequency_initial: "8:21 PM",
    frequency_final: "10:41 PM",
  },
  {
    id: 484,
    route: "CI",
    frequency_initial: "9:19 AM",
    frequency_final: "1:17 PM",
  },
  {
    id: 485,
    route: "DTE",
    frequency_initial: "6:46 AM",
    frequency_final: "4:38 AM",
  },
  {
    id: 486,
    route: "IID",
    frequency_initial: "10:00 AM",
    frequency_final: "9:09 PM",
  },
  {
    id: 487,
    route: "GJV",
    frequency_initial: "7:27 PM",
    frequency_final: "3:15 AM",
  },
  {
    id: 488,
    route: "SUPV",
    frequency_initial: "11:40 PM",
    frequency_final: "12:25 PM",
  },
  {
    id: 489,
    route: "JEC",
    frequency_initial: "2:43 PM",
    frequency_final: "11:02 PM",
  },
  {
    id: 490,
    route: "CASS",
    frequency_initial: "3:11 AM",
    frequency_final: "1:10 PM",
  },
  {
    id: 491,
    route: "STT^D",
    frequency_initial: "2:01 PM",
    frequency_final: "11:06 PM",
  },
  {
    id: 492,
    route: "HIFR",
    frequency_initial: "10:13 PM",
    frequency_final: "4:56 AM",
  },
  {
    id: 493,
    route: "FTXD",
    frequency_initial: "12:21 PM",
    frequency_final: "6:59 PM",
  },
  {
    id: 494,
    route: "SNR",
    frequency_initial: "6:03 PM",
    frequency_final: "4:58 AM",
  },
  {
    id: 495,
    route: "LDF",
    frequency_initial: "11:50 PM",
    frequency_final: "5:09 AM",
  },
  {
    id: 496,
    route: "HUNT",
    frequency_initial: "1:03 PM",
    frequency_final: "10:27 PM",
  },
  {
    id: 497,
    route: "EVHC",
    frequency_initial: "2:18 PM",
    frequency_final: "7:19 AM",
  },
  {
    id: 498,
    route: "MPACU",
    frequency_initial: "8:13 PM",
    frequency_final: "2:50 PM",
  },
  {
    id: 499,
    route: "CAG",
    frequency_initial: "9:29 AM",
    frequency_final: "9:10 PM",
  },
  {
    id: 500,
    route: "VRAY",
    frequency_initial: "5:51 PM",
    frequency_final: "11:29 PM",
  },
  {
    id: 501,
    route: "CBPX",
    frequency_initial: "2:36 AM",
    frequency_final: "2:57 AM",
  },
  {
    id: 502,
    route: "PRXL",
    frequency_initial: "11:03 AM",
    frequency_final: "1:56 PM",
  },
  {
    id: 503,
    route: "CRMT",
    frequency_initial: "2:09 AM",
    frequency_final: "11:39 AM",
  },
  {
    id: 504,
    route: "AHT",
    frequency_initial: "9:23 AM",
    frequency_final: "3:30 AM",
  },
  {
    id: 505,
    route: "SRTS",
    frequency_initial: "6:13 AM",
    frequency_final: "4:35 PM",
  },
  {
    id: 506,
    route: "CLACU",
    frequency_initial: "10:32 AM",
    frequency_final: "3:43 AM",
  },
  {
    id: 507,
    route: "INFO",
    frequency_initial: "11:07 PM",
    frequency_final: "8:16 PM",
  },
  {
    id: 508,
    route: "SCL",
    frequency_initial: "1:38 PM",
    frequency_final: "4:18 AM",
  },
  {
    id: 509,
    route: "FDS",
    frequency_initial: "10:25 PM",
    frequency_final: "4:37 AM",
  },
  {
    id: 510,
    route: "HIW",
    frequency_initial: "8:42 AM",
    frequency_final: "10:44 PM",
  },
  {
    id: 511,
    route: "KKR^B",
    frequency_initial: "4:40 AM",
    frequency_final: "7:15 PM",
  },
  {
    id: 512,
    route: "REIS",
    frequency_initial: "11:42 PM",
    frequency_final: "10:07 PM",
  },
  {
    id: 513,
    route: "OSIS",
    frequency_initial: "5:09 PM",
    frequency_final: "5:48 PM",
  },
  {
    id: 514,
    route: "IPCI",
    frequency_initial: "12:08 AM",
    frequency_final: "8:37 PM",
  },
  {
    id: 515,
    route: "MGI",
    frequency_initial: "9:09 PM",
    frequency_final: "9:32 PM",
  },
  {
    id: 516,
    route: "UTF",
    frequency_initial: "7:55 AM",
    frequency_final: "5:03 AM",
  },
  {
    id: 517,
    route: "QRHC",
    frequency_initial: "12:57 PM",
    frequency_final: "2:11 PM",
  },
  {
    id: 518,
    route: "SPWR",
    frequency_initial: "8:16 PM",
    frequency_final: "1:41 PM",
  },
  {
    id: 519,
    route: "PBH",
    frequency_initial: "9:19 AM",
    frequency_final: "11:17 PM",
  },
  {
    id: 520,
    route: "CDTX",
    frequency_initial: "9:46 PM",
    frequency_final: "2:58 AM",
  },
  {
    id: 521,
    route: "UBFO",
    frequency_initial: "7:48 AM",
    frequency_final: "6:06 PM",
  },
  {
    id: 522,
    route: "VFC",
    frequency_initial: "9:47 PM",
    frequency_final: "6:59 AM",
  },
  {
    id: 523,
    route: "AIV",
    frequency_initial: "1:06 AM",
    frequency_final: "9:44 AM",
  },
  {
    id: 524,
    route: "ACRS",
    frequency_initial: "2:41 PM",
    frequency_final: "2:32 PM",
  },
  {
    id: 525,
    route: "OMAB",
    frequency_initial: "10:27 PM",
    frequency_final: "6:19 AM",
  },
  {
    id: 526,
    route: "SFS",
    frequency_initial: "10:27 AM",
    frequency_final: "7:08 PM",
  },
  {
    id: 527,
    route: "AFMD",
    frequency_initial: "11:50 AM",
    frequency_final: "12:48 AM",
  },
  {
    id: 528,
    route: "GCVRZ",
    frequency_initial: "3:43 AM",
    frequency_final: "12:52 AM",
  },
  {
    id: 529,
    route: "CNXN",
    frequency_initial: "2:52 PM",
    frequency_final: "6:32 AM",
  },
  {
    id: 530,
    route: "FAF",
    frequency_initial: "12:26 PM",
    frequency_final: "2:33 AM",
  },
  {
    id: 531,
    route: "TORM          ",
    frequency_initial: "4:01 AM",
    frequency_final: "7:14 PM",
  },
  {
    id: 532,
    route: "SMIT",
    frequency_initial: "1:52 AM",
    frequency_final: "3:09 AM",
  },
  {
    id: 533,
    route: "LC",
    frequency_initial: "1:53 AM",
    frequency_final: "7:33 AM",
  },
  {
    id: 534,
    route: "CDK",
    frequency_initial: "4:52 PM",
    frequency_final: "5:27 AM",
  },
  {
    id: 535,
    route: "STT^C",
    frequency_initial: "8:22 PM",
    frequency_final: "2:33 AM",
  },
  {
    id: 536,
    route: "HTLF",
    frequency_initial: "12:43 AM",
    frequency_final: "8:57 AM",
  },
  {
    id: 537,
    route: "SBBC",
    frequency_initial: "10:05 PM",
    frequency_final: "7:34 AM",
  },
  {
    id: 538,
    route: "AXTI",
    frequency_initial: "8:31 PM",
    frequency_final: "8:57 AM",
  },
  {
    id: 539,
    route: "ACTX",
    frequency_initial: "11:00 AM",
    frequency_final: "2:10 PM",
  },
  {
    id: 540,
    route: "TMST",
    frequency_initial: "9:47 PM",
    frequency_final: "1:22 PM",
  },
  {
    id: 541,
    route: "KGC",
    frequency_initial: "12:56 AM",
    frequency_final: "5:06 AM",
  },
  {
    id: 542,
    route: "DWAT",
    frequency_initial: "9:28 PM",
    frequency_final: "3:32 AM",
  },
  {
    id: 543,
    route: "WLKP",
    frequency_initial: "11:07 PM",
    frequency_final: "11:08 AM",
  },
  {
    id: 544,
    route: "KEYW",
    frequency_initial: "6:25 AM",
    frequency_final: "6:42 AM",
  },
  {
    id: 545,
    route: "ARNC^B",
    frequency_initial: "9:11 AM",
    frequency_final: "5:23 AM",
  },
  {
    id: 546,
    route: "WLDN",
    frequency_initial: "10:17 AM",
    frequency_final: "9:42 PM",
  },
  {
    id: 547,
    route: "LBTYK",
    frequency_initial: "11:02 AM",
    frequency_final: "6:29 PM",
  },
  {
    id: 548,
    route: "CRIS",
    frequency_initial: "5:56 PM",
    frequency_final: "1:49 AM",
  },
  {
    id: 549,
    route: "KMG",
    frequency_initial: "11:14 AM",
    frequency_final: "9:21 AM",
  },
  {
    id: 550,
    route: "CA",
    frequency_initial: "8:58 AM",
    frequency_final: "4:03 AM",
  },
  {
    id: 551,
    route: "BREW",
    frequency_initial: "5:26 AM",
    frequency_final: "9:43 AM",
  },
  {
    id: 552,
    route: "CUBI",
    frequency_initial: "9:44 PM",
    frequency_final: "12:21 PM",
  },
  {
    id: 553,
    route: "SERV",
    frequency_initial: "8:48 AM",
    frequency_final: "10:31 AM",
  },
  {
    id: 554,
    route: "JD",
    frequency_initial: "5:47 AM",
    frequency_final: "11:16 AM",
  },
  {
    id: 555,
    route: "EPD",
    frequency_initial: "9:15 PM",
    frequency_final: "6:02 AM",
  },
  {
    id: 556,
    route: "UMPQ",
    frequency_initial: "12:56 AM",
    frequency_final: "12:40 AM",
  },
  {
    id: 557,
    route: "BHL",
    frequency_initial: "1:36 PM",
    frequency_final: "12:41 AM",
  },
  {
    id: 558,
    route: "KMB",
    frequency_initial: "6:41 AM",
    frequency_final: "11:01 AM",
  },
  {
    id: 559,
    route: "SHG",
    frequency_initial: "3:19 PM",
    frequency_final: "3:12 PM",
  },
  {
    id: 560,
    route: "SATS",
    frequency_initial: "1:59 PM",
    frequency_final: "4:35 AM",
  },
  {
    id: 561,
    route: "PMM",
    frequency_initial: "11:17 PM",
    frequency_final: "9:33 AM",
  },
  {
    id: 562,
    route: "ZBK",
    frequency_initial: "3:47 AM",
    frequency_final: "5:25 PM",
  },
  {
    id: 563,
    route: "SAVE",
    frequency_initial: "11:58 AM",
    frequency_final: "5:40 AM",
  },
  {
    id: 564,
    route: "BREW",
    frequency_initial: "4:48 AM",
    frequency_final: "6:20 PM",
  },
  {
    id: 565,
    route: "NRP",
    frequency_initial: "3:24 PM",
    frequency_final: "10:10 AM",
  },
  {
    id: 566,
    route: "HRS",
    frequency_initial: "12:06 AM",
    frequency_final: "11:21 AM",
  },
  {
    id: 567,
    route: "SUNW",
    frequency_initial: "2:15 PM",
    frequency_final: "4:21 AM",
  },
  {
    id: 568,
    route: "IEUS",
    frequency_initial: "11:37 PM",
    frequency_final: "8:54 AM",
  },
  {
    id: 569,
    route: "LAWS",
    frequency_initial: "12:51 PM",
    frequency_final: "9:15 AM",
  },
  {
    id: 570,
    route: "GNUS",
    frequency_initial: "5:43 AM",
    frequency_final: "5:39 AM",
  },
  {
    id: 571,
    route: "SLP",
    frequency_initial: "3:38 PM",
    frequency_final: "11:08 PM",
  },
  {
    id: 572,
    route: "TSEM",
    frequency_initial: "1:05 AM",
    frequency_final: "8:12 AM",
  },
  {
    id: 573,
    route: "FISI",
    frequency_initial: "6:41 PM",
    frequency_final: "4:44 AM",
  },
  {
    id: 574,
    route: "CHTR",
    frequency_initial: "9:07 PM",
    frequency_final: "6:25 PM",
  },
  {
    id: 575,
    route: "MAV",
    frequency_initial: "7:53 AM",
    frequency_final: "8:11 PM",
  },
  {
    id: 576,
    route: "ESIO",
    frequency_initial: "1:12 AM",
    frequency_final: "5:54 PM",
  },
  {
    id: 577,
    route: "GLNG",
    frequency_initial: "3:03 PM",
    frequency_final: "1:24 PM",
  },
  {
    id: 578,
    route: "MBFIP",
    frequency_initial: "3:09 PM",
    frequency_final: "7:03 PM",
  },
  {
    id: 579,
    route: "NAII",
    frequency_initial: "9:18 PM",
    frequency_final: "8:04 PM",
  },
  {
    id: 580,
    route: "MANU",
    frequency_initial: "7:10 PM",
    frequency_final: "1:34 AM",
  },
  {
    id: 581,
    route: "IPL^D",
    frequency_initial: "3:35 AM",
    frequency_final: "1:42 AM",
  },
  {
    id: 582,
    route: "ASCMA",
    frequency_initial: "2:31 PM",
    frequency_final: "3:44 AM",
  },
  {
    id: 583,
    route: "COH",
    frequency_initial: "5:38 AM",
    frequency_final: "5:52 PM",
  },
  {
    id: 584,
    route: "ZUMZ",
    frequency_initial: "4:01 AM",
    frequency_final: "8:54 PM",
  },
  {
    id: 585,
    route: "DMB",
    frequency_initial: "2:48 AM",
    frequency_final: "7:19 AM",
  },
  {
    id: 586,
    route: "YUME",
    frequency_initial: "12:21 PM",
    frequency_final: "10:51 PM",
  },
  {
    id: 587,
    route: "SORL",
    frequency_initial: "1:18 PM",
    frequency_final: "10:55 AM",
  },
  {
    id: 588,
    route: "ARIS",
    frequency_initial: "12:32 AM",
    frequency_final: "8:16 AM",
  },
  {
    id: 589,
    route: "STRA",
    frequency_initial: "12:55 PM",
    frequency_final: "1:47 PM",
  },
  {
    id: 590,
    route: "BABA",
    frequency_initial: "5:35 AM",
    frequency_final: "6:46 PM",
  },
  {
    id: 591,
    route: "CDZI",
    frequency_initial: "2:00 PM",
    frequency_final: "3:44 PM",
  },
  {
    id: 592,
    route: "VKTXW",
    frequency_initial: "12:05 AM",
    frequency_final: "7:20 PM",
  },
  {
    id: 593,
    route: "MDP",
    frequency_initial: "9:19 PM",
    frequency_final: "7:31 PM",
  },
  {
    id: 594,
    route: "PSCD",
    frequency_initial: "7:34 AM",
    frequency_final: "5:20 AM",
  },
  {
    id: 595,
    route: "GDO",
    frequency_initial: "2:25 PM",
    frequency_final: "1:51 PM",
  },
  {
    id: 596,
    route: "EBAY",
    frequency_initial: "9:52 PM",
    frequency_final: "7:27 PM",
  },
  {
    id: 597,
    route: "LHO^J",
    frequency_initial: "7:56 PM",
    frequency_final: "12:14 AM",
  },
  {
    id: 598,
    route: "FELE",
    frequency_initial: "2:20 PM",
    frequency_final: "6:34 PM",
  },
  {
    id: 599,
    route: "LEG",
    frequency_initial: "7:33 AM",
    frequency_final: "11:39 PM",
  },
  {
    id: 600,
    route: "WHR",
    frequency_initial: "10:56 AM",
    frequency_final: "11:41 PM",
  },
  {
    id: 601,
    route: "SMRT",
    frequency_initial: "7:06 PM",
    frequency_final: "12:04 PM",
  },
  {
    id: 602,
    route: "KEY^I",
    frequency_initial: "2:43 AM",
    frequency_final: "6:01 AM",
  },
  {
    id: 603,
    route: "NAZ",
    frequency_initial: "8:13 PM",
    frequency_final: "4:35 AM",
  },
  {
    id: 604,
    route: "FMS",
    frequency_initial: "8:27 AM",
    frequency_final: "5:57 AM",
  },
  {
    id: 605,
    route: "SPHS",
    frequency_initial: "7:45 AM",
    frequency_final: "4:40 PM",
  },
  {
    id: 606,
    route: "BST",
    frequency_initial: "1:29 AM",
    frequency_final: "3:31 PM",
  },
  {
    id: 607,
    route: "FINX",
    frequency_initial: "8:14 AM",
    frequency_final: "8:46 PM",
  },
  {
    id: 608,
    route: "FRT",
    frequency_initial: "10:29 PM",
    frequency_final: "5:44 AM",
  },
  {
    id: 609,
    route: "NLSN",
    frequency_initial: "3:28 AM",
    frequency_final: "11:04 PM",
  },
  {
    id: 610,
    route: "CNMD",
    frequency_initial: "2:25 AM",
    frequency_final: "2:52 AM",
  },
  {
    id: 611,
    route: "CASS",
    frequency_initial: "9:12 AM",
    frequency_final: "1:48 AM",
  },
  {
    id: 612,
    route: "QIWI",
    frequency_initial: "2:00 PM",
    frequency_final: "3:43 PM",
  },
  {
    id: 613,
    route: "CHK",
    frequency_initial: "7:05 PM",
    frequency_final: "1:13 AM",
  },
  {
    id: 614,
    route: "VCIT",
    frequency_initial: "7:29 PM",
    frequency_final: "9:15 PM",
  },
  {
    id: 615,
    route: "KORS",
    frequency_initial: "7:49 AM",
    frequency_final: "11:08 AM",
  },
  {
    id: 616,
    route: "VOD",
    frequency_initial: "9:27 AM",
    frequency_final: "4:32 AM",
  },
  {
    id: 617,
    route: "MXF",
    frequency_initial: "11:29 AM",
    frequency_final: "12:13 AM",
  },
  {
    id: 618,
    route: "APEI",
    frequency_initial: "1:21 AM",
    frequency_final: "10:00 AM",
  },
  {
    id: 619,
    route: "GBX",
    frequency_initial: "5:03 AM",
    frequency_final: "1:55 PM",
  },
  {
    id: 620,
    route: "CVO",
    frequency_initial: "6:26 AM",
    frequency_final: "12:18 AM",
  },
  {
    id: 621,
    route: "IAC",
    frequency_initial: "8:00 PM",
    frequency_final: "2:07 PM",
  },
  {
    id: 622,
    route: "HPE",
    frequency_initial: "8:12 PM",
    frequency_final: "10:56 PM",
  },
  {
    id: 623,
    route: "MPO",
    frequency_initial: "11:30 PM",
    frequency_final: "10:32 PM",
  },
  {
    id: 624,
    route: "EBAY",
    frequency_initial: "5:38 AM",
    frequency_final: "11:35 AM",
  },
  {
    id: 625,
    route: "DTV",
    frequency_initial: "4:33 PM",
    frequency_final: "9:30 AM",
  },
  {
    id: 626,
    route: "TCO^J",
    frequency_initial: "5:46 PM",
    frequency_final: "4:37 AM",
  },
  {
    id: 627,
    route: "ESLT",
    frequency_initial: "5:24 PM",
    frequency_final: "3:41 AM",
  },
  {
    id: 628,
    route: "GGZ",
    frequency_initial: "6:13 AM",
    frequency_final: "6:09 PM",
  },
  {
    id: 629,
    route: "RVLT",
    frequency_initial: "8:13 PM",
    frequency_final: "11:23 AM",
  },
  {
    id: 630,
    route: "FHK",
    frequency_initial: "3:04 PM",
    frequency_final: "4:59 PM",
  },
  {
    id: 631,
    route: "OFED",
    frequency_initial: "5:48 PM",
    frequency_final: "5:49 AM",
  },
  {
    id: 632,
    route: "VEDL",
    frequency_initial: "5:54 AM",
    frequency_final: "3:48 PM",
  },
  {
    id: 633,
    route: "PNC^P",
    frequency_initial: "12:46 AM",
    frequency_final: "7:17 PM",
  },
  {
    id: 634,
    route: "SSW^H",
    frequency_initial: "10:44 PM",
    frequency_final: "11:05 AM",
  },
  {
    id: 635,
    route: "PSA^X",
    frequency_initial: "2:17 PM",
    frequency_final: "1:09 PM",
  },
  {
    id: 636,
    route: "ARES",
    frequency_initial: "4:31 PM",
    frequency_final: "12:58 AM",
  },
  {
    id: 637,
    route: "GPS",
    frequency_initial: "9:36 AM",
    frequency_final: "11:12 AM",
  },
  {
    id: 638,
    route: "NTL",
    frequency_initial: "2:54 PM",
    frequency_final: "1:15 PM",
  },
  {
    id: 639,
    route: "GLOP",
    frequency_initial: "9:15 PM",
    frequency_final: "5:14 AM",
  },
  {
    id: 640,
    route: "AMED",
    frequency_initial: "7:58 PM",
    frequency_final: "1:41 AM",
  },
  {
    id: 641,
    route: "PZRX",
    frequency_initial: "2:24 AM",
    frequency_final: "10:50 AM",
  },
  {
    id: 642,
    route: "AKTX",
    frequency_initial: "11:28 PM",
    frequency_final: "12:34 PM",
  },
  {
    id: 643,
    route: "HBM.WS",
    frequency_initial: "7:31 AM",
    frequency_final: "8:35 PM",
  },
  {
    id: 644,
    route: "NHTC",
    frequency_initial: "5:02 AM",
    frequency_final: "10:19 PM",
  },
  {
    id: 645,
    route: "TDA",
    frequency_initial: "5:39 AM",
    frequency_final: "8:07 PM",
  },
  {
    id: 646,
    route: "CYS",
    frequency_initial: "8:44 AM",
    frequency_final: "10:27 PM",
  },
  {
    id: 647,
    route: "TYL",
    frequency_initial: "5:28 PM",
    frequency_final: "8:16 AM",
  },
  {
    id: 648,
    route: "LCM",
    frequency_initial: "1:46 AM",
    frequency_final: "12:47 PM",
  },
  {
    id: 649,
    route: "CG",
    frequency_initial: "5:31 PM",
    frequency_final: "11:42 PM",
  },
  {
    id: 650,
    route: "MCY",
    frequency_initial: "7:26 AM",
    frequency_final: "7:07 PM",
  },
  {
    id: 651,
    route: "SONA",
    frequency_initial: "9:49 PM",
    frequency_final: "8:32 AM",
  },
  {
    id: 652,
    route: "ACGL",
    frequency_initial: "6:18 PM",
    frequency_final: "5:38 AM",
  },
  {
    id: 653,
    route: "FATE",
    frequency_initial: "2:53 PM",
    frequency_final: "10:15 PM",
  },
  {
    id: 654,
    route: "GTYHW",
    frequency_initial: "8:57 AM",
    frequency_final: "7:07 AM",
  },
  {
    id: 655,
    route: "STLRW",
    frequency_initial: "10:08 PM",
    frequency_final: "8:15 AM",
  },
  {
    id: 656,
    route: "MLHR",
    frequency_initial: "12:15 AM",
    frequency_final: "4:34 PM",
  },
  {
    id: 657,
    route: "BF.B",
    frequency_initial: "8:15 PM",
    frequency_final: "5:01 AM",
  },
  {
    id: 658,
    route: "OIBR.C",
    frequency_initial: "10:05 AM",
    frequency_final: "2:10 AM",
  },
  {
    id: 659,
    route: "HDSN",
    frequency_initial: "8:50 PM",
    frequency_final: "1:06 PM",
  },
  {
    id: 660,
    route: "BLCM",
    frequency_initial: "10:59 AM",
    frequency_final: "1:23 AM",
  },
  {
    id: 661,
    route: "ZTS",
    frequency_initial: "2:51 AM",
    frequency_final: "9:03 AM",
  },
  {
    id: 662,
    route: "PCF",
    frequency_initial: "1:21 PM",
    frequency_final: "1:20 AM",
  },
  {
    id: 663,
    route: "JOE",
    frequency_initial: "9:53 PM",
    frequency_final: "3:17 PM",
  },
  {
    id: 664,
    route: "UZA",
    frequency_initial: "3:27 PM",
    frequency_final: "3:04 PM",
  },
  {
    id: 665,
    route: "MBT",
    frequency_initial: "9:36 PM",
    frequency_final: "2:02 AM",
  },
  {
    id: 666,
    route: "MB",
    frequency_initial: "12:38 AM",
    frequency_final: "9:46 PM",
  },
  {
    id: 667,
    route: "RGNX",
    frequency_initial: "1:45 PM",
    frequency_final: "7:13 PM",
  },
  {
    id: 668,
    route: "NMK^B",
    frequency_initial: "6:44 AM",
    frequency_final: "10:45 AM",
  },
  {
    id: 669,
    route: "SWFT",
    frequency_initial: "6:55 PM",
    frequency_final: "10:17 AM",
  },
  {
    id: 670,
    route: "BMY",
    frequency_initial: "11:47 AM",
    frequency_final: "5:16 PM",
  },
  {
    id: 671,
    route: "CMI",
    frequency_initial: "7:25 AM",
    frequency_final: "9:48 AM",
  },
  {
    id: 672,
    route: "RLOG",
    frequency_initial: "9:07 PM",
    frequency_final: "9:16 AM",
  },
  {
    id: 673,
    route: "ORRF",
    frequency_initial: "8:36 PM",
    frequency_final: "1:01 PM",
  },
  {
    id: 674,
    route: "ASIX",
    frequency_initial: "2:02 AM",
    frequency_final: "7:46 AM",
  },
  {
    id: 675,
    route: "RLJE",
    frequency_initial: "3:48 PM",
    frequency_final: "11:52 AM",
  },
  {
    id: 676,
    route: "HSON",
    frequency_initial: "2:34 PM",
    frequency_final: "12:19 AM",
  },
  {
    id: 677,
    route: "DS^B",
    frequency_initial: "9:22 AM",
    frequency_final: "7:27 PM",
  },
  {
    id: 678,
    route: "KNOP",
    frequency_initial: "6:04 AM",
    frequency_final: "3:42 AM",
  },
  {
    id: 679,
    route: "UFCS",
    frequency_initial: "7:16 AM",
    frequency_final: "12:36 PM",
  },
  {
    id: 680,
    route: "SUI^A",
    frequency_initial: "1:17 PM",
    frequency_final: "7:58 AM",
  },
  {
    id: 681,
    route: "NJR",
    frequency_initial: "11:25 PM",
    frequency_final: "4:22 AM",
  },
  {
    id: 682,
    route: "EGAN",
    frequency_initial: "1:17 PM",
    frequency_final: "11:14 AM",
  },
  {
    id: 683,
    route: "SCL",
    frequency_initial: "1:03 AM",
    frequency_final: "4:54 AM",
  },
  {
    id: 684,
    route: "ETRM",
    frequency_initial: "9:07 AM",
    frequency_final: "2:55 AM",
  },
  {
    id: 685,
    route: "TSQ",
    frequency_initial: "12:46 PM",
    frequency_final: "9:49 PM",
  },
  {
    id: 686,
    route: "EBR.B",
    frequency_initial: "9:39 PM",
    frequency_final: "11:22 PM",
  },
  {
    id: 687,
    route: "ADRD",
    frequency_initial: "1:58 AM",
    frequency_final: "10:47 AM",
  },
  {
    id: 688,
    route: "CDK",
    frequency_initial: "6:34 PM",
    frequency_final: "2:52 AM",
  },
  {
    id: 689,
    route: "BBG",
    frequency_initial: "12:22 AM",
    frequency_final: "4:55 PM",
  },
  {
    id: 690,
    route: "OFLX",
    frequency_initial: "12:25 AM",
    frequency_final: "8:28 PM",
  },
  {
    id: 691,
    route: "FN",
    frequency_initial: "8:15 AM",
    frequency_final: "12:02 PM",
  },
  {
    id: 692,
    route: "RTEC",
    frequency_initial: "9:51 AM",
    frequency_final: "9:05 PM",
  },
  {
    id: 693,
    route: "BELFB",
    frequency_initial: "1:14 AM",
    frequency_final: "9:01 AM",
  },
  {
    id: 694,
    route: "PLAY",
    frequency_initial: "11:32 AM",
    frequency_final: "6:27 PM",
  },
  {
    id: 695,
    route: "LPX",
    frequency_initial: "5:06 PM",
    frequency_final: "6:07 AM",
  },
  {
    id: 696,
    route: "LIVN",
    frequency_initial: "1:46 AM",
    frequency_final: "12:55 AM",
  },
  {
    id: 697,
    route: "KMG",
    frequency_initial: "9:41 AM",
    frequency_final: "2:25 AM",
  },
  {
    id: 698,
    route: "MTD",
    frequency_initial: "4:21 AM",
    frequency_final: "12:25 PM",
  },
  {
    id: 699,
    route: "SSTI",
    frequency_initial: "1:18 AM",
    frequency_final: "2:09 AM",
  },
  {
    id: 700,
    route: "SNDE",
    frequency_initial: "2:40 AM",
    frequency_final: "9:51 AM",
  },
  {
    id: 701,
    route: "ATU",
    frequency_initial: "9:53 AM",
    frequency_final: "6:11 AM",
  },
  {
    id: 702,
    route: "GTYH",
    frequency_initial: "7:39 PM",
    frequency_final: "9:45 AM",
  },
  {
    id: 703,
    route: "GRPN",
    frequency_initial: "8:59 AM",
    frequency_final: "2:06 PM",
  },
  {
    id: 704,
    route: "AXL",
    frequency_initial: "6:27 PM",
    frequency_final: "11:15 AM",
  },
  {
    id: 705,
    route: "BUFF",
    frequency_initial: "1:16 PM",
    frequency_final: "4:06 PM",
  },
  {
    id: 706,
    route: "ARW",
    frequency_initial: "12:05 AM",
    frequency_final: "9:37 AM",
  },
  {
    id: 707,
    route: "SPIL",
    frequency_initial: "3:57 AM",
    frequency_final: "6:36 PM",
  },
  {
    id: 708,
    route: "TAYD",
    frequency_initial: "1:50 PM",
    frequency_final: "3:33 AM",
  },
  {
    id: 709,
    route: "AGN^A",
    frequency_initial: "5:04 AM",
    frequency_final: "9:10 PM",
  },
  {
    id: 710,
    route: "CROX",
    frequency_initial: "7:19 PM",
    frequency_final: "11:39 AM",
  },
  {
    id: 711,
    route: "OHAI",
    frequency_initial: "5:48 AM",
    frequency_final: "4:15 PM",
  },
  {
    id: 712,
    route: "CFO",
    frequency_initial: "5:28 AM",
    frequency_final: "10:03 PM",
  },
  {
    id: 713,
    route: "CHRS",
    frequency_initial: "11:34 AM",
    frequency_final: "2:18 AM",
  },
  {
    id: 714,
    route: "PFS",
    frequency_initial: "12:50 AM",
    frequency_final: "10:48 AM",
  },
  {
    id: 715,
    route: "KS",
    frequency_initial: "4:51 AM",
    frequency_final: "6:11 AM",
  },
  {
    id: 716,
    route: "AGM^C",
    frequency_initial: "8:41 AM",
    frequency_final: "2:39 PM",
  },
  {
    id: 717,
    route: "EVR",
    frequency_initial: "4:35 AM",
    frequency_final: "5:58 PM",
  },
  {
    id: 718,
    route: "KBWP",
    frequency_initial: "10:42 AM",
    frequency_final: "8:19 AM",
  },
  {
    id: 719,
    route: "GIII",
    frequency_initial: "4:20 AM",
    frequency_final: "8:59 AM",
  },
  {
    id: 720,
    route: "AIV^A",
    frequency_initial: "1:28 PM",
    frequency_final: "8:14 PM",
  },
  {
    id: 721,
    route: "TRS",
    frequency_initial: "8:16 AM",
    frequency_final: "8:05 AM",
  },
  {
    id: 722,
    route: "FTR",
    frequency_initial: "6:18 PM",
    frequency_final: "3:17 PM",
  },
  {
    id: 723,
    route: "QYLD",
    frequency_initial: "3:52 PM",
    frequency_final: "9:47 AM",
  },
  {
    id: 724,
    route: "VNDA",
    frequency_initial: "8:52 PM",
    frequency_final: "2:43 PM",
  },
  {
    id: 725,
    route: "BLX",
    frequency_initial: "7:25 PM",
    frequency_final: "3:38 AM",
  },
  {
    id: 726,
    route: "NGHCO",
    frequency_initial: "8:41 PM",
    frequency_final: "11:33 PM",
  },
  {
    id: 727,
    route: "NANO",
    frequency_initial: "3:12 AM",
    frequency_final: "4:40 PM",
  },
  {
    id: 728,
    route: "RGR",
    frequency_initial: "3:53 PM",
    frequency_final: "8:23 AM",
  },
  {
    id: 729,
    route: "CAB",
    frequency_initial: "10:28 PM",
    frequency_final: "12:43 AM",
  },
  {
    id: 730,
    route: "EKSO",
    frequency_initial: "11:03 AM",
    frequency_final: "4:44 PM",
  },
  {
    id: 731,
    route: "SLRC",
    frequency_initial: "12:53 PM",
    frequency_final: "9:27 PM",
  },
  {
    id: 732,
    route: "LND",
    frequency_initial: "1:36 AM",
    frequency_final: "11:16 AM",
  },
  {
    id: 733,
    route: "EROS",
    frequency_initial: "7:22 AM",
    frequency_final: "6:21 PM",
  },
  {
    id: 734,
    route: "BELFA",
    frequency_initial: "6:43 PM",
    frequency_final: "4:01 AM",
  },
  {
    id: 735,
    route: "RIO",
    frequency_initial: "4:57 AM",
    frequency_final: "9:48 PM",
  },
  {
    id: 736,
    route: "EGY",
    frequency_initial: "11:02 AM",
    frequency_final: "12:06 AM",
  },
  {
    id: 737,
    route: "RDVY",
    frequency_initial: "6:57 PM",
    frequency_final: "4:47 PM",
  },
  {
    id: 738,
    route: "GYB",
    frequency_initial: "3:26 PM",
    frequency_final: "11:28 AM",
  },
  {
    id: 739,
    route: "JNPR",
    frequency_initial: "10:38 AM",
    frequency_final: "9:46 AM",
  },
  {
    id: 740,
    route: "CLCT",
    frequency_initial: "10:53 PM",
    frequency_final: "12:41 AM",
  },
  {
    id: 741,
    route: "QLC",
    frequency_initial: "12:18 PM",
    frequency_final: "10:23 PM",
  },
  {
    id: 742,
    route: "PTH",
    frequency_initial: "1:15 AM",
    frequency_final: "12:39 AM",
  },
  {
    id: 743,
    route: "HEI.A",
    frequency_initial: "1:02 AM",
    frequency_final: "9:39 AM",
  },
  {
    id: 744,
    route: "PFN",
    frequency_initial: "10:47 PM",
    frequency_final: "1:45 PM",
  },
  {
    id: 745,
    route: "KST",
    frequency_initial: "9:20 AM",
    frequency_final: "6:03 PM",
  },
  {
    id: 746,
    route: "TRU",
    frequency_initial: "2:10 PM",
    frequency_final: "6:19 PM",
  },
  {
    id: 747,
    route: "INDY",
    frequency_initial: "10:34 AM",
    frequency_final: "6:47 AM",
  },
  {
    id: 748,
    route: "TTC",
    frequency_initial: "5:10 PM",
    frequency_final: "12:18 AM",
  },
  {
    id: 749,
    route: "SOFO",
    frequency_initial: "12:56 PM",
    frequency_final: "5:14 PM",
  },
  {
    id: 750,
    route: "ARDX",
    frequency_initial: "4:52 AM",
    frequency_final: "12:34 AM",
  },
  {
    id: 751,
    route: "FSBK",
    frequency_initial: "3:45 PM",
    frequency_final: "1:54 PM",
  },
  {
    id: 752,
    route: "APH",
    frequency_initial: "9:58 AM",
    frequency_final: "5:20 AM",
  },
  {
    id: 753,
    route: "UFPI",
    frequency_initial: "1:31 AM",
    frequency_final: "5:34 PM",
  },
  {
    id: 754,
    route: "XNCR",
    frequency_initial: "8:16 PM",
    frequency_final: "4:18 AM",
  },
  {
    id: 755,
    route: "ALLY^A",
    frequency_initial: "3:33 PM",
    frequency_final: "3:01 AM",
  },
  {
    id: 756,
    route: "AMTD",
    frequency_initial: "10:09 PM",
    frequency_final: "11:15 PM",
  },
  {
    id: 757,
    route: "VYMI",
    frequency_initial: "2:43 PM",
    frequency_final: "7:34 AM",
  },
  {
    id: 758,
    route: "CELH",
    frequency_initial: "6:03 PM",
    frequency_final: "7:47 AM",
  },
  {
    id: 759,
    route: "BHK",
    frequency_initial: "6:34 AM",
    frequency_final: "8:56 AM",
  },
  {
    id: 760,
    route: "BELFA",
    frequency_initial: "12:02 AM",
    frequency_final: "8:29 AM",
  },
  {
    id: 761,
    route: "SWFT",
    frequency_initial: "3:15 PM",
    frequency_final: "2:23 PM",
  },
  {
    id: 762,
    route: "VIA",
    frequency_initial: "2:05 AM",
    frequency_final: "11:22 PM",
  },
  {
    id: 763,
    route: "TNP^D",
    frequency_initial: "7:32 AM",
    frequency_final: "11:00 PM",
  },
  {
    id: 764,
    route: "USDP",
    frequency_initial: "4:34 PM",
    frequency_final: "11:20 AM",
  },
  {
    id: 765,
    route: "REXR^A",
    frequency_initial: "9:44 PM",
    frequency_final: "6:39 PM",
  },
  {
    id: 766,
    route: "CRL",
    frequency_initial: "4:20 PM",
    frequency_final: "6:48 PM",
  },
  {
    id: 767,
    route: "CLW",
    frequency_initial: "2:52 PM",
    frequency_final: "9:54 AM",
  },
  {
    id: 768,
    route: "NNN^F",
    frequency_initial: "8:56 PM",
    frequency_final: "4:24 PM",
  },
  {
    id: 769,
    route: "ESV",
    frequency_initial: "9:34 PM",
    frequency_final: "12:31 AM",
  },
  {
    id: 770,
    route: "TV",
    frequency_initial: "7:58 AM",
    frequency_final: "1:43 AM",
  },
  {
    id: 771,
    route: "RP",
    frequency_initial: "5:51 PM",
    frequency_final: "11:58 PM",
  },
  {
    id: 772,
    route: "CIO",
    frequency_initial: "2:25 PM",
    frequency_final: "2:19 PM",
  },
  {
    id: 773,
    route: "PBPB",
    frequency_initial: "3:01 PM",
    frequency_final: "12:40 AM",
  },
  {
    id: 774,
    route: "AVH",
    frequency_initial: "4:49 AM",
    frequency_final: "6:05 AM",
  },
  {
    id: 775,
    route: "MUH",
    frequency_initial: "7:16 PM",
    frequency_final: "8:50 PM",
  },
  {
    id: 776,
    route: "PEIX",
    frequency_initial: "8:59 PM",
    frequency_final: "10:27 AM",
  },
  {
    id: 777,
    route: "FEMB",
    frequency_initial: "8:02 PM",
    frequency_final: "8:27 PM",
  },
  {
    id: 778,
    route: "HXL",
    frequency_initial: "4:19 PM",
    frequency_final: "2:35 AM",
  },
  {
    id: 779,
    route: "RATE",
    frequency_initial: "12:24 AM",
    frequency_final: "8:25 AM",
  },
  {
    id: 780,
    route: "ETB",
    frequency_initial: "7:58 PM",
    frequency_final: "9:46 PM",
  },
  {
    id: 781,
    route: "ZIONW",
    frequency_initial: "3:53 PM",
    frequency_final: "3:52 PM",
  },
  {
    id: 782,
    route: "OHAI",
    frequency_initial: "12:19 AM",
    frequency_final: "3:23 AM",
  },
  {
    id: 783,
    route: "QQQC",
    frequency_initial: "2:15 PM",
    frequency_final: "3:17 AM",
  },
  {
    id: 784,
    route: "CLI",
    frequency_initial: "6:08 PM",
    frequency_final: "2:23 AM",
  },
  {
    id: 785,
    route: "PXLW",
    frequency_initial: "5:46 PM",
    frequency_final: "12:13 AM",
  },
  {
    id: 786,
    route: "EVOK",
    frequency_initial: "10:56 PM",
    frequency_final: "2:40 AM",
  },
  {
    id: 787,
    route: "MSA",
    frequency_initial: "5:05 AM",
    frequency_final: "7:31 AM",
  },
  {
    id: 788,
    route: "TILE",
    frequency_initial: "9:52 AM",
    frequency_final: "3:23 AM",
  },
  {
    id: 789,
    route: "MUJ",
    frequency_initial: "10:16 PM",
    frequency_final: "2:50 AM",
  },
  {
    id: 790,
    route: "HSKA",
    frequency_initial: "4:44 PM",
    frequency_final: "7:19 PM",
  },
  {
    id: 791,
    route: "SYK",
    frequency_initial: "12:29 AM",
    frequency_final: "8:26 AM",
  },
  {
    id: 792,
    route: "CPAA",
    frequency_initial: "1:03 PM",
    frequency_final: "9:57 PM",
  },
  {
    id: 793,
    route: "CLW",
    frequency_initial: "1:24 PM",
    frequency_final: "11:14 AM",
  },
  {
    id: 794,
    route: "TCO^K",
    frequency_initial: "4:09 PM",
    frequency_final: "12:00 AM",
  },
  {
    id: 795,
    route: "TRMR",
    frequency_initial: "1:06 AM",
    frequency_final: "8:44 AM",
  },
  {
    id: 796,
    route: "CAPR",
    frequency_initial: "4:37 AM",
    frequency_final: "9:06 PM",
  },
  {
    id: 797,
    route: "ES",
    frequency_initial: "8:30 AM",
    frequency_final: "11:55 PM",
  },
  {
    id: 798,
    route: "KALU",
    frequency_initial: "10:22 PM",
    frequency_final: "4:07 AM",
  },
  {
    id: 799,
    route: "TIME",
    frequency_initial: "8:50 AM",
    frequency_final: "3:28 AM",
  },
  {
    id: 800,
    route: "JLS",
    frequency_initial: "1:36 PM",
    frequency_final: "12:08 AM",
  },
  {
    id: 801,
    route: "BMS",
    frequency_initial: "3:55 AM",
    frequency_final: "12:26 PM",
  },
  {
    id: 802,
    route: "NESR",
    frequency_initial: "1:53 AM",
    frequency_final: "1:50 PM",
  },
  {
    id: 803,
    route: "CHMG",
    frequency_initial: "12:15 PM",
    frequency_final: "6:47 AM",
  },
  {
    id: 804,
    route: "CCN",
    frequency_initial: "10:13 PM",
    frequency_final: "4:23 PM",
  },
  {
    id: 805,
    route: "GATX",
    frequency_initial: "9:23 PM",
    frequency_final: "9:18 AM",
  },
  {
    id: 806,
    route: "SOXX",
    frequency_initial: "9:30 PM",
    frequency_final: "9:35 PM",
  },
  {
    id: 807,
    route: "BSE",
    frequency_initial: "1:30 AM",
    frequency_final: "8:13 PM",
  },
  {
    id: 808,
    route: "DHI",
    frequency_initial: "10:39 PM",
    frequency_final: "7:39 PM",
  },
  {
    id: 809,
    route: "NYRT",
    frequency_initial: "1:45 PM",
    frequency_final: "10:08 AM",
  },
  {
    id: 810,
    route: "SQBG",
    frequency_initial: "12:07 PM",
    frequency_final: "7:26 PM",
  },
  {
    id: 811,
    route: "MGP",
    frequency_initial: "5:46 AM",
    frequency_final: "2:35 PM",
  },
  {
    id: 812,
    route: "KAP",
    frequency_initial: "1:47 AM",
    frequency_final: "4:31 PM",
  },
  {
    id: 813,
    route: "TKC",
    frequency_initial: "11:11 AM",
    frequency_final: "7:46 PM",
  },
  {
    id: 814,
    route: "COT",
    frequency_initial: "8:51 PM",
    frequency_final: "3:15 PM",
  },
  {
    id: 815,
    route: "ATLO",
    frequency_initial: "3:02 AM",
    frequency_final: "9:43 PM",
  },
  {
    id: 816,
    route: "GLT",
    frequency_initial: "3:02 AM",
    frequency_final: "8:00 AM",
  },
  {
    id: 817,
    route: "POLA",
    frequency_initial: "3:05 PM",
    frequency_final: "2:05 AM",
  },
  {
    id: 818,
    route: "FSC",
    frequency_initial: "2:34 PM",
    frequency_final: "9:45 AM",
  },
  {
    id: 819,
    route: "KBWY",
    frequency_initial: "12:09 PM",
    frequency_final: "12:44 PM",
  },
  {
    id: 820,
    route: "FWP",
    frequency_initial: "10:25 PM",
    frequency_final: "7:25 PM",
  },
  {
    id: 821,
    route: "FNSR",
    frequency_initial: "6:42 AM",
    frequency_final: "8:34 AM",
  },
  {
    id: 822,
    route: "FEYE",
    frequency_initial: "6:46 AM",
    frequency_final: "3:32 PM",
  },
  {
    id: 823,
    route: "NBD",
    frequency_initial: "8:18 AM",
    frequency_final: "11:49 AM",
  },
  {
    id: 824,
    route: "ETP",
    frequency_initial: "9:30 AM",
    frequency_final: "11:44 PM",
  },
  {
    id: 825,
    route: "EAGLW",
    frequency_initial: "12:42 PM",
    frequency_final: "2:19 AM",
  },
  {
    id: 826,
    route: "PCMI",
    frequency_initial: "5:04 AM",
    frequency_final: "2:56 AM",
  },
  {
    id: 827,
    route: "ZEUS",
    frequency_initial: "12:01 PM",
    frequency_final: "3:10 PM",
  },
  {
    id: 828,
    route: "DEX",
    frequency_initial: "9:27 PM",
    frequency_final: "5:42 PM",
  },
  {
    id: 829,
    route: "ORBC",
    frequency_initial: "11:31 PM",
    frequency_final: "5:17 AM",
  },
  {
    id: 830,
    route: "GFA",
    frequency_initial: "1:31 AM",
    frequency_final: "12:45 PM",
  },
  {
    id: 831,
    route: "WHFBL",
    frequency_initial: "2:32 AM",
    frequency_final: "9:16 PM",
  },
  {
    id: 832,
    route: "GWRE",
    frequency_initial: "3:25 PM",
    frequency_final: "8:49 AM",
  },
  {
    id: 833,
    route: "STFC",
    frequency_initial: "5:04 PM",
    frequency_final: "8:48 AM",
  },
  {
    id: 834,
    route: "CCK",
    frequency_initial: "2:51 PM",
    frequency_final: "3:06 AM",
  },
  {
    id: 835,
    route: "ELC",
    frequency_initial: "12:41 AM",
    frequency_final: "7:23 PM",
  },
  {
    id: 836,
    route: "XPO",
    frequency_initial: "5:10 PM",
    frequency_final: "10:32 PM",
  },
  {
    id: 837,
    route: "RBS^F",
    frequency_initial: "3:00 PM",
    frequency_final: "4:22 AM",
  },
  {
    id: 838,
    route: "NEE^Q",
    frequency_initial: "7:52 AM",
    frequency_final: "5:29 AM",
  },
  {
    id: 839,
    route: "EVT",
    frequency_initial: "2:29 PM",
    frequency_final: "12:43 AM",
  },
  {
    id: 840,
    route: "RLI",
    frequency_initial: "4:35 PM",
    frequency_final: "12:55 PM",
  },
  {
    id: 841,
    route: "IMED",
    frequency_initial: "12:43 PM",
    frequency_final: "9:17 AM",
  },
  {
    id: 842,
    route: "BITA",
    frequency_initial: "3:16 AM",
    frequency_final: "8:24 AM",
  },
  {
    id: 843,
    route: "UBSI",
    frequency_initial: "7:00 AM",
    frequency_final: "9:34 AM",
  },
  {
    id: 844,
    route: "THW",
    frequency_initial: "4:45 AM",
    frequency_final: "2:05 AM",
  },
  {
    id: 845,
    route: "AXTA",
    frequency_initial: "1:14 PM",
    frequency_final: "12:58 PM",
  },
  {
    id: 846,
    route: "PUMP",
    frequency_initial: "10:56 PM",
    frequency_final: "11:53 AM",
  },
  {
    id: 847,
    route: "FOXF",
    frequency_initial: "10:42 PM",
    frequency_final: "12:26 AM",
  },
  {
    id: 848,
    route: "APVO",
    frequency_initial: "12:47 AM",
    frequency_final: "4:38 AM",
  },
  {
    id: 849,
    route: "LSI",
    frequency_initial: "2:35 AM",
    frequency_final: "9:14 AM",
  },
  {
    id: 850,
    route: "GPACW",
    frequency_initial: "12:52 PM",
    frequency_final: "8:49 PM",
  },
  {
    id: 851,
    route: "GS",
    frequency_initial: "6:00 PM",
    frequency_final: "9:44 AM",
  },
  {
    id: 852,
    route: "LIVN",
    frequency_initial: "11:05 AM",
    frequency_final: "8:35 PM",
  },
  {
    id: 853,
    route: "DHG",
    frequency_initial: "1:40 PM",
    frequency_final: "4:55 PM",
  },
  {
    id: 854,
    route: "MPAA",
    frequency_initial: "10:42 AM",
    frequency_final: "12:55 PM",
  },
  {
    id: 855,
    route: "OASM",
    frequency_initial: "10:53 AM",
    frequency_final: "9:11 PM",
  },
  {
    id: 856,
    route: "PMT^A",
    frequency_initial: "5:36 AM",
    frequency_final: "9:21 AM",
  },
  {
    id: 857,
    route: "NYMTP",
    frequency_initial: "9:07 AM",
    frequency_final: "11:24 PM",
  },
  {
    id: 858,
    route: "CHSCL",
    frequency_initial: "3:33 PM",
    frequency_final: "9:17 AM",
  },
  {
    id: 859,
    route: "SAGE",
    frequency_initial: "11:18 PM",
    frequency_final: "5:07 AM",
  },
  {
    id: 860,
    route: "VRTS",
    frequency_initial: "12:28 PM",
    frequency_final: "10:34 AM",
  },
  {
    id: 861,
    route: "AXGN",
    frequency_initial: "10:20 PM",
    frequency_final: "11:34 AM",
  },
  {
    id: 862,
    route: "ATH",
    frequency_initial: "11:13 AM",
    frequency_final: "12:16 AM",
  },
  {
    id: 863,
    route: "VER",
    frequency_initial: "5:39 AM",
    frequency_final: "2:55 AM",
  },
  {
    id: 864,
    route: "SAFT",
    frequency_initial: "7:24 PM",
    frequency_final: "10:11 AM",
  },
  {
    id: 865,
    route: "WFC^P",
    frequency_initial: "12:25 PM",
    frequency_final: "12:59 PM",
  },
  {
    id: 866,
    route: "ALE",
    frequency_initial: "6:53 PM",
    frequency_final: "10:20 PM",
  },
  {
    id: 867,
    route: "BWLD",
    frequency_initial: "12:19 AM",
    frequency_final: "10:30 AM",
  },
  {
    id: 868,
    route: "DTF",
    frequency_initial: "5:22 AM",
    frequency_final: "11:26 PM",
  },
  {
    id: 869,
    route: "YECO",
    frequency_initial: "6:33 AM",
    frequency_final: "10:19 PM",
  },
  {
    id: 870,
    route: "PZN",
    frequency_initial: "11:53 PM",
    frequency_final: "3:46 PM",
  },
  {
    id: 871,
    route: "HRZN",
    frequency_initial: "5:54 PM",
    frequency_final: "5:35 AM",
  },
  {
    id: 872,
    route: "PSCD",
    frequency_initial: "12:36 PM",
    frequency_final: "5:51 AM",
  },
  {
    id: 873,
    route: "SALM",
    frequency_initial: "8:30 AM",
    frequency_final: "4:28 AM",
  },
  {
    id: 874,
    route: "FINL",
    frequency_initial: "12:47 AM",
    frequency_final: "4:41 AM",
  },
  {
    id: 875,
    route: "URRE",
    frequency_initial: "4:52 AM",
    frequency_final: "8:56 PM",
  },
  {
    id: 876,
    route: "ABIO",
    frequency_initial: "11:23 PM",
    frequency_final: "6:48 AM",
  },
  {
    id: 877,
    route: "AJXA",
    frequency_initial: "4:12 PM",
    frequency_final: "3:50 AM",
  },
  {
    id: 878,
    route: "CHS",
    frequency_initial: "3:49 PM",
    frequency_final: "8:41 PM",
  },
  {
    id: 879,
    route: "IGA",
    frequency_initial: "7:14 PM",
    frequency_final: "1:40 AM",
  },
  {
    id: 880,
    route: "WPXP",
    frequency_initial: "6:48 AM",
    frequency_final: "8:59 AM",
  },
  {
    id: 881,
    route: "ROP",
    frequency_initial: "10:01 AM",
    frequency_final: "11:07 PM",
  },
  {
    id: 882,
    route: "BKK",
    frequency_initial: "6:41 PM",
    frequency_final: "3:50 PM",
  },
  {
    id: 883,
    route: "RECN",
    frequency_initial: "6:08 PM",
    frequency_final: "8:54 PM",
  },
  {
    id: 884,
    route: "EURN",
    frequency_initial: "10:20 AM",
    frequency_final: "5:50 PM",
  },
  {
    id: 885,
    route: "VSI",
    frequency_initial: "10:54 PM",
    frequency_final: "2:04 PM",
  },
  {
    id: 886,
    route: "TKC",
    frequency_initial: "5:47 PM",
    frequency_final: "4:34 AM",
  },
  {
    id: 887,
    route: "QLC",
    frequency_initial: "3:44 PM",
    frequency_final: "9:30 AM",
  },
  {
    id: 888,
    route: "SHG",
    frequency_initial: "6:17 PM",
    frequency_final: "6:42 AM",
  },
  {
    id: 889,
    route: "CAMP",
    frequency_initial: "2:08 AM",
    frequency_final: "8:20 PM",
  },
  {
    id: 890,
    route: "BIIB",
    frequency_initial: "12:11 AM",
    frequency_final: "12:12 PM",
  },
  {
    id: 891,
    route: "NWE",
    frequency_initial: "2:19 AM",
    frequency_final: "8:39 AM",
  },
  {
    id: 892,
    route: "UBOH",
    frequency_initial: "3:06 PM",
    frequency_final: "6:22 PM",
  },
  {
    id: 893,
    route: "BAP",
    frequency_initial: "10:48 PM",
    frequency_final: "10:55 AM",
  },
  {
    id: 894,
    route: "UDBI",
    frequency_initial: "2:17 PM",
    frequency_final: "1:00 AM",
  },
  {
    id: 895,
    route: "VGSH",
    frequency_initial: "5:55 AM",
    frequency_final: "10:10 PM",
  },
  {
    id: 896,
    route: "TEO",
    frequency_initial: "9:59 PM",
    frequency_final: "1:26 PM",
  },
  {
    id: 897,
    route: "CAH",
    frequency_initial: "5:39 PM",
    frequency_final: "5:30 AM",
  },
  {
    id: 898,
    route: "EQBK",
    frequency_initial: "8:41 AM",
    frequency_final: "9:27 PM",
  },
  {
    id: 899,
    route: "CDK",
    frequency_initial: "5:07 AM",
    frequency_final: "4:33 AM",
  },
  {
    id: 900,
    route: "RF",
    frequency_initial: "2:49 PM",
    frequency_final: "9:10 PM",
  },
  {
    id: 901,
    route: "RTEC",
    frequency_initial: "1:54 AM",
    frequency_final: "1:55 PM",
  },
  {
    id: 902,
    route: "NXTD",
    frequency_initial: "5:32 AM",
    frequency_final: "3:48 AM",
  },
  {
    id: 903,
    route: "CNCR",
    frequency_initial: "11:05 AM",
    frequency_final: "3:04 AM",
  },
  {
    id: 904,
    route: "VVV",
    frequency_initial: "10:48 AM",
    frequency_final: "12:14 PM",
  },
  {
    id: 905,
    route: "DSM",
    frequency_initial: "10:48 PM",
    frequency_final: "3:56 PM",
  },
  {
    id: 906,
    route: "HABT",
    frequency_initial: "9:58 PM",
    frequency_final: "2:30 AM",
  },
  {
    id: 907,
    route: "NLY",
    frequency_initial: "2:37 AM",
    frequency_final: "5:26 AM",
  },
  {
    id: 908,
    route: "CNS",
    frequency_initial: "10:23 PM",
    frequency_final: "12:44 AM",
  },
  {
    id: 909,
    route: "IRM",
    frequency_initial: "6:07 PM",
    frequency_final: "3:06 AM",
  },
  {
    id: 910,
    route: "DXB",
    frequency_initial: "8:38 PM",
    frequency_final: "5:45 PM",
  },
  {
    id: 911,
    route: "CASC",
    frequency_initial: "9:29 AM",
    frequency_final: "3:03 AM",
  },
  {
    id: 912,
    route: "NRE",
    frequency_initial: "9:44 AM",
    frequency_final: "10:43 AM",
  },
  {
    id: 913,
    route: "MNP",
    frequency_initial: "2:21 AM",
    frequency_final: "5:14 PM",
  },
  {
    id: 914,
    route: "TDA",
    frequency_initial: "11:50 PM",
    frequency_final: "2:32 AM",
  },
  {
    id: 915,
    route: "TGT",
    frequency_initial: "6:34 PM",
    frequency_final: "11:46 AM",
  },
  {
    id: 916,
    route: "WBC",
    frequency_initial: "11:34 AM",
    frequency_final: "7:24 AM",
  },
  {
    id: 917,
    route: "YECO",
    frequency_initial: "10:44 AM",
    frequency_final: "1:01 PM",
  },
  {
    id: 918,
    route: "BWINA",
    frequency_initial: "5:19 PM",
    frequency_final: "4:16 PM",
  },
  {
    id: 919,
    route: "TTC",
    frequency_initial: "1:15 PM",
    frequency_final: "6:10 PM",
  },
  {
    id: 920,
    route: "YHOO",
    frequency_initial: "2:29 PM",
    frequency_final: "6:16 AM",
  },
  {
    id: 921,
    route: "OXLC",
    frequency_initial: "2:19 PM",
    frequency_final: "3:43 PM",
  },
  {
    id: 922,
    route: "QADA",
    frequency_initial: "8:36 AM",
    frequency_final: "4:09 PM",
  },
  {
    id: 923,
    route: "TCBIP",
    frequency_initial: "10:42 AM",
    frequency_final: "1:09 PM",
  },
  {
    id: 924,
    route: "VIVE",
    frequency_initial: "8:25 AM",
    frequency_final: "7:26 AM",
  },
  {
    id: 925,
    route: "FNB",
    frequency_initial: "11:53 AM",
    frequency_final: "12:54 PM",
  },
  {
    id: 926,
    route: "PRMW",
    frequency_initial: "3:30 AM",
    frequency_final: "6:38 AM",
  },
  {
    id: 927,
    route: "AJRD",
    frequency_initial: "5:28 AM",
    frequency_final: "3:52 PM",
  },
  {
    id: 928,
    route: "YHOO",
    frequency_initial: "10:02 PM",
    frequency_final: "6:24 AM",
  },
  {
    id: 929,
    route: "DCUD",
    frequency_initial: "3:34 AM",
    frequency_final: "12:30 AM",
  },
  {
    id: 930,
    route: "EE",
    frequency_initial: "7:25 AM",
    frequency_final: "3:22 AM",
  },
  {
    id: 931,
    route: "CAC",
    frequency_initial: "10:08 AM",
    frequency_final: "2:03 AM",
  },
  {
    id: 932,
    route: "GRAM",
    frequency_initial: "5:49 PM",
    frequency_final: "7:30 PM",
  },
  {
    id: 933,
    route: "CLRO",
    frequency_initial: "5:20 PM",
    frequency_final: "11:16 AM",
  },
  {
    id: 934,
    route: "QLC",
    frequency_initial: "4:56 AM",
    frequency_final: "1:06 AM",
  },
  {
    id: 935,
    route: "MLHR",
    frequency_initial: "10:36 AM",
    frequency_final: "11:29 PM",
  },
  {
    id: 936,
    route: "SHG",
    frequency_initial: "12:50 PM",
    frequency_final: "10:47 PM",
  },
  {
    id: 937,
    route: "CZFC",
    frequency_initial: "8:45 AM",
    frequency_final: "9:53 PM",
  },
  {
    id: 938,
    route: "LTRX",
    frequency_initial: "2:50 PM",
    frequency_final: "10:46 PM",
  },
  {
    id: 939,
    route: "BATRK",
    frequency_initial: "3:57 AM",
    frequency_final: "6:36 PM",
  },
  {
    id: 940,
    route: "VMBS",
    frequency_initial: "8:23 AM",
    frequency_final: "8:00 AM",
  },
  {
    id: 941,
    route: "BOXC",
    frequency_initial: "4:19 PM",
    frequency_final: "5:45 PM",
  },
  {
    id: 942,
    route: "SGLB",
    frequency_initial: "7:38 PM",
    frequency_final: "2:46 AM",
  },
  {
    id: 943,
    route: "BIT",
    frequency_initial: "11:11 AM",
    frequency_final: "5:04 AM",
  },
  {
    id: 944,
    route: "WCC",
    frequency_initial: "2:40 AM",
    frequency_final: "12:01 AM",
  },
  {
    id: 945,
    route: "XOMA",
    frequency_initial: "12:37 PM",
    frequency_final: "1:34 AM",
  },
  {
    id: 946,
    route: "NDLS",
    frequency_initial: "9:58 AM",
    frequency_final: "1:18 AM",
  },
  {
    id: 947,
    route: "BBGI",
    frequency_initial: "3:24 PM",
    frequency_final: "5:35 AM",
  },
  {
    id: 948,
    route: "SPE",
    frequency_initial: "2:59 PM",
    frequency_final: "7:27 AM",
  },
  {
    id: 949,
    route: "AMZN",
    frequency_initial: "12:50 AM",
    frequency_final: "12:56 PM",
  },
  {
    id: 950,
    route: "IPGP",
    frequency_initial: "8:45 PM",
    frequency_final: "11:22 AM",
  },
  {
    id: 951,
    route: "PLW",
    frequency_initial: "3:17 AM",
    frequency_final: "6:44 PM",
  },
  {
    id: 952,
    route: "NMZ",
    frequency_initial: "6:19 PM",
    frequency_final: "11:16 AM",
  },
  {
    id: 953,
    route: "KANG",
    frequency_initial: "2:07 AM",
    frequency_final: "6:53 PM",
  },
  {
    id: 954,
    route: "NXPI",
    frequency_initial: "10:30 PM",
    frequency_final: "2:34 PM",
  },
  {
    id: 955,
    route: "TTEC",
    frequency_initial: "8:59 AM",
    frequency_final: "9:03 AM",
  },
  {
    id: 956,
    route: "ARA",
    frequency_initial: "9:16 PM",
    frequency_final: "9:06 AM",
  },
  {
    id: 957,
    route: "QUAD",
    frequency_initial: "4:38 AM",
    frequency_final: "5:37 AM",
  },
  {
    id: 958,
    route: "EVSTC",
    frequency_initial: "9:44 PM",
    frequency_final: "1:28 PM",
  },
  {
    id: 959,
    route: "OPXAW",
    frequency_initial: "10:24 AM",
    frequency_final: "5:29 PM",
  },
  {
    id: 960,
    route: "NTCT",
    frequency_initial: "10:09 AM",
    frequency_final: "5:55 AM",
  },
  {
    id: 961,
    route: "OMC",
    frequency_initial: "5:47 AM",
    frequency_final: "8:59 AM",
  },
  {
    id: 962,
    route: "PTHN",
    frequency_initial: "10:07 AM",
    frequency_final: "9:38 AM",
  },
  {
    id: 963,
    route: "ORBK",
    frequency_initial: "4:14 AM",
    frequency_final: "6:30 PM",
  },
  {
    id: 964,
    route: "SIVB",
    frequency_initial: "8:41 AM",
    frequency_final: "6:38 PM",
  },
  {
    id: 965,
    route: "NSTG",
    frequency_initial: "7:30 PM",
    frequency_final: "5:34 PM",
  },
  {
    id: 966,
    route: "FTXL",
    frequency_initial: "2:56 AM",
    frequency_final: "3:39 AM",
  },
  {
    id: 967,
    route: "GCP",
    frequency_initial: "2:47 AM",
    frequency_final: "9:22 PM",
  },
  {
    id: 968,
    route: "SCSC",
    frequency_initial: "11:25 AM",
    frequency_final: "6:28 PM",
  },
  {
    id: 969,
    route: "WBT",
    frequency_initial: "10:36 AM",
    frequency_final: "6:20 AM",
  },
  {
    id: 970,
    route: "FLDM",
    frequency_initial: "9:51 AM",
    frequency_final: "8:56 AM",
  },
  {
    id: 971,
    route: "LAMR",
    frequency_initial: "3:37 PM",
    frequency_final: "7:50 AM",
  },
  {
    id: 972,
    route: "PDM",
    frequency_initial: "2:55 AM",
    frequency_final: "6:02 PM",
  },
  {
    id: 973,
    route: "CVBF",
    frequency_initial: "8:10 AM",
    frequency_final: "10:56 PM",
  },
  {
    id: 974,
    route: "SCZ",
    frequency_initial: "1:09 PM",
    frequency_final: "10:53 AM",
  },
  {
    id: 975,
    route: "FUL",
    frequency_initial: "2:30 AM",
    frequency_final: "10:18 AM",
  },
  {
    id: 976,
    route: "BSF",
    frequency_initial: "3:20 PM",
    frequency_final: "11:03 AM",
  },
  {
    id: 977,
    route: "SFUN",
    frequency_initial: "11:20 PM",
    frequency_final: "8:51 PM",
  },
  {
    id: 978,
    route: "EQBK",
    frequency_initial: "9:50 PM",
    frequency_final: "4:29 AM",
  },
  {
    id: 979,
    route: "NDSN",
    frequency_initial: "11:06 PM",
    frequency_final: "4:59 PM",
  },
  {
    id: 980,
    route: "YY",
    frequency_initial: "3:00 AM",
    frequency_final: "12:45 AM",
  },
  {
    id: 981,
    route: "CLRBZ",
    frequency_initial: "7:40 AM",
    frequency_final: "9:07 PM",
  },
  {
    id: 982,
    route: "E",
    frequency_initial: "6:51 PM",
    frequency_final: "7:49 PM",
  },
  {
    id: 983,
    route: "TTI",
    frequency_initial: "8:39 PM",
    frequency_final: "12:52 PM",
  },
  {
    id: 984,
    route: "SCHW",
    frequency_initial: "12:40 AM",
    frequency_final: "3:18 AM",
  },
  {
    id: 985,
    route: "FTS",
    frequency_initial: "1:57 PM",
    frequency_final: "8:51 PM",
  },
  {
    id: 986,
    route: "SKYS",
    frequency_initial: "9:53 AM",
    frequency_final: "1:57 AM",
  },
  {
    id: 987,
    route: "BBW",
    frequency_initial: "4:19 PM",
    frequency_final: "9:28 AM",
  },
  {
    id: 988,
    route: "UNXL",
    frequency_initial: "12:37 PM",
    frequency_final: "10:15 AM",
  },
  {
    id: 989,
    route: "HVT.A",
    frequency_initial: "3:13 PM",
    frequency_final: "6:52 AM",
  },
  {
    id: 990,
    route: "CNCE",
    frequency_initial: "10:44 PM",
    frequency_final: "8:42 AM",
  },
  {
    id: 991,
    route: "ENO",
    frequency_initial: "11:38 AM",
    frequency_final: "4:31 PM",
  },
  {
    id: 992,
    route: "CLDR",
    frequency_initial: "7:58 PM",
    frequency_final: "12:39 PM",
  },
  {
    id: 993,
    route: "ECYT",
    frequency_initial: "3:06 AM",
    frequency_final: "2:41 PM",
  },
  {
    id: 994,
    route: "CLNS^C",
    frequency_initial: "5:41 AM",
    frequency_final: "1:00 PM",
  },
  {
    id: 995,
    route: "WST",
    frequency_initial: "6:09 PM",
    frequency_final: "2:49 PM",
  },
  {
    id: 996,
    route: "RMAX",
    frequency_initial: "2:28 PM",
    frequency_final: "9:34 AM",
  },
  {
    id: 997,
    route: "GXP",
    frequency_initial: "11:34 AM",
    frequency_final: "2:22 AM",
  },
  {
    id: 998,
    route: "IHG",
    frequency_initial: "7:14 AM",
    frequency_final: "8:00 AM",
  },
  {
    id: 999,
    route: "PJC",
    frequency_initial: "11:29 PM",
    frequency_final: "12:02 AM",
  },
  {
    id: 1000,
    route: "TUP",
    frequency_initial: "11:49 AM",
    frequency_final: "9:31 AM",
  },
];