import { useState } from "react";
import {
  SidebarHeader,
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarFooter,
} from "react-pro-sidebar";
import "./sidebar.scss";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faChartPie } from "@fortawesome/free-solid-svg-icons";
import { faMapMarked } from "@fortawesome/free-solid-svg-icons";
import { faDatabase } from "@fortawesome/free-solid-svg-icons";
import { faPollH, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { logout } from "../redux/slice/UserSlice";
import { Logout } from "../api/login/logout";
import { useAppDispatch, useAppSelector } from "../redux/hooks";

const SideBar = () => {
  const [collapsed, setCollapsed] = useState(true);
  const User = useAppSelector((state: any) => state.User);
  const handleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const dispatch = useAppDispatch();

  const handleLogoutButton = () => {
    Logout(User.refreshToken);
    dispatch(logout());
  };

  return (
    <ProSidebar collapsed={collapsed}>
      <SidebarHeader>
        <div
          onClick={handleCollapsed}
          style={{
            padding: "24px",
            cursor: "pointer",
          }}
        >
          <FontAwesomeIcon icon={faBars} size="2x" />
        </div>
      </SidebarHeader>
      <Menu iconShape="square" popperArrow>
        <MenuItem icon={<FontAwesomeIcon icon={faMapMarked} />}>
          Map View
          <Link to="/user/map" />
        </MenuItem>
        {User.userRole === "admin" && (
          <SubMenu title="CRUD" icon={<FontAwesomeIcon icon={faPollH} />}>
            <MenuItem icon={<FontAwesomeIcon icon={faDatabase} />}>
              PIS Master Data
              <Link to="/user/master-data" />
            </MenuItem>
            <MenuItem icon={<FontAwesomeIcon icon={faDatabase} />}>
              RFID Master Data
              <Link to="/user/rfid-master-data" />
            </MenuItem>
          </SubMenu>
        )}
      </Menu>
      <SidebarFooter>
        <Menu onClick={() => handleLogoutButton()}>
          <MenuItem icon={<FontAwesomeIcon icon={faSignOutAlt} />}>
            Logout
          </MenuItem>
        </Menu>
      </SidebarFooter>
    </ProSidebar>
  );
};

export default SideBar;
