import React from "react";
import PISMaps from "../components/PISMaps";
import styles from "./MapView.module.css";
import SideBar from "../components/SideBar";

function MapView() {
  return (
    <div className="flex flex-row overflow-hidden">
      <div className="">
        <SideBar />
      </div>
      <div className="w-screen">
        <PISMaps />
      </div>
    </div>
  );
}

export default MapView;
