import axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from "axios";

export const getStopsStatus = async (token: any) => {
  var config: AxiosRequestConfig = {
    method: "get",
    url: "https://pis.distronix.in:5543/pis/deviceStatus_plus?threshold=30",
    headers: {
      "x-access-token": token,
    },
  };

  const result = await axios(config).catch(function (error) {
    console.log(error);
  });
  return result;
};
