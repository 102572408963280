import axios, { AxiosError, AxiosRequestConfig } from "axios";

export const Logout = async (token: any) => {
  let config: AxiosRequestConfig = {
    method: "post",
    url: "https://pis.distronix.in:5443/api/users/logout",
    headers: {
      "x-access-refresh-token": token,
    },
  };

  axios(config)
    .then((response) => {
      // console.log(response.data);
    })
    .catch((error: any) => {
      const axiosTypedError: AxiosError = error;
      console.log(axiosTypedError.message);
      if (axiosTypedError.response?.status === 404) {
        console.log("Dispatching Logout function");
      }
    });
};
