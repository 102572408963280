import React, { ChangeEventHandler, useCallback, useState } from "react";
import styles from "./Login.module.css";
import GoogleMapReact from "google-map-react";
import { Link, Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRssSquare } from "@fortawesome/free-solid-svg-icons";
import { Markers } from "../pages/mockdata";
import MAPS_API_KEY from "../APIKEY";
import { useSnackbar } from "notistack";
import { Login as LogginIn } from "../api/login/login";
import { login, setUser, setAccessToken } from "../redux/slice/UserSlice";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
//@ts-ignore
import ClientCaptcha from "react-client-captcha";
import crypto from "crypto";

const Login = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [UserId, setUserId] = useState("");
  const [Password, setPassword] = useState("");
  const [CaptchaCode, setCaptchaCode] = useState("");
  const [CaptchaEnter, setCaptchaEnter] = useState("");

  const dispatch = useAppDispatch();
  const User = useAppSelector((state) => state.User);

  const center = {
    lat: 22.59627,
    lng: 88.48757,
  };
  const zoom = 14;
  const tryToLogin = async () => {

    if (CaptchaEnter !== CaptchaCode) {
      enqueueSnackbar("Capcha Wrong", { variant: "error" });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
      return
    }

    const algorithm = "aes-256-cbc";
    const cipher = crypto.createCipheriv(
      algorithm,
      "12345678123456781234567812345678",
      "1234567812345678"
    );
    // @ts-ignore
    let encryptedData = cipher.update(Password, "utf-8", "hex");
    // @ts-ignore
    encryptedData += cipher.final("hex");

    const res = await LogginIn(UserId, encryptedData).catch((e) => {
      enqueueSnackbar(e.message, { variant: "error" });
    });
    if (res.status === "success") {
      dispatch(
        setUser({
          name: res?.name,
          userName: res?.userName,
          userRole: res?.userRole,
          refreshToken: res?.refreshToken,
        })
      );
      dispatch(
        setAccessToken({
          token: res?.token,
        })
      );
      dispatch(login());
      enqueueSnackbar("Login Successful", {
        variant: "success",
      });
    } else {
      enqueueSnackbar("Login Failed", { variant: "error" });
    }
  };

  const handleClick = () => {
    enqueueSnackbar("Trying to log in", { variant: "info" });
    tryToLogin();
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleClick();
    }
  };

  const MarkerPin = ({ stoppage }: any): any => (
    <div className={styles.MarkerPin}>
      <FontAwesomeIcon icon={faRssSquare} size="2x" color="#E84335" />
    </div>
  );

  const capchaChange = (e:any) => {
    setCaptchaEnter(e.target.value)
  }

  const setCode = (code: string) => {
    setCaptchaCode(code);
  }

  if (User.loggedIn) return <Redirect to="/user/map" />;

  return (
    <div className="styles.Container">
      <div className={styles.FormContainer}>
        <div className={styles.Form} onKeyPress={handleKeyPress}>
          <input
            type="text"
            placeholder="Username"
            value={UserId}
            autoComplete="off"
            onChange={(e) => setUserId(e.target.value)}
          />
          <input
            type="text"
            className={[styles.pass].join(" ")}
            placeholder="Password"
            autoComplete="off"
            value={Password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <div
            style={{
              color: "#000",
              position: "absolute",
              left: 40,
              top: 108,
            }}
          >
            {Password.split("")
              .map((x) => "*")
              .join("")}
          </div>
          <div 
           style={{
             display: "flex",
             gap: "10px",
           }}
          >
          <input
            type="text"
            placeholder="Enter Captcha"
            autoComplete="off"
            onChange={capchaChange}
            style={{
              width: "50%",
            }}
            maxLength={4}
          />
          <ClientCaptcha captchaCode={setCode} height={30} width={60} fontSize={15} />
          </div>
          <input type="submit" onClick={() => handleClick()} value="LOGIN" />
          <Link to="/guest/forgot-password" className={styles.ForgotPass}>
            Forgot Password?
          </Link>
        </div>
      </div>
      <div className={styles.Maps}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: MAPS_API_KEY }}
          defaultCenter={center}
          defaultZoom={zoom}
          options={(maps: any) => ({
            backgroundColor: "##f7f7f7",
            fullscreenControl: false,
            mapTypeControl: false,
            zoomControl: false,
          })}
        >
          {Markers.tag.map((item: any, index: number) => (
            <MarkerPin
              key={item.mac + item.lat}
              stoppage={item.stoppage}
              lat={item.lat}
              lng={item.lng}
            />
          ))}
        </GoogleMapReact>
      </div>
    </div>
  );
};

export default Login;
