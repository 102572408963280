import axios from "axios";

export const GenerateAccessToken = async (refreshtoken: string) => {
  const response = await axios.post(
    "https://pis.distronix.in:5443/api/users/token",
    { refreshToken: refreshtoken },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
};
