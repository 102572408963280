import React, { useEffect } from "react";
import styles from "../components/PISMaps.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import Emoji from "./Emoji";
import moment from "moment";

const DisplayInfo = ({
  markerData,
  MarkData,
  handleMarkerInfoClose,
  status,
}: any) => {
  return (
    <div className={styles.MarkerSideBar}>
      <div className={styles.MarkerSBHeaderBG}>
        <div onClick={handleMarkerInfoClose} className={styles.CloseBtn}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
        <div className={styles.MarkerSideBarHeader}>
          <p>DEVICE LOCATION</p>
          <h2>{markerData.stoppage}</h2>
        </div>
        <div className={styles.Diagnostic}>
          <div className={styles.DiagnosticStatus}>
            <p>DEVICE STATUS</p>
            <FontAwesomeIcon
              className={styles.Active}
              icon={faCircle}
              color={
                !!status &&
                status.find((data: any) => data.name === markerData.stoppage)
                  ?.pis_online_status === "Online"
                  ? "#0f0"
                  : "#FF0000"
              }
              size="xs"
              title="Active"
            />
          </div>
          <div className="flex flex-col">
            <p className="text-white text-md pt-1 italic">
              {!!status &&
              status.find((data: any) => data.name === markerData.stoppage)
                ?.pis_last_response
                ? "Last seen " +
                  // moment
                  //   .utc(
                  //     !!status &&
                  //       status.find(
                  //         (data: any) => data.name === markerData.stoppage
                  //       )?.pis_last_response,
                  //     "YYYY-MM-DD HH:mm:ss"
                  //   )
                  //   .local()
                  //   .fromNow()
                  moment(
                    !!status &&
                      status.find(
                        (data: any) => data.name === markerData.stoppage
                      )?.pis_last_response,
                    "YYYY-MM-DD HH:mm:ss"
                  ).fromNow()
                : "No data"}
            </p>
          </div>
        </div>
      </div>
      <div className={styles.BusETA}>
        {/* <p>BUSES ETA FOR {markerData.stoppage.toUpperCase()} BUS STOP</p> */}
        <div className={styles.ETATableContainer}>
          <div className={styles.ETARowHeader}>
            <div className={styles.ETACell}>BUS</div>
            <div className={styles.ETACell}>DESTINAIION</div>
            <div className={styles.ETACell}>ETA</div>
          </div>
          <div className={styles.ETACellValue}>
            {MarkData.length === 0 && markerData.mac === "" ? (
              <p>No Display Data Found.</p>
            ) : (
              MarkData.length === 0 && <p>Fetching Live Data...</p>
            )}
            {MarkData.length === 1 && MarkData[0].cmd === "No data" && (
              <p>No Display Data Found.</p>
            )}
            {MarkData.filter((i: any) => i.mac === markerData.mac).map(
              (item: any, index: number) => {
                if (item.effect === "normal") {
                  return (
                    <div
                      className={`${
                        item.effect === "blink" && "animate-pulse"
                      }`}
                      key={index}
                    >
                      <div className={styles.ETARow}>
                        <div className={styles.ETACell}>{item.cmd}</div>
                        <div className={styles.ETACell}>{item.dest}</div>
                        <div className={styles.ETACell}>{item.eta}</div>
                      </div>
                    </div>
                  );
                }
              }
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisplayInfo;
