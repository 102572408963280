import { createSlice } from "@reduxjs/toolkit";

export const UserSlice = createSlice({
  name: "User",
  initialState: {
    loggedIn: false,
    name: null,
    refreshToken: null,
    token: null,
    userName: null,
    userRole: null,
  },
  reducers: {
    login: (state) => {
      state.loggedIn = true;
    },
    logout: (state) => {
      state.loggedIn = false;
    },
    setUser: (state, action) => {
      state.name = action?.payload?.name;
      state.refreshToken = action?.payload?.refreshToken;
      state.userName = action?.payload?.userName;
      state.userRole = action?.payload?.userRole;
    },
    setAccessToken: (state, action) => {
      state.token = action?.payload?.token;
    },
  },
});

export const { login, logout, setUser, setAccessToken } = UserSlice.actions;

export default UserSlice.reducer;
