import React from "react";
import moment from "moment";
import styles from "../components/PISMaps.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBus } from "@fortawesome/free-solid-svg-icons";

const BusPin = (props: any) => {
  const { toggleBusInfo, selectedBusId } = props;
  return (
    <div
      id={props.vehicleRegNo}
      onClick={() => {toggleBusInfo(props.vehicleRegNo)}}
    >
      <div className={styles.BusPin}>
        <p className={styles.BusNumber}>
          {!!props.routeCode &&
            props.routeCode.slice(0, props.routeCode.indexOf(":"))}
        </p>
        <FontAwesomeIcon icon={faBus} size="2x" color="#34A452" />
      </div>
      {selectedBusId === props.vehicleRegNo ? (
        <div className={styles.BusPinInfo}>
          <div className={styles.BusDetails}>
            <p>REGISTRATION NUMBER</p>
            <p className={styles.BusDetailsRight}>{props.vehicleRegNo}</p>
          </div>
          <div className={styles.BusDetails}>
            <p>ROUTE ID</p>
            <p className={styles.BusDetailsRight}>{props.routeCode}</p>
          </div>
          <div className={styles.BusDetails}>
            <p>LATITUDE </p>
            <p className={styles.BusDetailsRight}>{props.lat}</p>
          </div>
          <div className={styles.BusDetails}>
            <p>LONGITUDE</p>
            <p className={styles.BusDetailsRight}>{props.lng}</p>
          </div>
          <div className={styles.BusDetails}>
            <p>LAST ONLINE</p>
            <p className={styles.BusDetailsRight}>
              {moment(props.time).calendar()}
            </p>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default BusPin;
