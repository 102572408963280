import React, { useState } from "react";
import styles from "./Login.module.css";
import GoogleMapReact from "google-map-react";
import { Link, useLocation, Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRssSquare } from "@fortawesome/free-solid-svg-icons";
import { Markers } from "../pages/mockdata";
import MAPS_API_KEY from "../APIKEY";
import { useSnackbar } from "notistack";
import crypto from "crypto";

function Reset() {
  const [code, setCode] = useState("");
  const [resetPassword, setResetPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const center = {
    lat: 22.59627,
    lng: 88.48757,
  };
  const [backTo, setBackTo] = useState(false);
  const location: any = useLocation();
  const userId = location.state.userId;
  const zoom = 14;
  const MarkerPin = ({ stoppage }: any): any => (
    <div className={styles.MarkerPin}>
      <FontAwesomeIcon icon={faRssSquare} size="2x" color="#E84335" />
    </div>
  );
  const { enqueueSnackbar } = useSnackbar();

  const changePassword = () => {
    const algorithm = "aes-256-cbc";

    // the cipher function
    // @ts-ignore
    const cipher = crypto.createCipheriv(
      algorithm,
      "12345678123456781234567812345678",
      "1234567812345678"
    );
    if (resetPassword === confirmPassword) {
      // encrypt the message
      // input encoding
      // output encoding
      // @ts-ignore
      let encryptedData = cipher.update(resetPassword, "utf-8", "hex");
      // @ts-ignore
      encryptedData += cipher.final("hex");

      var axios = require("axios");
      var data = JSON.stringify({
        userName: userId,
        password: encryptedData,
        confirmPassword: encryptedData,
        otp: code,
      });

      var config = {
        method: "post",
        url: "https://pis.distronix.in:5443/api/users/forgetPassword",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response: any) {
          setBackTo(true);
          enqueueSnackbar("Password Changed Successfully", {
            variant: "success",
          });
        })
        .catch(function (error: any) {
          console.log(error);
        });
    } else {
      enqueueSnackbar("Password and Confirm Password do not match", {
        variant: "error",
      });
    }
  };
  if (backTo) return <Redirect to="/guest/signin" />;
  return (
    <div className="styles.Container">
      <div className={styles.FormContainer}>
        <div className={styles.Form}>
          <p className="text-white">Enter the code below</p>
          <input
            type="text"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            placeholder="ENTER CODE"
          />
          <input
            type="password"
            value={resetPassword}
            onChange={(e) => setResetPassword(e.target.value)}
            placeholder="ENTER NEW PASSWORD"
          />
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="CONFIRM PASSWORD"
          />
          <input
            type="submit"
            value="RESET PASSWORD"
            onClick={changePassword}
          />
          <Link to="/guest/forgot-password" className={styles.ForgotPass}>
            Didn't get code?
          </Link>
        </div>
      </div>
      <div className={styles.Maps}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: MAPS_API_KEY }}
          defaultCenter={center}
          defaultZoom={zoom}
          options={(maps) => ({
            backgroundColor: "##f7f7f7",
            fullscreenControl: false,
            mapTypeControl: false,
            zoomControl: false,
          })}
        >
          {Markers.tag.map((item: any, index: number) => (
            <MarkerPin stoppage={item.stoppage} lat={item.lat} lng={item.lng} />
          ))}
        </GoogleMapReact>
      </div>
    </div>
  );
}

export default Reset;
