import axios, { AxiosRequestConfig } from "axios";
// @ts-ignore
import qs from "qs";

export const editPIS = async (
  id: string,
  name: string,
  dis_direction: string,
  lat: string,
  long: string,
  dis_lat: string,
  dis_long: string,
  dev_id_1: string,
  dev_id_2: string,
  remarks: string,
  token: string
) => {
  var data = qs.stringify({
    name: name,
    dis_direction: dis_direction,
    lat: lat,
    long: long,
    dis_lat: dis_lat,
    dis_long: dis_long,
    dev_id_1: dev_id_1,
    dev_id_2: dev_id_2,
    remarks: remarks,
  });
  var config: AxiosRequestConfig = {
    method: "patch",
    url: `https://pis.distronix.in:5443/api/pisMaster/updateOne/${id}`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "x-access-token": token,
    },
    data: data,
  };

  const result = await axios(config)
    .then((result: any) => result)
    .catch((error: any) => {
      console.log(error);
      return error;
    });

  return result;
};
