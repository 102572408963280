import React from 'react';
import styles from './Header.module.css';

function Header() {
    return (
      <div style={{background:"#1E62A6"}} className='flex flex-row items-center px-4'>
        <div className="flex flex-row gap-2 justify-start items-center">
          <img
            className="p-1 my-3 h-12 bg-white rounded-md"
            src="/nkda-logo.png"
            alt="NKDA Logo"
          ></img>
          <img
            className="p-1 my-3 h-12 bg-white rounded-md"
            src="/nkgsccl-logo.png"
            alt="nkgsccl Logo"
          ></img>
        </div>
        <div className={styles.Brand}>NKDA Public Information System</div>
      </div>
    );
}

export default Header
