import axios, { AxiosRequestConfig } from "axios";

export interface getRouteDT {
  status: string;
  count: number;
  data: string[];
}

export const getRoute = async (token: string) => {
  var config: AxiosRequestConfig = {
    method: "get",
    url: "https://pis.distronix.in:5443/api/v1.1/getAllBusLabel",
    headers: {
      "x-access-token": token,
    },
  };

  const result = await axios(config).catch(function (error) {
    console.log(error);
  });
  return result;
};
