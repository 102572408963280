import axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from "axios";

export const getUserResponse = async (token: string) => {
  var data = JSON.stringify({
    refresh_token: token,
  });
  var config: AxiosRequestConfig = {
    method: "post",
    url: "https://pis.distronix.in:5443/api/users/userResponse",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  const result = await axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      console.log(error);
    });
  return result;
};
