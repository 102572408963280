import { FunctionComponent } from "react";

import MapView from "../../pages/MapView";
import MasterData from "../../pages/MasterData";
import BusOfflineReport from "../../pages/reports/BusOfflineReport";
import RFIDMaster from "../../pages/RFIDMaster";

interface UserRoute {
    Component: FunctionComponent;
    path: string;
    title: string;
}

export const UserRoutes: UserRoute[] = [
  {
    Component: MapView,
    path: "/map",
    title: "Map View",
  },
  {
    Component: MasterData,
    path: "/master-data",
    title: "Master Data",
  },
  {
    Component: RFIDMaster,
    path: "/rfid-master-data",
    title: "Master Data",
  },
  {
    Component: BusOfflineReport,
    path: "/reports/bus-offline-report",
    title: "Bus Offline Report",
  },
];