import axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from "axios";

export const getRoutes2 = async (routeList: any, token: string) => {
  var data = JSON.stringify({
    busLabels: routeList,
  });

  var config: AxiosRequestConfig = {
    method: "post",
    url: "https://pis.distronix.in:5443/api/v1.1/vehical/getRoutes2",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-access-token": token,
    },
    data: data,
  };

  const result = await axios(config)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
    });
  return result;
};
